import React from 'react';
import './CategoryHero.scss';
import Container from './../Container';
import Section from './../Section';
import CategoryRequestForm from './../CategoryRequestForm';
import MoreButton from "../MoreButton";

const CategoryHero = ({image: HeroImage, title = '', description = ''}) => {
  let scrollToTop = () => {
    const section = document.querySelector('.category-hero');

    window.scrollTo({
      left: 0,
      top: section.scrollHeight,
      behavior: "smooth"
    });
  };

  return (
    <Section className="category-hero">
      <Container className="category-hero__container">
        <div className="category-hero__content">
          <div className="category-hero__title">
            {title}
          </div>
          <div className="category-hero__description">
            {description}
          </div>
          <div className="category-hero__description category-hero__description--second">
            Или наберите менеджеру на номер <a href="tel:+74956627524">+7 (495) 662-75-24</a>!
          </div>

          <CategoryRequestForm
            style={{
              maxWidth: 380,
              margin: '0 auto',
            }}
          />

          <MoreButton onClick={scrollToTop}/>
        </div>
        <div className="category-hero__image">
          <HeroImage/>
        </div>
      </Container>
    </Section>
  );
};

export default CategoryHero;
