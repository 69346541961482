import React, {PureComponent} from 'react';
import {Switch, Route} from 'react-router-dom';
import ScrollToTop from '././ScrollToTop';
import HomePage from './../pages/HomePage';
import NotFoundPage from './../pages/NotFoundPage';
import Header from './Header';
import Content from './Content';
import Footer from './Footer';
import CategoriesPage from './../pages/CategoriesPage';
import HowItWorksPage from './../pages/HowItWorksPage';
import AboutUsPage from './../pages/AboutUsPage';
import ContactsPage from './../pages/ContactsPage';
import Drawer from '././Drawer';
import EmailVerifyModal from '././EmailVerifyModal';
import ResetPasswordModal from './ResetPasswordModal';


import AuthModal from './AuthModal';


import ForgotPasswordModal from './ForgotPasswordModal';
import PrivacyPolicyPage from '../pages/PrivacyPolicyPage';
import TermsPage from '../pages/TermsPage';
import PersonalDataPage from '../pages/PersonalDataPage';
import CookieAlert from './CookieAlert';
import ContextServicePage from '../pages/Services/ContextServicePage';
import ServiceRequestModal from './ServiceRequestModal/ServiceRequestModal';
import WebsiteServicePage from '../pages/Services/WebsiteServicePage';
import SeoServicePage from '../pages/Services/SeoServicePage';
import SmmServicePage from '../pages/Services/SmmServicePage';
import SermServicePage from '../pages/Services/SermServicePage';
import LegalPage from '../pages/LegalPage';


import InfoModal from './InfoModal';
import PartnerModal from './PartnerModal';
import SupportModal from './SupportModal';
import SuccessModal from './SuccessModal';
import CategoryRequestModal from './CategoryRequestModal/CategoryRequestModal';
import {connect} from 'react-redux';
import {fetchUser} from '../actions/authActions';
import YandexMetrika from './YandexMetrika/YandexMetrika';


class App extends PureComponent {
  componentDidMount() {
    this.props.fetchUser();
  }

  render = () => {
    return (
        <React.Fragment>
          <ScrollToTop/>
          <YandexMetrika/>

          <Header/>

          <Content>
            <Switch>
              <Route exact path={`/email/verify/:token`}>
                <EmailVerifyModal isOpen={true}/>
                <Route path={`/`} component={HomePage}/>
              </Route>

              <Route exact path={`/password/reset`}>
                <ResetPasswordModal isOpen={true}/>
                <Route path={`/`} component={HomePage}/>
              </Route>

              <Route exact path={`/`} component={HomePage}/>
              <Route exact path={`/категории`} component={CategoriesPage}/>
              <Route exact path={`/как-это-работает`} component={HowItWorksPage}/>
              <Route exact path={`/о-компании`} component={AboutUsPage}/>
              <Route exact path={`/контакты`} component={ContactsPage}/>
              <Route exact path={`/юристы`} component={LegalPage}/>

              <Route exact path={`/политика-конфиденциальности`} component={PrivacyPolicyPage}/>
              <Route exact path={`/пользовательское-соглашение`} component={TermsPage}/>
              <Route exact path={`/согласие-на-обработку-персональных-данных`} component={PersonalDataPage}/>

              <Route exact path={`/услуги/контекстная-реклама`} component={ContextServicePage}/>
              <Route exact path={`/услуги/создание-сайта`} component={WebsiteServicePage}/>
              <Route exact path={`/услуги/комплексное-продвижение`} component={SeoServicePage}/>
              <Route exact path={`/услуги/smm-продвижение`} component={SmmServicePage}/>
              <Route exact path={`/услуги/управление-репутацией-serm`} component={SermServicePage}/>


              <Route component={NotFoundPage}/>
            </Switch>
          </Content>

          <Footer/>

          <AuthModal/>
          <SuccessModal/>
          <CategoryRequestModal/>
          <ServiceRequestModal/>

          <InfoModal/>
          <PartnerModal/>
          <SupportModal/>
          <ForgotPasswordModal/>

          <Drawer/>

          <CookieAlert/>
        </React.Fragment>
    );
  };
}

export default connect(null, {fetchUser})(App);
