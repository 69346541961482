export default class Errors {
  constructor(errors = {}) {
    this.errors = errors;
  }

  has = (key) => {
    return this.errors.hasOwnProperty(key);
  };

  get = (key) => {
    if (/\.\*/.test(key)) {
      const re = new RegExp(key);

      return Object.keys(this.errors)
        .filter(key => re.test(key))
        .reduce((accumulator, key) => Object.assign(accumulator, { [key]: this.errors[key] }), {});
    }

    return this.errors[key];
  };

  clear = (key) => {
    if (this.has(key)) {
      delete this.errors[key];
    }
  };
}
