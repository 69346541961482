import React from 'react';
import ReactSelect, {components} from 'react-select';
import {ArrowDownIcon} from '../../icons';

const styles = {
  container: (provided, state) => ({
    position: 'relative',
    width: '100%',
    paddingBottom: !state.selectProps.menuIsOpen ? null : 2,

    ...(state.selectProps.customStyles.container && (
      state.selectProps.customStyles.container(provided, state)
    )),
  }),

  control: (provided, state) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
    background: '#FFFFFF',
    borderWidth: !state.menuIsOpen ? 1 : 2,
    borderStyle: 'solid',
    borderColor: !state.menuIsOpen ? '#DADADF' : '#4763EB',
    borderRadius: !state.menuIsOpen ? 32 : '16px 16px 0 0',
    borderBottom: !state.menuIsOpen ? null : 'none',
    overflow: 'hidden',
    cursor: 'pointer',
    zIndex: !state.menuIsOpen ? null : 9999,

    ...(state.selectProps.invalid && {
      borderWidth: 2,
      borderColor: !state.menuIsOpen ? '#FD5C6F' : '#4763EB',
    }),

    ...(state.selectProps.customStyles.control && (
      state.selectProps.customStyles.control(provided, state)
    )),

    ...((state.selectProps.isMulti && state.hasValue && state.selectProps.value.length > 3)  && {
      borderRadius: !state.menuIsOpen ? '16px' : '16px 16px 0 0',
    }),

    ...((state.selectProps.isMulti && state.hasValue && state.selectProps.value.map(x => x.name).join('').length > 21 )  && {
      borderRadius: !state.menuIsOpen ? '16px' : '16px 16px 0 0',
    }),

    ...((state.selectProps.isMulti && state.hasValue && state.selectProps.value.map(x => x.name).join('').length < 21 && state.selectProps.value.length === 3)  && {
      borderRadius: !state.menuIsOpen ? 32 : '16px 16px 0 0',
    }),
  }),

  singleValue: (provided, state) => ({
    overflow: 'hidden',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    color: '#43434C',
    fontSize: 14,
    fontWeight: 700,
    whiteSpace: 'nowrap',

    ...(state.selectProps.invalid && {
      color: '#FD5C6F',
    }),

    ...(state.selectProps.customStyles.singleValue && (
      state.selectProps.customStyles.singleValue(provided, state)
    ))
  }),

  input: (provided, state) => ({
    '*': {
      color: '#43434C',
      fontSize: 14,
      fontWeight: 700,
      boxSizing: `border-box`,
      height: '100%'
    }


  }),

  valueContainer: (provided, state) => ({
    padding: !state.selectProps.menuIsOpen ? '12px 20px' : '11px 19px',
    width: '100%',

    ...(state.selectProps.invalid && {
      padding: '11px 19px',
    }),

    ...(state.selectProps.customStyles.valueContainer && (
      state.selectProps.customStyles.valueContainer(provided, state)
    )),

    ...(state.selectProps.isMulti && {
      display: 'inline-flex',
      flexWrap: 'wrap',
      paddingRight: '40px'
    }),

    ...((state.selectProps.isMulti && state.hasValue) && {
      padding: !state.selectProps.menuIsOpen ? '6px 40px 6px 6px' : '5px 39px 5px 5px',
    }),
    ...((state.selectProps.isMulti && state.hasValue && state.selectProps.value.length >= 2) && {
      padding:  !state.selectProps.menuIsOpen ? '6px 40px 0 6px' : '5px 39px 0 5px'
    }),
  }),

  placeholder: (provided, state) => ({
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    width: '100%',
    fontSize: 14,
    color: '',
    fontWeight: 400,

    ...(state.selectProps.invalid && {
      color: !state.selectProps.menuIsOpen ? '#FD5C6F' : '#9696A0',
    }),

    ...(state.selectProps.customStyles.placeholder && (
      state.selectProps.customStyles.placeholder(provided, state)
    )),

  }),

  indicatorsContainer: (provided, state) => ({
    position: 'absolute',
    top: !state.selectProps.menuIsOpen ? 0 : -1,
    right: !state.selectProps.menuIsOpen ? 0 : -1,
    bottom: !state.selectProps.menuIsOpen ? 0 : -1,

    ...(state.selectProps.customStyles.indicatorsContainer && (
      state.selectProps.customStyles.indicatorsContainer(provided, state)
    )),

    ...(state.selectProps.isMulti && {
      display: 'flex',
      alignItems: 'center',
      padding: '10px 0'
    }),
  }),

  dropdownIndicator: (provided, state) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 20px',
    height: '100%',
    fontSize: 12,
    color: '#43434C',
    transform: !state.selectProps.menuIsOpen ? 'none' : 'rotate(180deg)',

    ...(state.selectProps.customStyles.dropdownIndicator && (
      state.selectProps.customStyles.dropdownIndicator(provided, state)
    )),

    ...(state.selectProps.isMulti && {
      padding: !state.selectProps.menuIsOpen ? '0 20px 0 10px' : '0 20px'
    }),
  }),

  menu: (provided, state) => ({
    position: 'absolute',
    top: 'calc(100% - 7px)',
    left: 0,
    right: 0,
    background: '#FFFFFF',
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: '#4763EB',
    borderRadius: '0 0 16px 16px',
    borderTop: 'none',
    boxShadow: '0 10px 20px 0 rgba(50, 50, 65, 0.15)',
    overflow: 'hidden',
    padding: '0 0 8px 0',
    zIndex: 9998,

    ...(state.selectProps.customStyles.menu && (
      state.selectProps.customStyles.menu(provided, state)
    ))
  }),

  menuList: (provided, state) => ({
    maxHeight: 225,
    overflowY: 'auto',
  }),

  option: (provided, state) => ({
    padding: '6px 19px',
    fontSize: 14,
    fontWeight: 700,
    cursor: 'pointer',
    color: state.isSelected ? '#4763EB' : '#43434C',

    '&:hover': {
      color: '#4763EB',
    },

    ...(state.selectProps.customStyles.option && (
      state.selectProps.customStyles.option(provided, state)
    ))
  }),

  noOptionsMessage: (provided, state) => ({
    padding: '10px 19px',
    color: '#43434C',
    fontSize: 14,
    fontWeight: 400,
  }),

  clearIndicator: (provided, state) => ({

    ...(state.selectProps.isMulti && {
      color: 'white',
      display: 'none'
    }),
  }),

  group: (provided, state) => ({}),
  groupHeading: (provided, state) => ({}),
  indicatorSeparator: (provided, state) => ({

    ...(state.selectProps.isMulti && {
      display: 'none'
    }),
  }),
  loadingIndicator: (provided, state) => ({}),
  loadingMessage: (provided, state) => ({}),
  menuPortal: (provided, state) => ({}),

  multiValue: (provided, state) => ({

    ...(state.selectProps.isMulti && {
      display: 'inline-flex',
      alignItems: 'center',
      padding: '7px 8px 7px 12px',
      background: '#4763EB',
      color: 'white',
      fontWeight: 300,
      borderRadius: '50px',
      fontSize: 14,

    }),

    ...((state.selectProps.isMulti && state.hasValue) && {
      marginRight: '6px'
    }),

    ...((state.selectProps.isMulti && state.hasValue && state.selectProps.value.length >= 2) && {
      marginBottom: '6px',
      marginRight: '6px'
    }),

  }),

  multiValueLabel: (provided, state) => ({

    ...(state.selectProps.isMulti && {
      marginRight: '8px',

      maxWidth: `120px`,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    }),
  }),

  multiValueRemove: (provided, state) => ({


    ...(state.selectProps.isMulti && {
      lineHeight: 0
    }),
  }),
};

const DropdownIndicator = props => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <ArrowDownIcon/>
      </components.DropdownIndicator>
    )
  );
};

const Option = ({children, ...props}) => {
  // if (props.data !== props.getValue()[0]) {
    return (
      components.Option && (
        <components.Option {...props}>
          {children}
        </components.Option>
      )
    );
  // } else {
  //   return null;
  // }
};

const Select = ({customStyles = {}, components = {}, error = null, ...props}) => {
  return (
    <React.Fragment>
      <ReactSelect
        invalid={!!error}
        components={{ DropdownIndicator, Option, ...components }}
        isSearchable={false}
        {...props}
        styles={styles}
        customStyles={customStyles}
        noOptionsMessage={() => `Ничего не найдено`}
      />
      {error && (
        <div className="input__error">
          {error}
        </div>
      )}
    </React.Fragment>
  );
};

export default Select;
