import React from 'react';
import Modal from './../Modal';
import {SupportIcon} from '../../icons';
import {connect} from 'react-redux';
import {openSuccessModal, closeSupportModal} from '../../actions/modalActions';
import ContactForm from '../ContactForm';

const SupportModal = (props) => {
  const {isOpen, closeModal, openSuccessModal} = props;

  return (
      <Modal
          isOpen={isOpen}
          onRequestClose={closeModal}
          title={`Написать сообщение`}
          description={`У вас сложности или вопросы технического характера? Напишите нам, мы решим этот вопрос`}
          icon={<SupportIcon/>}
      >
        <ContactForm
            icon={<SupportModal/>}
            withAttachments={true}
            onSubmit={() => {
              closeModal();
              openSuccessModal();
            }}
        />
      </Modal>
  );
};

const mapStateToProps = state => ({
  isOpen: state.modal.support.isOpen
});

const mapDispatchToProps = dispatch => ({
  closeModal: () => dispatch(closeSupportModal()),
  openSuccessModal: () => dispatch(openSuccessModal({
    icon: () => <SupportIcon/>,
    description: '...'
  })),
});

export default connect(mapStateToProps, mapDispatchToProps)(SupportModal);
