import React from 'react';
import './Steps.scss';
import Container from '../Container';
import SectionTitle from '../SectionTitle';
import SectionDescription from '../SectionDescription';
import {AccountIcon, CheckIcon, PencilIcon} from '../../icons';
import ReadyButton from '../ReadyButton/ReadyButton';
import Section from '../Section';

const Steps = ({horizontal = false}) => {
  // if (document.documentElement.clientWidth < 1080) {
  //   horizontal = false;
  // }

  return (
      <Section>
        <Container>
          <SectionTitle center={!horizontal}>
            Как это работает?
          </SectionTitle>
          <SectionDescription center={!horizontal}>
            Все очень просто, пару минут и пару шагов и вы уже с нами
          </SectionDescription>

          <div className={`steps ${horizontal ? 'steps--horizontal' : ''}`}>
            <div className="steps__item">
              <div className="steps__number">
                1
              </div>
              <div className="steps__title">
                Оставляете заявку
                <br/>
                и регистрируетесь
              </div>
              <div className="steps__icon">
                <PencilIcon/>
              </div>
            </div>
            <div className="steps__divider"/>
            <div className="steps__item">
              <div className="steps__number">
                2
              </div>
              <div className="steps__title">
                Получаете доступ
                <br/>
                в ваш личный кабинет
              </div>
              <div className="steps__icon">
                <AccountIcon/>
              </div>
            </div>
            <div className="steps__divider"/>
            <div className="steps__item steps__item--success">
              <div className="steps__success">
                <CheckIcon/>
              </div>
              <div className="steps__title">
                Начинаете получать желаемый поток клиентов и просто делать свою работу!
              </div>
            </div>
          </div>

          <ReadyButton/>
        </Container>
      </Section>
  );
};

export default Steps;
