import React, {PureComponent} from 'react';
import {openSuccessModal} from '../../actions/modalActions';
import {MailIcon, SiteIcon, UserIcon} from '../../icons';
import {connect} from 'react-redux';
import FormControl from '../FormControl';
import Input from '../Input';
import Button from '../Button';
import PhoneInput from '../PhoneInput';
import Checkbox from '../Checkbox';
import Errors from '../Errors';
import {storeRequest} from '../../actions/requestActions';

const initialState = {
  data: {
    name: '',
    phone: '',
    email: '',
    url: '',
    message: '',
    personalData: '',
  },
  errors: new Errors([]),
};

class ServiceRequestForm extends PureComponent {
  constructor(props) {
    super(props);

    this.state = initialState;
  }

  handleChange = (e) => {
    const {name, value, type, checked} = e.target;

    this.setState(prevState => ({
      data: {
        ...prevState.data,
        [name]: type === 'checkbox' ? checked : value,
      }
    }));
  };

  handleSubmit = () => {
    const {
      onSubmit = () => {
      }
    } = this.props;
    const {data} = this.state;

    storeRequest(data)
        .then(() => {
          this.setState(initialState);
          this.props.openSuccessModal();

          onSubmit();
        })
        .catch(errors => {
          this.setState({
            errors: new Errors(errors)
          })
        });
  };

  render = () => {
    const {style} = this.props;
    const {data: {name, phone, email, url, message, personalData}, errors} = this.state;

    return (
        <div style={style}>
          <FormControl>
            <Input
                error={errors.get('name')}
                name="name"
                value={name}
                onChange={this.handleChange}
                placeholder={`Ваше имя`}
                icon={(props) => <UserIcon {...props}/>}
            />
          </FormControl>
          <FormControl>
            <PhoneInput
                error={errors.get('phone')}
                name="phone"
                value={phone}
                onChange={this.handleChange}
            />
          </FormControl>
          <FormControl>
            <Input
                error={errors.get('email')}
                type="email"
                name="email"
                value={email}
                onChange={this.handleChange}
                placeholder={`Ваш email`}
                icon={(props) => <MailIcon {...props}/>}
            />
          </FormControl>
          <FormControl>
            <Input
                error={errors.get('url')}
                type="url"
                name="url"
                value={url}
                onChange={this.handleChange}
                placeholder={`Ссылка на сайт`}
                icon={(props) => <SiteIcon {...props}/>}
            />
          </FormControl>
          <FormControl>
            <Input
                error={errors.get('message')}
                multiline
                name="message"
                value={message}
                onChange={this.handleChange}
                placeholder={`Оставьте ваши коментарии и введите в курс дела и вашей ситуации…`}
            />
          </FormControl>
          <FormControl>
            <Checkbox
                checked={personalData}
                error={errors.get('personal_data')}
                name="personalData"
                onChange={this.handleChange}
            >
              Я даю своё&nbsp;
              <a
                  href={`/согласие-на-обработку-персональных-данных`}
                  target="_blank"
                  rel="noopener noreferrer"
              >
                согласие на обработку персональных данных
              </a>
            </Checkbox>
          </FormControl>
          <FormControl>
            <Button
                fullWidth
                onClick={this.handleSubmit}
            >
              Отправить
            </Button>
          </FormControl>
        </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  openSuccessModal: () => dispatch(openSuccessModal({
    icon: () => <MailIcon/>,
    description: 'Спасибо, что оставили заявку, мы свяжемся с Вами в ближайшее время.'
  })),
});

export default connect(null, mapDispatchToProps)(ServiceRequestForm);

