import React from 'react';
import './Menu.scss';
import {NavLink} from 'react-router-dom';
import DropdownMenu from './../DropdownMenu';
import ServiceMenu from '../ServiceMenu';

const Menu = ({className = '', onClick = () => {}, ...props}) => {
  return (
      <div
          {...props}
          className={`menu ${className}`}
      >
        <NavLink
            exact={true}
            activeClassName='menu__item--active'
            className="menu__item"
            to={`/категории`}
            onClick={onClick}
        >
          Категории лидов
        </NavLink>

        <DropdownMenu
            link={props => (
                <NavLink
                    {...props}
                    to={`/услуги`}
                >
                  Услуги
                </NavLink>
            )}
            menu={props => (
                <ServiceMenu
                    {...props}
                    onClick={() => {
                      onClick();
                      props.onClick();
                    }}
                />
            )}
        />

        <NavLink
            exact={true}
            activeClassName='menu__item--active'
            className="menu__item"
            to={`/как-это-работает`}
            onClick={onClick}
        >
          Как это работает
        </NavLink>
        <NavLink
            exact={true}
            activeClassName='menu__item--active'
            className="menu__item"
            to={`/о-компании`}
            onClick={onClick}
        >
          О компании
        </NavLink>
        <NavLink
            exact={true}
            activeClassName='menu__item--active'
            className="menu__item"
            to={`/контакты`}
            onClick={onClick}
        >
          Контакты
        </NavLink>
        <NavLink
            exact={true}
            activeClassName='menu__item--active'
            className="menu__item"
            to={`/юристы`}
            onClick={onClick}
        >
          Юристам
        </NavLink>
      </div>
  );
};

export default Menu;
