import React from 'react';
import './Slider.scss';
import ReactSlider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import {ArrowLeftIcon, ArrowRightIcon} from './../../icons';

const PrevArrow = ({className, onClick}) => {
  return (
    <ArrowLeftIcon
      onClick={onClick}
      className={className}
    />
  );
};

const NextArrow = ({className, onClick}) => {
  return (
    <ArrowRightIcon
      onClick={onClick}
      className={className}
    />
  );
};

const Slider = ({children}) => {
  return (
    <ReactSlider
      dots={true}
      infinite={true}
      speed={500}
      slidesToShow={1}
      slidesToScroll={1}

      prevArrow={<PrevArrow/>}
      nextArrow={<NextArrow/>}

    >
      {children}
    </ReactSlider>
  );
};

export default Slider;
