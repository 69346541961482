import React from 'react';
import Button from "../../components/Button";
import Features from "../../components/Features";
import ServiceHero from "../../components/ServiceHero";
import {ReactComponent as HeroImage} from './../../images/hero-website.svg';
import Background from "../../components/Background";
import Readiness from "../../components/Readiness";
import ServiceRequest from "../../components/ServiceRequest";
import {openServiceRequestModal} from "../../actions/modalActions";
import {connect} from "react-redux";
import StartUp from '../../images/start-up.svg';
import Development from '../../images/development.svg';
import Planing from '../../images/planing.svg';
import WebBuild from '../../images/webbuild.svg';
import Lunch from '../../images/launch.svg';

const WebsiteServicePage = (props) => {
  const {openServiceRequestModal} = props;

  return (
    <React.Fragment>
      <Background color="grey">
        <ServiceHero
          image={() => <HeroImage style={{margin: '-30px 0'}} />}
          title={`Нужно Создать Сайт под вашу идею или бизнес?`}
        >
          <p>
            Мы предлагаем услуги разработки веб-продуктов от лендинга до мульти-функционального портала. В нашей команде сильные специалисты с большим опытом готовые решить необходимую вам задачу.
          </p>
          <p>
            <strong>
              Оставьте заявку чтобы начать работу по вашему сайту!
            </strong>
          </p>
          <Button
            onClick={openServiceRequestModal}
          >
            Оставить заявку
          </Button>
        </ServiceHero>

        <Features
          type={`horizontal`}
          title={`Зачем вам нужен сайт или веб-продукт?`}
          items={[{
            icon: () => <img width={120} height={120} src={StartUp} alt="" />,
            title: 'Вы новая компания/проект и вас еще нет в интернете',
            description: 'Если вы организуете какие-либо акции, специальные предложения или преследуете привлечение клиентов на конкретные цели, то контекстная реклама будет верным решением.',
          }, {
            icon: () => <img width={120} height={120} src={Development} alt="" />,
            title: 'Вы уже имеете свой сайт или веб-продукт, но желаете его модернизировать',
            description: 'Разместить рекламные объявления в Яндекс.Директ или Google Ads можно в несколько кликов. Следовательно, и начать рекламировать себя можно течение 1–2 дней после принятия соответствующего решения.',
          }]}
        />

        <Readiness
          onClick={openServiceRequestModal}
        />

        <Features
          type={`vertical`}
          title={`Что входит в услуги Разработки сайта, Веб-продукта?`}
          items={[{
            icon: () => <img width={120} height={120} src={Planing} alt="" />,
            title: 'Оценка задач и целей вашего бизнеса или проекта, составление плана и объема работ.',
            description: '',
          }, {
            icon: () => <img width={120} height={120} src={WebBuild} alt="" />,
            title: 'Дизайн, а так-же разработка вашего сайта и внедрение необходимого функционала, контента до полной готовности',
            description: '',
          }, {
            icon: () => <img width={120} height={120} src={Lunch} alt="" />,
            title: 'Выкладка продукта в сеть и его поддержка на будущих этапах развития и расширения функциональности',
            description: '',
          }]}
        />
      </Background>

      <Background color="white">
        <ServiceRequest
          withoutUrl={true}
          title={
            <React.Fragment>
              Оставьте заявку по услугам
              <br/>
              Разработки сайта, Веб-продукта?
            </React.Fragment>
          }
          description={`Заполните форму чтобы начать работать с нами`}
        />
      </Background>
    </React.Fragment>
  );
};

const mapDispatchToProps = dispatch => ({
  openServiceRequestModal: () => dispatch(openServiceRequestModal({
    title: 'Оставьте заявку по услугам Разработки сайта, Веб-продукта?',
    description: 'Заполните форму чтобы начать работать с нами',
  })),
});

export default connect(null, mapDispatchToProps)(WebsiteServicePage);
