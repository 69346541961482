import React from 'react';

const Title = (props) => {
  return (
    <div
      {...props}
      style={{
        fontWeight: 900,
        fontSize: 19,
        color: '#43434C',
        margin: '30px 0'
      }}
    />
  );
};

export default Title;
