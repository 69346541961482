import * as actionTypes from './actionTypes';

export const openModal = ({modalType = '', payload = null}) => ({
  type: actionTypes.OPEN_MODAL,
  modalType: modalType,
  payload: payload,
});

export const closeModal = ({modalType = ''}) => ({
  type: actionTypes.CLOSE_MODAL,
  modalType: modalType,
});

export const openSuccessModal = ({icon = null, description = null}) => openModal({
  modalType: 'success',
  payload: {
    icon: icon,
    description: description,
  },
});

export const closeSuccessModal = () => closeModal({
  modalType: 'success',
});

export const openAuthModal = ({tab = null}) => openModal({
  modalType: 'auth',
  payload: {
    tab: tab,
  },
});

export const closeAuthModal = () => closeModal({
  modalType: 'auth',
});

export const openInfoModal = () => openModal({
  modalType: 'info',
});

export const closeInfoModal = () => closeModal({
  modalType: 'info',
});

export const openPartnerModal = () => openModal({
  modalType: 'partner',
});

export const closePartnerModal = () => closeModal({
  modalType: 'partner',
});

export const openSupportModal = () => openModal({
  modalType: 'support',
});

export const closeSupportModal = () => closeModal({
  modalType: 'support',
});



export const openForgotPasswordModal = () => openModal({
  modalType: 'forgotPassword',
});

export const closeForgotPasswordModal = () => closeModal({
  modalType: 'forgotPassword',
});

export const openCategoryRequestModal = () => openModal({
  modalType: 'categoryRequest',
});

export const closeCategoryRequestModal = () => closeModal({
  modalType: 'categoryRequest',
});



export const openServiceRequestModal = ({title, description}) => openModal({
  modalType: 'serviceRequest',
  payload: {
    title: title,
    description: description,
  }
});

export const closeServiceRequestModal = () => closeModal({
  modalType: 'serviceRequest',
});
