import React from 'react';
import Modal from './../Modal';
import FormControl from '../FormControl';
import Button from './../Button';
import {CheckIcon} from './../../icons';
import {connect} from 'react-redux';
import {closeSuccessModal} from './../../actions/modalActions';

const SuccessModal = (props) => {
  const {isOpen, icon: Icon, description, closeModal} = props;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      title={`Готово!`}
    >
      <div className="status">
        <div className="status__icon">
          {Icon && <Icon/>}
          <CheckIcon className="status__badge status__badge--success" />
        </div>
        <div className="status__description">
          {description}
        </div>
      </div>

      <FormControl>
        <Button
          onClick={closeModal}
        >
          ОК
        </Button>
      </FormControl>
    </Modal>
  );
};

const mapStateToProps = state => ({
  isOpen: state.modal.success.isOpen,
  icon: state.modal.success.payload.icon,
  description: state.modal.success.payload.description,
});

const mapDispatchToProps = dispatch => ({
  closeModal: () => dispatch(closeSuccessModal())
});

export default connect(mapStateToProps, mapDispatchToProps)(SuccessModal);
