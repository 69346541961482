import React from 'react';
import './Background.scss';

const Background = ({color = 'white', ...props}) => {
  return (
    <div
      {...props}
      className={`background background--${color}`}
    />
  );
};

export default Background;
