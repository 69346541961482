import React from 'react';
import './Readiness.scss';
import Button from '../Button';
import Section from '../Section';

const Readiness = ({onClick = () => {}}) => {
  return (
      <Section>
        <div className={`readiness`}>
          <div className="readiness__title">
            Ну что?
            <br/>
            Готовы начать?
          </div>
          <Button
              onClick={onClick}
          >
            Оставить заявку
          </Button>
        </div>
      </Section>
  );
};

export default Readiness;
