import React from 'react';
import './Input.scss';

const Input = ({icon: Icon = () => null, error = null, multiline, ...props}) => {
  return (
    <div className="input__root">
      {multiline ? (
        <textarea
          {...props}
          rows={5}
          className={`input input--multiline ${error ? 'input--invalid' : ''}`}
        />
      ) : (
        <React.Fragment>
          <Icon className="input__icon" />
          <input
            type="text"
            {...props}
            className={`input ${error ? 'input--invalid' : ''}`}
            autoComplete="off"
          />
        </React.Fragment>
      )}
      {error && (
        <div className="input__error">
          {error}
        </div>
      )}
    </div>
  );
};

export default Input;
