import axios from 'axios';
import _ from 'lodash';

export const storeContact = ({type, name, phone, email, message, attachments, personalData}) => {
  const data = new FormData();

  data.append('type', 'support');
  data.append('name', name);
  data.append('phone', phone);
  data.append('email', email);
  data.append('message', message);
  data.append('personal_data', personalData);

  _.forEach(attachments, attachment => {
    data.append('attachments[]', attachment);
  });

  return new Promise((resolve, reject) => {
    axios.get('/sanctum/csrf-cookie').then(() => {
      axios.post('/contact', data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      }).then(() => {
        resolve();
      }).catch(error => {
        reject(error.response.data.errors);
      });
    });
  });
};
