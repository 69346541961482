import React from 'react';
import {NavLink} from 'react-router-dom';

const ServiceMenu = ({onClick = () => {}}) => {

  return (
    <React.Fragment>
      <NavLink
        exact={true}
        activeClassName="dropdown-menu__item--active"
        to={`/услуги/контекстная-реклама`}
        className="dropdown-menu__item"
        onClick={onClick}
      >
        Контекстная реклама
      </NavLink>
      <NavLink
        exact={true}
        activeClassName="dropdown-menu__item--active"
        to={`/услуги/создание-сайта`}
        className="dropdown-menu__item"
        onClick={onClick}
      >
        Создание сайта, веб-продукта
      </NavLink>
      <NavLink
        exact={true}
        activeClassName="dropdown-menu__item--active"
        to={`/услуги/комплексное-продвижение`}
        className="dropdown-menu__item"
        onClick={onClick}
      >
        Комплексное продвижение
      </NavLink>
      <NavLink
        exact={true}
        activeClassName="dropdown-menu__item--active"
        to={`/услуги/smm-продвижение`}
        className="dropdown-menu__item"
        onClick={onClick}
      >
        SMM продвижение
      </NavLink>
      <NavLink
        exact={true}
        activeClassName="dropdown-menu__item--active"
        to={`/услуги/управление-репутацией-serm`}
        className="dropdown-menu__item"
        onClick={onClick}
      >
        Управление репутацией (SERM)
      </NavLink>
    </React.Fragment>
  );
};

export default ServiceMenu;
