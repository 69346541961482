import React from 'react';
import Select from './../Select';
import categories from './../../constants/categories';

const CategorySelect = (props) => {
  return (
    <Select
      {...props}
      options={categories}
      getOptionValue={option => option.title}
      getOptionLabel={option => option.title}
      placeholder={`Все категории`}
    />
  );
};

export default CategorySelect;
