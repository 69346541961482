import React from 'react';
import './ReadyButton.scss';
import Button from '../Button';
import {openAuthModal} from '../../actions/modalActions';
import {connect} from 'react-redux';

const ReadyButton = (props) => {
  return (
      <div className="ready-button">
        <div className="ready-button__title">
          Готовы начать?
        </div>
        <Button
            onClick={props.openAuthModal}
        >
          Зарегистрироваться
        </Button>
      </div>
  );
};

const mapDispatchToProps = dispatch => ({
  openAuthModal: () => dispatch(openAuthModal({tab: 'register'})),
});

export default connect(null, mapDispatchToProps)(ReadyButton);
