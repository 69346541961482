import React, {PureComponent} from 'react';
import './DropdownMenu.scss';

class DropdownMenu extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };

    this.$dropdown = null;
  }

  openDropdown = (e) => {
    e.preventDefault();

    this.setState({
      isOpen: true,
    });

    document.addEventListener('click', this.closeDropdown);
  };

  closeDropdown = (e) => {
    if (! this.$dropdown.contains(e.target)) {
      this.handleClose();
    }
  };

  handleClose = () => {
    this.setState({
      isOpen: false,
    });

    document.removeEventListener('click', this.closeDropdown);
  };

  render = () => {
    const {isOpen} = this.state;
    const {link: Link = () => {}, menu: Menu} = this.props;

    return (
      <div className="dropdown">
        {document.documentElement.clientWidth > 980 && (
          <Link
            activeClassName="menu__item--active"
            className={`menu__item ${isOpen ? 'menu__item--active' : ''}`}
            onClick={this.openDropdown}
          />
        )}

        {(isOpen || document.documentElement.clientWidth <= 980) && (
          <div
            className="dropdown-menu"
            ref={dropdown => {
              this.$dropdown = dropdown;
            }}
          >
            <Menu
              onClick={this.handleClose}
            />
          </div>
        )}
      </div>
    );
  }
}

export default DropdownMenu;
