import React from 'react';
import CategoryDirections from '../components/CategoryDirections';
import Examples from '../components/Examples';
import Consultation from '../components/Consultation';
import Steps from '../components/Steps';
import Background from '../components/Background';
import About from '../components/About';
import Button from '../components/Button';
import CategoryHero from '../components/CategoryHero';
import {connect} from 'react-redux';
import {openCategoryRequestModal} from '../actions/modalActions';
import CategoryRequest from '../components/CategoryRequest';
import HeroLegal from '../images/hero-legal.svg'
import CategoryLegal from '../images/category-legal.svg'

const LegalPage = (props) => {
  return (
      <React.Fragment>
        <Background color="white">
          <CategoryHero
              title={`Нужны лиды на юридическую тематику?`}
              description={`Оформите заявку и получайте клиентов уже сейчас.`}
              image={() => <img src={HeroLegal} alt=""/>}
          />

          <About
              title={`Что такое юридические лиды и зачем они вам?`}
              icon={() => <img src={CategoryLegal} alt=""/>}
          >
            <p>Лиды на юридические услуги позволят вашей компании заниматься непосредственно своим бизнесом –
              обслуживанием и приемом клиентов. Наша же задача — обеспечить вас бесперебойным потоком потенциальных
              заказчиков ваших услуг.</p>
            <p>
              Мы предлагаем простую и понятную услугу для любого бизнеса.
              <br/>
              Вы будете получать целевые профилированные заявки от ваших потенциальных клиентов (лиды) по удобным для
              вас объемам и условиям сотрудничества.
            </p>

            <Button onClick={props.openCategoryRequestModal}>
              Работать с нами!
            </Button>
          </About>

          <CategoryDirections
              title={
                <React.Fragment>
                  Мы предоставляем лиды&nbsp;
                  <span className="text-primary">в более чем 40 тематиках</span>
                </React.Fragment>
              }
              directions={[
                'Автомобильное право',
                'Авторское право',
                'Административное право',
                'Арбитраж',
                'Банкротство',
                'Бухгалтерский учет',
                'Военное право',
                'Госзакупки',
                'Государственное управление',
                'Гражданское право',
                'Гражданство',
                'Жилищное право',
                'Закон об ІТ',
                'Защита прав потребителей',
                'Земельное право',
                'Интелектуальная собственность',
                'Исполнительное право',
                'Конституционное право',
                'Кредитование',
                'Лицензирование',
                'Медицинское право',
                'Международное право',
                'Муниципальное право',
                'Налоговое право',
                'Наследство',
                'Недвижимость',
                'Образование',
                'Предпринимательское право',
                'Процессуальное право',
                'Сельское хозяйство',
                'Семейное право',
                'Социальное обеспечение',
                'Страхование',
                'Таможенное право',
                'Трудовое право',
                'Уголовное право',
                'Уголовно-исполнительное право',
                'Экологическое право',
              ]}
          />

          <Examples/>
        </Background>

        <Background color="grey">
          <Consultation/>
        </Background>

        <Background color="white">
          <Steps horizontal/>
        </Background>

        <Background color="grey-white">
          <CategoryRequest/>
        </Background>
      </React.Fragment>
  );
};

export default connect(null, {openCategoryRequestModal})(LegalPage);
