import React from 'react';
import Section from './../Section';
import SectionTitle from '../SectionTitle';
import SectionDescription from '../SectionDescription';
import ServiceRequestForm from '../ServiceRequestForm/ServiceRequestForm';

const ServiceRequest = ({title = '', description = ''}) => {
  return (
      <Section>
        <SectionTitle center>
          {title}
        </SectionTitle>
        <SectionDescription center>
          {description}
        </SectionDescription>

        <ServiceRequestForm
            style={{
              maxWidth: 380,
              margin: '0 auto',
            }}
        />
      </Section>
  );
};

export default ServiceRequest;
