import React from 'react';
import './CategoryDirections.scss';
import Container from './../Container';
import Section from './../Section';
import SectionTitle from './../SectionTitle';
import Button from './../Button';
import {openCategoryRequestModal} from '../../actions/modalActions';
import {connect} from 'react-redux';

const CategoryDirections = ({title = '', directions = [], ...props}) => {
  return (
    <Section>
      <Container>
        <SectionTitle>
          {title}
        </SectionTitle>
        <div className="category-directions">
          {directions.map((direction, index) => (
            <div
              key={index}
              className="category-directions__item"
            >
              {direction}
            </div>
          ))}
        </div>
        <Button onClick={props.openCategoryRequestModal}>
          Получить лиды
        </Button>
      </Container>
    </Section>
  );
};

export default connect(null, {openCategoryRequestModal})(CategoryDirections);
