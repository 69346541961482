import React from 'react';
import Select from './../Select';

const options = [
  { value: '20-30', label: '20 — 30' },
  { value: '40-50', label: '40 — 50' },
  { value: '60-100', label: '60 — 100' },
  { value: '-', label: 'Все что есть' },
];

const LeadCountSelect = ({value = null, ...props}) => {
  return (
    <Select
      {...props}
      value={value ? value : options[0]}
      options={options}
    />
  );
};

export default LeadCountSelect;
