import React from 'react';
import Select from './../Select';

const options = [
  {value: '10000', label: '10 000 руб'},
  {value: '15000', label: '15 000 руб'},
  {value: '25000', label: '25 000 руб'},
  {value: '50000', label: '50 000 руб'},
  {value: '100000', label: '100 000 руб'},
  {value: '-', label: 'Больше всех'},
];

const BudgetSelect = ({value = null, ...props}) => {
  return (
    <Select
      {...props}
      value={value ? value : options[0]}
      options={options}
    />
  );
};

export default BudgetSelect;
