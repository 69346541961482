import React from 'react';
import './Modal.scss';
import ReactModal from 'react-modal';
import {CloseIcon} from '../../icons';
import MobileDetect from 'mobile-detect';

ReactModal.setAppElement('#root');

const Modal = ({title = null, description = null, icon = null, children = null, isOpen = false, onRequestClose = () => {}, ...props}) => {
  return (
      <ReactModal
          {...props}
          isOpen={isOpen}
          onRequestClose={onRequestClose}
          className={`modal ${new MobileDetect(window.navigator.userAgent).phone() ? 'modal--phone' : ''}`}
          overlayClassName={`overlay ${new MobileDetect(window.navigator.userAgent).phone() ? 'overlay--phone' : ''}`}
      >
        <CloseIcon
            className="modal__close"
            onClick={onRequestClose}
        />

        <div className="modal__content">
          <div className="modal__title">
            {title}
          </div>

          {icon && (
              <div className="modal__icon">
                {icon}
              </div>
          )}

          {description && (
              <div className="modal__description">
                {description}
              </div>
          )}

          {children}
        </div>
      </ReactModal>
  );
};

export default Modal;
