import React, {PureComponent} from 'react';
import {storeContact} from './../../actions/contactActions';
import FormControl from './../FormControl';
import Input from './../Input';
import PhoneInput from './../PhoneInput';
import Button from './../Button';
import FileInput from './../FileInput';
import Checkbox from './../Checkbox';
import {MailIcon, UserIcon} from './../../icons';
import Errors from './../Errors';

const initialState = {
  data: {
    name: '',
    phone: '',
    email: '',
    message: '',
    attachments: [],
    personalData: false,
  },
  errors: new Errors({}),
};

class ContactForm extends PureComponent {
  constructor(props) {
    super(props);

    this.state = initialState;
  }

  handleChangeAttachments = (files) => {
    this.setState(prevState => ({
      data: {
        ...prevState.data,
        attachments: files,
      }
    }));
  };

  handleChange = (e) => {
    const {name, value, type, checked, files} = e.target;

    this.setState(prevState => ({
      data: {
        ...prevState.data,
        [name]: (type === 'checkbox' ? checked : (type === 'file' ? files : value)),
      }
    }));
  };

  handleSubmit = () => {
    const {data} = this.state;

    storeContact(data)
      .then(() => {
        this.setState(initialState);
        this.props.onSubmit();
      })
      .catch(errors => {
        this.setState({
          errors: new Errors(errors)
        })
      });
  };

  render = () => {
    const {withAttachments = false} = this.props;
    const {data: {name, phone, email, message, attachments, personalData}, errors} = this.state;

    return (
      <React.Fragment>
        <FormControl>
          <Input
            error={errors.get('name')}
            name="name"
            value={name}
            onChange={this.handleChange}
            placeholder={`Ваше имя`}
            icon={(props) => <UserIcon {...props}/>}
          />
        </FormControl>
        <FormControl>
          <PhoneInput
            error={errors.get('phone')}
            name="phone"
            value={phone}
            onChange={this.handleChange}
          />
        </FormControl>
        <FormControl>
          <Input
            error={errors.get('email')}
            type="email"
            name="email"
            value={email}
            onChange={this.handleChange}
            placeholder={`Ваш еmail`}
            icon={(props) => <MailIcon {...props}/>}
          />
        </FormControl>
        <FormControl>
          <Input
            error={errors.get('message')}
            multiline
            name="message"
            value={message}
            onChange={this.handleChange}
            placeholder={`Ваше сообщение…`}
          />
        </FormControl>

        {withAttachments && (
          <FormControl>
            <FileInput
              error={errors.get('attachments.*')}
              name="attachments"
              value={attachments}
              onChange={this.handleChangeAttachments}
            />
          </FormControl>
        )}

        <FormControl>
          <Checkbox
            checked={personalData}
            error={errors.get('personal_data')}
            name="personalData"
            onChange={this.handleChange}
          >
            Я даю своё&nbsp;
            <a
              href={`/согласие-на-обработку-персональных-данных`}
              target="_blank"
              rel="noopener noreferrer"
            >
              согласие на обработку персональных данных
            </a>
          </Checkbox>
        </FormControl>

        <FormControl>
          <Button
            fullWidth
            onClick={this.handleSubmit}
          >
            Отправить
          </Button>
        </FormControl>
      </React.Fragment>
    );
  };
}

export default ContactForm;
