import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {openSuccessModal} from './../../actions/modalActions';
import {storeContact} from './../../actions/contactActions';
import FormControl from './../FormControl';
import Input from './../Input';
import PhoneInput from './../PhoneInput';
import Button from './../Button';
import Checkbox from './../Checkbox';
import {MailIcon, UserIcon} from './../../icons';
import Errors from './../Errors';

const initialState = {
  data: {
    name: '',
    phone: '',
    email: '',
    message: '',
    personalData: false,
  },
  errors: new Errors({}),
};

class ConsultationForm extends PureComponent {
  constructor(props) {
    super(props);

    this.state = initialState;
  }

  handleChange = (e) => {
    const {name, value, type, checked} = e.target;

    this.setState(prevState => ({
      data: {
        ...prevState.data,
        [name]: type === 'checkbox' ? checked : value,
      }
    }));
  };

  handleSubmit = () => {
    const {data} = this.state;

    storeContact(data)
      .then(() => {
        this.setState(initialState);
        this.props.openSuccessModal();
      })
      .catch(errors => {
        this.setState({
          errors: new Errors(errors)
        })
      });
  };

  render() {
    const {data: {name, phone, email, message, personalData}, errors} = this.state;

    return (
      <React.Fragment>
        <FormControl>
          <Input
            error={errors.get('name')}
            name="name"
            value={name}
            onChange={this.handleChange}
            placeholder={`Ваше имя`}
            icon={(props) => <UserIcon {...props}/>}
          />
        </FormControl>
        <FormControl>
          <PhoneInput
            type="tel"
            error={errors.get('phone')}
            name="phone"
            value={phone}
            onChange={this.handleChange}
          />
        </FormControl>
        <FormControl>
          <Input
            error={errors.get('email')}
            type="email"
            name="email"
            value={email}
            onChange={this.handleChange}
            placeholder={`Ваш email`}
            icon={(props) => <MailIcon {...props}/>}
          />
        </FormControl>
        <FormControl>
          <Input
            error={errors.get('message')}
            multiline
            name="message"
            value={message}
            onChange={this.handleChange}
            placeholder={`Расскажите какие заявки вам нужны или другую информацию о вашем бизнесе…`}
          />
        </FormControl>
        <FormControl>
          <Checkbox
            checked={personalData}
            error={errors.get('personal_data')}
            name="personalData"
            onChange={this.handleChange}
          >
            Я даю своё&nbsp;
            <a
              href={`/согласие-на-обработку-персональных-данных`}
              target="_blank"
              rel="noopener noreferrer"
            >
              согласие на обработку персональных данных
            </a>
          </Checkbox>
        </FormControl>
        <FormControl>
          <Button
            fullWidth
            onClick={this.handleSubmit}
          >
            Получить консультацию
          </Button>
        </FormControl>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  openSuccessModal: () => dispatch(openSuccessModal({
    icon: () => <MailIcon/>,
    description: 'Спасибо, что оставили заявку, мы свяжемся с Вами в ближайшее время.'
  })),
});

export default connect(null, mapDispatchToProps)(ConsultationForm);
