import React from 'react';
import './Container.scss';

const Container = ({className = '', ...props}) => {
  return (
    <div
      {...props}
      className={`container ${className}`}
    />
  );
};

export default Container;
