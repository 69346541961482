import React from 'react';
import Input from '../Input';
import InputMask from 'react-input-mask';
import {PhoneIcon} from './../../icons';

const PhoneInput = ({value, onChange, ...props}) => {
  return (
    <InputMask
      mask="+7 (999) 999-99-99"
      value={value}
      onChange={onChange}
      // onChange={(e) => {
      //   if (e.target.value !== '+7 (___) ___-__-__') {
      //     onChange(e);
      //   }
      // }}
    >
      {inputProps => (
        <Input
          type="tel"
          {...props}
          {...inputProps}
          icon={props => <PhoneIcon {...props} />}
          placeholder={`+7 (___) ___-__-__`}
        />
      )}
    </InputMask>
  );
};

export default PhoneInput;
