import React from 'react';
import {PRODUCTION} from '../Constants';
import {YMInitializer} from 'react-yandex-metrika';


const YandexMetrika = () => {
  return (
      <>
        { process.env.NODE_ENV === PRODUCTION &&
        <YMInitializer accounts={ [86588477] }
                       options={ {
                         clickmap: true,
                         trackLinks: true,
                         accurateTrackBounce: true,
                         webvisor: true
                       } }
                       version="2"/>
        }

      </>
  );
};

export default YandexMetrika;
