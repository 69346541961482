import React from 'react';
import './Button.scss';

const Button = ({fullWidth, style = {}, size = null, ...props}) => {
  return (
    <button
      {...props}
      style={{
        ...style,
        width: fullWidth ? '100%' : 'auto',
      }}
      className={`button ${size === 'small' ? 'button--small' : ''}`}
    />
  );
};

export default Button;
