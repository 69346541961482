import React from 'react';
import Modal from './../Modal';
import {HandshakeIcon} from '../../icons';
import {connect} from 'react-redux';
import {openSuccessModal} from '../../actions/modalActions';
import {closePartnerModal} from '../../actions/modalActions';
import ContactForm from '../ContactForm';

const PartnerModal = (props) => {
  const {isOpen, closeModal, openSuccessModal} = props;

  return (
      <Modal
          isOpen={isOpen}
          onRequestClose={closeModal}
          title={`Написать сообщение`}
          description={`Хотите предложить ваше партнерство? Напишите нам мы будем рады обсудить с вами условия сотрудничества`}
          icon={<HandshakeIcon/>}
      >
        <ContactForm
            icon={<HandshakeIcon/>}
            onSubmit={() => {
              closeModal();
              openSuccessModal();
            }}
        />
      </Modal>
  );
};

const mapStateToProps = state => ({
  isOpen: state.modal.partner.isOpen
});

const mapDispatchToProps = dispatch => ({
  closeModal: () => dispatch(closePartnerModal()),
  openSuccessModal: () => dispatch(openSuccessModal({
    icon: () => <HandshakeIcon/>,
    description: '...'
  })),
});

export default connect(mapStateToProps, mapDispatchToProps)(PartnerModal);
