import * as actionTypes from './../actions/actionTypes';

const initialState = {
  auth: {
    isOpen: false,
    payload: {
      tab: null,
    },
  },
  success: {
    isOpen: false,
    payload: {
      icon: null,
      description: null,
    },
  },
  info: {
    isOpen: false,
    payload: {},
  },
  partner: {
    isOpen: false,
    payload: {},
  },
  support: {
    isOpen: false,
    payload: {},
  },
  forgotPassword: {
    isOpen: false,
    payload: {},
  },
  categoryRequest: {
    isOpen: false,
    payload: {},
  },
  serviceRequest: {
    isOpen: false,
    payload: {
      title: null,
      description: null,
    },
  },
};

const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.OPEN_MODAL:
      return {
        ...state,
        [action.modalType]: {
          isOpen: true,
          payload: action.payload,
        },
      };
    case actionTypes.CLOSE_MODAL:
      return {
        ...state,
        [action.modalType]: initialState[action.modalType],
      };
    default:
      return state;
  }
};

export default modalReducer;
