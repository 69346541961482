import React from 'react';
import './Policies.scss';
import {Link} from 'react-router-dom';

// Согласие на обработку персональных данных

const Policies = ({className = '', ...props}) => {
  return (
      <div className={`policies ${className}`}>
        <Link
            className="policies__link"
            to={`/политика-конфиденциальности`}
        >
          Политика конфиденциальности
        </Link>
        <Link
            className="policies__link"
            to={`/пользовательское-соглашение`}
        >
          Пользовательское соглашение
        </Link>
      </div>
  );
};

export default Policies;
