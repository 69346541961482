import React, {PureComponent} from 'react';
import Modal from '../Modal';
import {connect} from 'react-redux';
import {closeForgotPasswordModal} from './../../actions/modalActions';
import {openSuccessModal} from './../../actions/modalActions';
import {closeAuthModal} from './../../actions/modalActions';
import ForgotPasswordForm from './../ForgotPasswordForm'
import {MailIcon} from './../../icons';

class ForgotPasswordModal extends PureComponent {
  handleSubmit = () => {

    this.props.closeModal();
    this.props.openSuccessModal();
  };

  render = () => {
    const {isOpen} = this.props;

    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={this.props.closeModal}
        onAfterOpen={this.props.closeAuthModal}
        title={`Восстановление пароля`}
        description={`Введите электронную почту`}
      >
        <ForgotPasswordForm
          onSubmit={this.handleSubmit}
        />
      </Modal>
    );
  };
}

const mapStateToProps = state => ({
  isOpen: state.modal.forgotPassword.isOpen
});

const mapDispatchToProps = dispatch => ({
  closeAuthModal: () => dispatch(closeAuthModal()),
  closeModal: () => dispatch(closeForgotPasswordModal()),
  openSuccessModal: () => dispatch(openSuccessModal({
    icon: () => <MailIcon/>,
    description: 'Вам было отправлено письмо на восстановление доступа к аккаунту. Проверьте свою почту.'
  })),
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordModal);
