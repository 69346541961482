import React, {PureComponent} from 'react';
import './Examples.scss';
import Container from './../Container';
import Section from './../Section';
import SectionTitle from './../SectionTitle';
import SectionDescription from './../SectionDescription';
import CategorySelect from './../CategorySelect';
import Slider from './../Slider';
import Lead from './../Lead';
import examples from './../../constants/examples';
import SliderItem from '../SliderItem/SliderItem';

const _ = require('lodash');

class Examples extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      category: null,
    };
  }

  handleCategoryChange = (option) => {
    this.setState({
      category: option.title,
    });
  };

  filterExamples = () => {
    const {category} = this.state;

    return examples.filter(example => {
      if (category === null) {
        return true;
      }

      return example.category === category;
    });
  };

  render = () => {
    const size = document.documentElement.clientWidth < 980 ? 1 : 2;

    return (
        <Section>
          <Container>
            <div className="examples">
              <div className="examples__header">
                <div className="examples__title">
                  <SectionTitle>
                    Примеры лидов
                  </SectionTitle>
                  <SectionDescription>
                    Посмотрите пример заявок котрые вы можете получать!
                  </SectionDescription>
                </div>
                <div className="examples__filter">
                  <CategorySelect
                      customStyles={{
                        container: (provided, state) => ({
                          width: 300,
                        }),
                      }}
                      onChange={this.handleCategoryChange}
                  />
                </div>
              </div>

              <Slider>
                {_.chunk(this.filterExamples(), size).map((chunk, index) => (
                    <SliderItem key={index}>
                      {chunk.map((lead, index) => (
                          <Lead key={index} {...lead} />
                      ))}
                    </SliderItem>
                ))}
              </Slider>
            </div>
          </Container>
        </Section>
    );
  }
}

export default Examples;
