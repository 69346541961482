import React from 'react';
import Container from '../components/Container';
import SectionTitle from '../components/SectionTitle';
import { Paragraph } from '../components/Typography';
import Background from '../components/Background';
import Section from '../components/Section';

const PersonalDataPage = () => {
  return (
    <React.Fragment>
      <Container>
        <Background color="white">
          <Section>
            <SectionTitle>
              Согласие на обработку персональных данных
            </SectionTitle>

            <Paragraph>Я выражаю согласие на обработку своих персональных данных без оговорок и ограничений и
              подтверждаю, что, давая такое согласие, действую свободно, по своей воле и в своих интересах.</Paragraph>
            <Paragraph>1. Целью предоставления мною персональных данных для последующей обработки их Компанией является
              получение информационно-консультационных услуг и услуг технической поддержки Сайта и его
              сервисов.</Paragraph>
            <Paragraph>2. Я осознаю и соглашаюсь с тем, что настоящее согласие предоставляется на осуществление любых
              действий по обработке моих персональных данных, которые необходимы для достижения указанных целей, как с
              использованием средств автоматизации, так и без таковых, включая без ограничения: сбор, систематизацию,
              накопление, хранение, уточнение (обновление, изменение), получение от третьих лиц, использование,
              распространение (в том числе передача), обезличивание, блокирование, уничтожение, трансграничную передачу
              персональных данных, а также осуществление любых иных действий с моими персональными данными с учетом норм
              Федерального закона №152 «О персональных данных» от 27.07.2006 г.</Paragraph>
            <Paragraph>3. Подписание мною настоящего согласия (путём нажатия на кнопку «Зарегистрироваться»/ «Получить
              консультацию»/ «Получить лиды») распространяется на следующие персональные данные: имя; контактный номер
              телефона; адрес электронной почты (E-mail), регион местонахождения, автоматически собираемые данные
              (IP-адрес, cookies, информация о географическом положении, логи и данные передаваемые веб-страницей и
              сервером), а также другие данные предоставляемые мною по собственному усмотрению.</Paragraph>
            <Paragraph>4. Компания не проверяет достоверность предоставленных мною персональных данных. Компания исходит
              из того, что предоставленная мною персональная информация является достоверной и достаточной. Я осознаю,
              что несу ответственность за предоставление персональных данных третьего лица в соответствии с действующим
              законодательством.</Paragraph>
            <Paragraph>
              5. Я даю согласие на раскрытие Компанией моих персональных данных третьим лицам в целях получения
              информационно-консультационных услуг и услуг технической поддержки Сайта и его сервисов. Персональные
              данные передаются в соответствии с законодательством РФ. В случае, когда Компания передает мои
              персональные данные третьим лицам, она требует от третьих лиц соблюдение конфиденциальности моих
              персональных данных.
              <br/>
              В случае если Компания считает, что принятые ей меры не могут обеспечить полную защиту персональных данных
              при передаче, в т.ч. трансграничной, я соглашаюсь с тем, что мои персональные данные будут переданы в
              обезличенном виде, в случае если это не повлечет за собой неисполнимость услуг.
            </Paragraph>
            <Paragraph>6. Обработка осуществляется в соответствии с требованиями Федерального закона № 152 «О
              персональных данных» от 27.07.2006г. и Политикой конфиденциальности. Я подтверждаю, что ознакомлен с
              Политикой конфиденциальности, размещённой на сайте Компании.</Paragraph>
            <Paragraph>7. Настоящее согласие действует с момента регистрации на Сайте бессрочно. По окончании обработки
              моих персональных данных Компания, по истечении предусмотренных действующим законодательством сроков
              хранения документов, содержащих персональные данные, обеспечивает их уничтожение.</Paragraph>
            <Paragraph>8. Я подтверждаю, что проинформирован о том, что в любой момент в течение всего срока действия
              настоящего согласия, я вправе отозвать последнее путем направления обращения в адрес Компании и
              соглашаюсь, что она обязана прекратить обработку персональных данных и уничтожить эти данные в течение 30
              (тридцати) рабочих дней с момента получения указанного обращения. При этом я осознаю и принимаю, что такой
              отзыв может повлечь окончание предоставления мне услуг от Компании.</Paragraph>
          </Section>
        </Background>
      </Container>
    </React.Fragment>
  );
};

export default PersonalDataPage;
