export default [
  {
    category: 'Юриспруденция',
    name: 'Виктор',
    phone: '+792******47',
    location: 'Москва',
    message: 'Образовался долг пере кредиторами в размере 750 000р, сейчас нет возможности погасить задолженность, как мне пройти процедуру банкротства?',
  }, {
    category: 'Юриспруденция',
    name: 'Инга',
    phone: '+791******81',
    location: 'Санкт - Петербург',
    message: 'Заказала стиральную машину с доставкой и установкой, распаковки обнаружились дефекты (вмятина и царапина), доставщики отказались забрать товар, что делать?',
  }, {
    category: 'Страхование',
    name: 'Андрей',
    phone: '+792******34',
    location: 'Челябинск',
    message: 'Рассчитайте пожалуйста стоимость страховки для моего автомобиля BMW X5 2001 года, объем двигателя 5.5.',
  }, {
    category: 'Страхование',
    name: 'Мария',
    phone: '+795******75',
    location: 'Екатеринбург',
    message: 'Имею свой небольшой продуктовый магазин площадью 20 кв.м., хочу застраховать товар и помещение от несчастных случаев (пожар и т.д.). Жду звонка.',
  }, {
    category: 'Кредитование и финансирование',
    name: 'Сергей',
    phone: '+792******69',
    location: 'Москва, Московская область',
    message: 'Необходимая сумма займа 10 000 р, Гражданство РФ, З/П 50 000 р., срок 2 недели.',
  }, {
    category: 'Кредитование и финансирование',
    name: 'Леонид',
    phone: '+796******11',
    location: 'Москва',
    message: 'Ипотека. Необходимая сумма кредита - 1,5 млн. руб, объект: Квартира.',
  }, {
    category: 'Недвижимость, продажа и аренда',
    name: 'Дмитрий',
    phone: '+793******06',
    location: 'Санкт-Петербург',
    message: 'Молодая русская семья снимет квартиру на Длительный срок. Платежеспособные, работаем. Местоположение не имеет значения.',
  }, {
    category: 'Недвижимость, продажа и аренда',
    name: 'Наталья',
    phone: '+795******94',
    location: 'Москва',
    message: 'Куплю 3-х или 2-х ком. кв. улучшенной планировки в жилом состоянии с хорошей транспортной доступностью за наличный расчет на сумму 4,2 млн. руб.',
  }, {
    category: 'Строительство и ремонт',
    name: 'Андрей',
    phone: '+792******74',
    location: 'Московская область',
    message: 'Добрый день! Предварительно (дом еще не сдан) Хотелось бы узнать стоимость ремонта в новостройке в г.Химки (1 км от МКАД). Площадь квартиры 45 м.кв + балкон 3,8 м.кв.',
  }, {
    category: 'Строительство и ремонт',
    name: 'Николай',
    phone: '+793******97',
    location: 'Москва',
    message: 'Ремонт под ключ. Панельный дом серии 111-м, 4-х комнатная квартира. Есть стяжка на полу, возможна заливка наливным полом. Есть дизайн-проект. Прошу рассчитать предварительную стоимость.',
  }, {
    category: 'Туризм и путешествия',
    name: 'Анна',
    phone: '+798*****14',
    location: 'Москва',
    message: 'Не могли бы вы помочь в получении Шенгенской визы на длительный срок? Сколько это будет стоить?',
  }, {
    category: 'Туризм и путешествия',
    name: 'Олег',
    phone: '+792*****10',
    location: 'Тула',
    message: 'Хочу слетать в Тайланд по горячей путевке, но предлагают путевки тур.агенты посредники, из-за этого цена всегда завышена! Жду звонка или ответ на почту.',
  }, {
    category: 'Грузоперевозки и логистика',
    name: 'Анатолий',
    phone: '+791*****84',
    location: 'Москва',
    message: 'Требуются услуги профессиональной компании по переезду со старой квартиры в новую. Разборка и сборка мебели, упаковка и перевозка. Спасибо!',
  }, {
    category: 'Грузоперевозки и логистика',
    name: 'Карен',
    phone: '+791*****26',
    location: 'Санкт-Петербург',
    message: 'Перевозка скоропортящегося продукта (мясо). Необходим контейнер-холодильник для перевозки из Дагестана (г. Дербент) в Санкт-Петербург.',
  }, {
    category: 'Инвестирование (Forex)',
    name: 'Антон',
    phone: '+791*****04',
    location: 'Череповец',
    message: 'Имею небольшой опыт работы на финансовом рынке, слышал что у вас можно заключить договор с трейдерами на не большую сумму (до 1000 долларов), не могли бы вы рассказать об условиях?',
  }, {
    category: 'Инвестирование (Forex)',
    name: 'Жанна',
    phone: '+791*****04',
    location: 'Москва',
    message: 'Опыт работа на фин. рынке: до 1 года. Желаемая сумма инвестирования: 3000$. Желаемые инструменты: Нефть, Валютные пары. Жду звонка.',
  }, {
    category: 'IT и web-дизайн',
    name: 'Ирина',
    phone: '+795******21',
    location: 'Екатеринбург',
    message: 'Необходима разработка фирменного сайта медицинского центра. Каковы сроки и возможен ли выезд специалиста?',
  }, {
    category: 'IT и web-дизайн',
    name: 'Максим',
    phone: '+793******97',
    location: 'Иркутск',
    message: 'Сколько будет стоить разработка приложения для внутреннего документооборота компании в соответствии с тех. заданием?',
  }, {
    category: 'PR и маркетинг',
    name: 'Игорь',
    phone: '+792******22',
    location: 'Екатеринбург',
    message: 'Пожалуйста сообщите какова будет стоимость продвижения сайта *******.com Упор делаем на высокочастотные запросы.',
  }, {
    category: 'PR и маркетинг',
    name: 'Александр',
    phone: '+792******18',
    location: 'Москва, Московская область',
    message: 'Необходимо продвинуть мой сайт, сколько гарантированно посетителей я буду получать? Сколько времени займет продвижение? Сайт: *********.ru',
  }, {
    category: 'Медицина и косметология',
    name: 'Вадим',
    phone: '+793******32',
    location: 'Москва',
    message: 'Инсульт, 51 год. Буду ждать звонка, хочу обсудить со специалистом клиники.',
  }, {
    category: 'Медицина и косметология',
    name: 'Аркадий',
    phone: '+792******54',
    location: 'Челябинск',
    message: 'Диагноз: неспецифический язвенный колит кишечника. Беспокоят боли в кишечнике.',
  }, {
    category: 'Образование',
    name: 'Артем',
    phone: '+792******07',
    location: 'Ростов',
    message: 'Хочу выучить Английский язык. Узнал, что это можно сделать удаленно, свяжитесь со мной.',
  }, {
    category: 'Образование',
    name: 'Карина',
    phone: '+792******52',
    location: 'Москва',
    message: 'Скажите пожалуйста, как записаться на кулинарные курсы? Какова стоимость и план обучения? Спасибо!',
  }
];
