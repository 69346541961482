import React from 'react';
import './Lead.scss';
import {LocationIcon, PhoneIcon, UserIcon} from './../../icons';

const Lead = ({category, phone, name, location, message}) => {
  return (
    <div className="lead">
      <div className="lead__category">
        {category}
      </div>
      <div className="lead__contact-details">
        <div className="lead__phone">
          <PhoneIcon className="lead__icon"/>
          {phone}
        </div>
        <div className="lead__name">
          <UserIcon className="lead__icon"/>
          {name}
        </div>
        <div className="lead__location">
          <LocationIcon className="lead__icon"/>
          {location}
        </div>
      </div>
      <div className="lead__message">
        {message}
      </div>
    </div>
  );
};

export default Lead;
