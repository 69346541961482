import React from 'react';
import About from "../../components/About";
import Button from "../../components/Button";
import Features from "../../components/Features";
import ServiceHero from "../../components/ServiceHero";
import {ReactComponent as HeroImage} from './../../images/hero-context.svg';
import Background from "../../components/Background";
import Readiness from "../../components/Readiness";
import ServiceRequest from "../../components/ServiceRequest";
import {openServiceRequestModal} from "../../actions/modalActions";
import {connect} from "react-redux";
import ContextImage from '../../images/context.svg';
import EcommerceImage from '../../images/ecommerce.svg';
import TargetImage from '../../images/target.svg';
import GearsImage from '../../images/gears.svg';
import UserScopeImage from '../../images/user-scope.svg';
import HierarchicalImage from '../../images/hierarchical.svg';
import StatsImage from '../../images/stats.svg';

const ContextServicePage = (props) => {
  const {openServiceRequestModal} = props;

  return (
    <React.Fragment>
      <Background color="grey">
        <ServiceHero
          image={() => <HeroImage style={{margin: '-30px'}} />}
          title={`Нужны комплексные услуги по Контекстной Рекламе?`}
        >
          <p>
            Маркетинговый отдел Заявки.рф предлагает профессиональную настройку, запуск и дальнейшее ведение контекста для эффективной рекламной кампании в сети под вашу специфику бизнеса
          </p>
          <p>
            <strong>
              Оставьте заявку чтобы начать работу с нами!
            </strong>
          </p>
          <Button
            onClick={openServiceRequestModal}
          >
            Оставить заявку
          </Button>
        </ServiceHero>

        <About
          icon={() => <img width={160} height={150} src={ContextImage} alt="" />}
        >
          <p>
            Контекстная реклама — это рекламная кампания, подразумевающая отображение специально сгенерированных рекламных объявлений в выдаче поисковиков по заранее определенным запросам и заданной стоимости.
          </p>
          <p>
            Также показы осуществляются на партнерских сайтах в специально отведенных для этого блоках.
          </p>
          <Button
            onClick={openServiceRequestModal}
          >
            Работать с нами!
          </Button>
        </About>

        <Features
          type={`horizontal`}
          title={`Кому необходима Контекстная Реклама?`}
          items={[{
            icon: () => <img width={120} height={120} src={EcommerceImage} alt="" />,
            title: 'Продажа сезонных товаров, услуг и других целевых офферов',
            description: 'Если вы организуете какие-либо акции, специальные предложения или преследуете привлечение клиентов на конкретные цели, то контекстная реклама будет верным решением.',
          }, {
            icon: () => <img width={120} height={120} src={TargetImage} alt="" />,
            title: 'Получить целевой трафик в кратчайшие сроки',
            description: 'Разместить рекламные объявления в Яндекс.Директ или Google Ads можно в несколько кликов. Следовательно, и начать рекламировать себя можно течение 1–2 дней после принятия соответствующего решения.',
          }]}
        />

        <Features
          type={`horizontal`}
          title={`Что дает Контекстная Реклама?`}
          items={[{
            icon: () => <img width={120} height={120} src={GearsImage} alt="" />,
            title: 'Возможность точной и целевой настройки кампании',
            description: 'Мы можем помочь осуществить максимально точную настройку для получения наибольшей отдачи при наименьших затратах. Мы правильно настроим и подберем «Временной таргетинг», «Геотаргетинг» вашу рекламу увидит именно тот пользователь которого вы привлекаете',
          }, {
            icon: () => <img width={120} height={120} src={UserScopeImage} alt="" />,
            title: 'Привлечение только целевой аудитории',
            description: 'При настроенной кампании ваша контекстная реклама будет показываться только тем пользователям сети, которые наиболее заинтересованы в предлагаемом вами товаре или услуге (целевая аудитория).',
          }]}
        />

        <Readiness
          onClick={openServiceRequestModal}
        />

        <Features
          type={`vertical`}
          title={`Что входит в услугу ведения Контекстной Рекламы?`}
          items={[{
            icon: () => <img width={120} height={120} src={ContextImage} alt="" />,
            title: 'Оценку бюджетов и стоимости рекламы. Ручной подбор наиболее выгодных запросов для отображения контекста.',
            description: '',
          }, {
            icon: () => <img width={120} height={120} src={HierarchicalImage} alt="" />,
            title: 'Настройку рекламной кампании, разработка маркетинговой стратегии размещения.',
            description: '',
          }, {
            icon: () => <img width={120} height={120} src={StatsImage} alt="" />,
            title: 'Предоставление детальной отчетности и статистики о проведенных рекламных мероприятиях',
            description: '',
          }]}
        />
      </Background>

      <Background color="white">
        <ServiceRequest
          title={
            <React.Fragment>
              Оставьте заявку по услугам
              <br/>
              Контекстной Рекламы
            </React.Fragment>
          }
          description={`Заполните форму что начать работать с нами`}
        />
      </Background>
    </React.Fragment>
  );
};

const mapDispatchToProps = dispatch => ({
  openServiceRequestModal: () => dispatch(openServiceRequestModal({
    title: 'Оставьте заявку по услугам Контекстной Рекламы',
    description: 'Заполните форму что начать работать с нами',
  })),
});

export default connect(null, mapDispatchToProps)(ContextServicePage);
