import React from 'react';
import './About.scss';
import Container from './../Container';
import Section from './../Section';
import SectionTitle from './../SectionTitle';

const About = ({icon: Icon, title = '', children: description}) => {
  return (
    <Section>
      <Container>
        <SectionTitle>
          {title}
        </SectionTitle>
        <div className="about">
          <div className="about__icon">
            <Icon/>
          </div>
          <div className="about__description">
            {description}
          </div>
        </div>
      </Container>
    </Section>
  );
};

export default About;
