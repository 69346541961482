export default [
  {'title': 'Юриспруденция', 'url': ''},
  {'title': 'Страхование', 'url': ''},
  {'title': 'Кредитование и финансирование', 'url': ''},
  {'title': 'Недвижимость, продажа и аренда', 'url': ''},
  {'title': 'Строительство и ремонт', 'url': ''},
  {'title': 'Туризм и путешествия', 'url': ''},
  {'title': 'Грузоперевозки и логистика', 'url': ''},
  {'title': 'Инвестирование (Forex)', 'url': ''},
  {'title': 'IT и web-дизайн', 'url': ''},
  {'title': 'PR и маркетинг', 'url': ''},
  {'title': 'Медицина и косметология', 'url': ''},
  {'title': 'Образование', 'url': ''},
];
