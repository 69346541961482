import * as actionTypes from './../actions/actionTypes';

const initialState = {
  pending: false,
  user: null,
  isAuthenticated: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_PENDING:
      return {
        ...state,
        pending: true,
      };
    case actionTypes.FETCH_USER_SUCCESS:
      return {
        ...state,
        pending: false,
        user: action.user,
        isAuthenticated: true,
      };
    case actionTypes.FETCH_USER_ERROR:
      return initialState;
    default:
      return state;
  }
};

export default authReducer;
