import React from 'react';
import './MoreButton.scss';
import {ArrowDownIcon} from '../../icons';

const MoreButton = (props) => {
  return (
      <button
          {...props}
          className="more-button"
      >
        Подробнее
        <ArrowDownIcon
            className="more-button__icon"
        />
      </button>
  );
};

export default MoreButton;
