import React from 'react';
import './LogoutButton.scss';
import {LogoutIcon} from '../../icons';
import {connect} from 'react-redux';
import {logout} from '../../actions/authActions';

const LogoutButton = (props) => {
  return (
      <button
          className="logout-button"
          onClick={props.logout}
      >
        <LogoutIcon/>
      </button>
  );
};

export default connect(null, {logout})(LogoutButton);
