import React from 'react';
import './DrawerButton.scss';
import {connect} from 'react-redux';
import {openDrawer} from './../../actions/drawerActions';
import {MenuIcon} from './../../icons';

const DrawerButton = ({className = '', ...props}) => {
  return (
    <button
      className={`drawer-button ${className}`}
      onClick={props.openDrawer}
    >
      <MenuIcon/>
    </button>
  );
};

export default connect(null, {openDrawer})(DrawerButton);
