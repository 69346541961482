import React from 'react';
import Modal from './../Modal';
import {connect} from 'react-redux';
import {closeServiceRequestModal, openSuccessModal} from '../../actions/modalActions';
import ServiceRequestForm from '../ServiceRequestForm/ServiceRequestForm';

const ServiceRequestModal = (props) => {
  const {isOpen, title, description, closeModal} = props;

  return (
      <Modal
          isOpen={isOpen}
          onRequestClose={closeModal}
          title={title}
          description={description}
          icon={null}
      >
        <ServiceRequestForm
            onSubmit={closeModal}
        />
      </Modal>
  );
};

const mapStateToProps = state => ({
  isOpen: state.modal.serviceRequest.isOpen,
  title: state.modal.serviceRequest.payload.title,
  description: state.modal.serviceRequest.payload.description,
});

const mapDispatchToProps = dispatch => ({
  closeModal: () => dispatch(closeServiceRequestModal()),
  openSuccessModal: () => dispatch(openSuccessModal({
    icon: () => null,
    description: '...'
  })),
});

export default connect(mapStateToProps, mapDispatchToProps)(ServiceRequestModal);
