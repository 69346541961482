import React from 'react';
import Background from './../components/Background';
import Categories from './../components/Categories';
import Consultation from './../components/Consultation';
import LeadFeatures from './../components/LeadFeatures';
import CategoryRequest from './../components/CategoryRequest';

const CategoriesPage = () => {
  return (
    <React.Fragment>
      <Background color="grey">
        <Categories/>
      </Background>

      <Background color="white">
        <Consultation flat={true} />
        <LeadFeatures/>
      </Background>

      <Background color="grey-white">
        <CategoryRequest/>
      </Background>
    </React.Fragment>
  );
};

export default CategoriesPage;
