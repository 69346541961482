import React from 'react';
import './SectionDescription.scss';

const SectionDescription = ({className = '', center = false, ...props}) => {
  return (
    <div
      {...props}
      className={`
        section-description
        ${center ? 'section-description--center' : ''}
        ${className}
      `}
    />
  );
};

export default SectionDescription;
