import React from 'react';
import './NotFound.scss';
import Container from '../Container';
import Section from '../Section';
import Button from '../Button';
import {withRouter} from 'react-router-dom';
import Image404 from '../../images/404.svg'

const NotFound = (props) => {
  const {history} = props;

  return (
      <Section>
        <Container>
          <div className="not-found">
            <img className="not-found__image" src={Image404} alt=""/>

            <div className="not-found__title">
              Похоже что запрашиваемая вами страница
              <br/>
              не найдена или больше не существует
            </div>

            <Button
                onClick={() => history.push(`/`)}
            >
              На главную
            </Button>
          </div>
        </Container>
      </Section>
  );
};

export default withRouter(NotFound);
