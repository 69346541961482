import React from 'react';
import './Copyright.scss';

const Copyright = ({className = ''}) => {
 return (
   <div className={`copyright ${className}`}>
     Заявки.рф &copy; {new Date().getFullYear()}
   </div>
 );
};

export default Copyright;
