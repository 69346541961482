import * as actionTypes from './../actions/actionTypes';
import axios from 'axios';
const queryString = require('query-string');

export const fetchUser = () => dispatch => {
  dispatch({
    type: actionTypes.FETCH_USER_PENDING
  });

  axios.get('/account/user')
    .then(response => {
      dispatch({
        type: actionTypes.FETCH_USER_SUCCESS,
        user: response.data.results
      });
    }).catch(error => {
      dispatch({
        type: actionTypes.FETCH_USER_ERROR
      });
    });
};

export const login = ({ email, password }) => dispatch => {
  return new Promise((resolve, reject) => {
    axios.get('/sanctum/csrf-cookie').then(() => {
      axios.post('/auth/login', {
        email: email,
        password: password
      }).then(response => {
        dispatch({
          type: actionTypes.LOGIN,
          user: response.data.results
        });

        window.location.href = '/account';
      }).catch(error => {
        reject(error.response.data.errors);
      });
    });
  });
};

export const register = ({ name, phone, email, password, passwordConfirmation, location, businessType, count, budget, description, personalData, locationsOfInterest }) => dispatch => {
  return new Promise((resolve, reject) => {
    axios.get('/sanctum/csrf-cookie').then(() => {
      axios.post('/auth/register', {
        name: name,
        phone: phone,
        email: email,
        password: password,
        password_confirmation: passwordConfirmation,
        location_id: location ? location.id : null,
        business_type: businessType.title,
        lead_count: count.label,
        planned_budget: budget.label,
        description: description,
        personal_data: personalData,
        locations_of_interest: locationsOfInterest.map(x => x.id)
      }).then(response => {
        dispatch({
          type: actionTypes.REGISTER,
          user: response.data.results
        });

        window.location.href = '/account';
      }).catch(error => {
        reject(error.response.data.errors);
      });
    });
  });
};

export const logout = () => dispatch => {
  axios.post('/auth/logout').then(() => {
    dispatch({
      type: actionTypes.DESTROY_SESSION
    });
  });
};

export const forgotPassword = ({email}) => dispatch => {
  return new Promise((resolve, reject) => {
    axios.get('/sanctum/csrf-cookie').then(() => {
      axios.post('/auth/password/email', {
        email: email,
      }).then(response => {
        resolve();
      }).catch(error => {
        reject(error.response.data.errors);
      });
    });
  });
};

export const resetPassword = ({token, email, password, passwordConfirmation}) => dispatch => {
  return new Promise((resolve, reject) => {
    axios.get('/sanctum/csrf-cookie').then(() => {
      axios.post('/auth/password/reset', {
        token: token,
        email: email,
        password: password,
        password_confirmation: passwordConfirmation,
      }).then(response => {
        resolve();
      }).catch(error => {
        reject(error.response.data.errors);
      });
    });
  });
};

export const emailVerify = ({token, signature, expires}) => dispatch => {
  return new Promise((resolve, reject) => {
    axios.get('/sanctum/csrf-cookie').then(() => {
      axios.get(`/email/verify/${token}?` + queryString.stringify({
        signature: signature,
        expires: expires,
      })).then(response => {
        resolve();
      }).catch(error => {
        reject(error.response.data.errors);
      });
    });
  });
};



