import React from 'react';

const Column = ({size = 12, style, ...props}) => {
  return (
    <div
      {...props}

      style={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        flexDirection: 'column',
        width: '100%',
        maxWidth: document.documentElement.clientWidth < 440 ? 'auto' : `calc(100% / 12 * ${size})`,
        padding: '0 8px',
        ...style,
      }}
    />
  );
};

export default Column;
