import React from 'react';
import Container from '../components/Container';
import SectionTitle from '../components/SectionTitle';
import { Title, Paragraph, List } from '../components/Typography';
import Section from '../components/Section';
import Background from '../components/Background';
import BankDetails from '../components/BankDetails';

const PrivacyPolicyPage = () => {
  return (
    <React.Fragment>
      <Container>
        <Background color="white">
          <Section>
            <SectionTitle>
              Политика в отношении обработки персональных данных
            </SectionTitle>

            <Paragraph>
              Администрация сайта «Заявки.рф» (далее – Компания) предоставляет услуги посредством сайта
              https://заявки.рф (далее –
              Заявки.рф), приложения или связанных интернет-сервисов (далее – Услуги) пользователям этих программ (далее
              – Пользователи).
            </Paragraph>

            <Title>
              1. Общие положения
            </Title>

            <Paragraph>1.1. Настоящая Политика в отношении обработки персональных данных (далее – Политика)
              устанавливает правила использования Компанией персональной информации, получаемой от Пользователей или
              посетителей сайта Заявки.рф, приложения или связанных интернет-сервисов. Дополнительные правила о защите
              персональных данных могут применяться к определённым категориям Пользователей (Клиентам или Заказчикам).
              Текст Политики постоянно доступен Пользователям в сети Интернет по адресу:
              https://заявки.рф/политикаконфиденциальности</Paragraph>
            <Paragraph>1.2. Политика направлена на защиту прав и свобод физических лиц, персональные данные которых
              обрабатывает Компания. Политика разработана в соответствии с законодательством Российской Федерации. Все
              термины и определения, встречающиеся в тексте Политики толкуются в соответствии с Пользовательским
              соглашением и действующим законодательством РФ (в частности, ФЗ «О персональных данных».)</Paragraph>
            <Paragraph>1.3. Пользователи прямо соглашаются на обработку своих персональных данных, как это описано в
              настоящей Политике. Использование сайта Заявки.рф, приложения или связанных интернет-сервисов означает
              выражение Пользователем безоговорочного согласия с Политикой и указанными условиями обработки информации.
              Пользователь не должен пользоваться Сайтом, приложением или связанными интернет-сервисами, если
              Пользователь не согласен с условиями Политики.</Paragraph>

            <Title>
              2. Персональная информация Пользователей, которую обрабатывает Компания
            </Title>

            <Paragraph>2.1. Сайт собирает, получает доступ и использует в определенных Политикой целях персональные
              данные Пользователей, техническую и иную информацию, связанную с Пользователями.</Paragraph>
            <Paragraph>2.2. Под персональными данными Пользователя понимается информация, которую Пользователь
              предоставляет Компании при регистрации на Сайте и последующем использовании Сайта. Обязательная для
              предоставления Компании информация помечена специальным образом. Обязательной для предоставления
              Пользователями информацией является: имя, адрес электронной почты и номер телефона. Иная информация
              предоставляется Пользователем на его усмотрение.</Paragraph>
            <Paragraph>
              2.3. Техническая информация не является персональными данными, и включает:
            </Paragraph>

            <List
              items={ [
                'Автоматически собираемую информацию (IP-адрес, ID устройства, данные об используемом ПО, данные GPS, запись истории событий) для подтверждения Пользователя и его действий',
                'Информация о запросах и посещённых страницах Пользователей, теги пикселей для маркетинговых исследований Компании',
                'Файлы cookies для воспроизведения настроек Пользователя',
              ] }
            />

            <Paragraph>
              Компания может хранить и обрабатывать эту информацию самостоятельно или получать от партнёров, агентов и
              поставщиков, а также делиться ей с ними. Пользователь может отключить возможность использования файлов
              cookies в настройках браузера, удалить историю запросов и посещённых страниц, а также установить другие
              настройки приватности.
            </Paragraph>

            <Paragraph>2.4. Компания также может обрабатывать данные, сделанные общедоступными субъектом персональных
              данных или подлежащие опубликованию или обязательному раскрытию в соответствии с законом.</Paragraph>
            <Paragraph>2.5. Содержание и объем обрабатываемых персональных данных не является избыточными по отношению к
              заявленным целям их обработки.</Paragraph>
            <Paragraph>2.6. Компания не проверяет достоверность персональной информации, предоставляемой Пользователем,
              и не имеет возможности оценивать его дееспособность. Однако Компания исходит из того, что Пользователь
              предоставляет достоверную и достаточную персональную информацию о себе и поддерживает эту информацию в
              актуальном состоянии.</Paragraph>


            <Title>
              3. Цели обработки персональной информации Пользователей
            </Title>

            <Paragraph>
              3.1. Главная цель Компании при сборе персональных данных — предоставление услуг по использованию Сайта и
              его сервисов в исполнение Пользовательского соглашения. Пользователи соглашаются с тем, что Компания также
              может использовать их персональные данные для:
            </Paragraph>

            <List
              items={ [
                'идентификации Пользователя, сравнения персональных данных для подтверждения их точности и проверки их третьими сторонами в случаях, предусмотренных законодательством;',
                'предоставления услуг и клиентской поддержки по запросу Пользователей;',
                'разрешения споров, защита интересов в правоохранительных, либо иных государственных органах;',
                'выявления и пресечения мошеннических действий;',
                'поддержки и оптимизации Услуг и сервисов;',
                'разработки новых Услуг и сервисов;',
                'информирования Пользователей об услугах, рекламных предложениях на основании информационных предпочтений Пользователей;',
                'проведения статистических и иных исследований на основе обезличенных данных;',
              ] }
            />

            <Paragraph>
              3.2. Компания использует также техническую информацию в целях, указанных в пункте 3.1.
            </Paragraph>

            <Title>
              4. Условия и способы обработки персональной информации Пользователей и её передачи третьим лицам
            </Title>

            <Paragraph>4.1. Посетители дают согласие на обработку своих технических данных при переходе на сайт.
              Пользователь даёт согласие на обработку своих персональных данных путём регистрации на Сайте или отправки
              заявки.</Paragraph>
            <Paragraph>4.2. Обработка персональных данных Пользователя означает сбор, запись, систематизацию,
              накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу
              (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных
              данных Пользователя.</Paragraph>
            <Paragraph>4.3. В отношении персональной информации Пользователя сохраняется ее конфиденциальность, кроме
              случаев добровольного предоставления Пользователем информации о себе для общего доступа неограниченному
              кругу лиц.</Paragraph>
            <Paragraph>
              4.4. Компания вправе передать персональную информацию Пользователя третьим лицам в следующих случаях:
            </Paragraph>

            <List
              items={ [
                'Пользователь выразил согласие на такие действия;',
                'Передача необходима для использования Пользователем определенной услуги Сайта либо для исполнения определенного договора или соглашения с Пользователем;',
                'Передача уполномоченным органам государственной власти Российской Федерации по основаниям и в порядке, установленным законодательством Российской Федерации;',
                'Такая передача происходит в рамках продажи или иной передачи бизнеса (полностью или в части), при этом к приобретателю переходят все обязательства по соблюдению условий настоящей Политики применительно к полученной им персональной информации;',
                'Передача информации с целью проведения аудита;',
                'В целях обеспечения возможности защиты прав и законных интересов Компании или третьих лиц в случаях, когда Пользователь нарушает Пользовательское соглашение с Компанией, настоящую Политику, либо документы, содержащие условия использования конкретных услуг;',
                'В результате обработки персональной информации Пользователя путем ее обезличивания получены обезличенные статистические данные, которые передаются третьему лицу для проведения исследований и презентаций, выполнения работ или оказания услуг по поручению Компании.',
              ] }
            />

            <Paragraph>4.5. Третьи лица, получившие от Компании доступ к персональным данным, обязаны в дальнейшем не
              раскрывать третьим лицам и не распространять персональные данные без согласия субъекта персональных
              данных, если иное не предусмотрено федеральным законом.</Paragraph>
            <Paragraph>4.6. Обработка персональных данных Пользователя производится смешанным способом с использованием
              баз данных на территории Российской Федерации. Для достижения целей обработки персональных данных и с
              согласия субъектов персональных данных Оператор может осуществлять трансграничную передачу персональных
              данных. Вне зависимости от того, где именно осуществляется обработка персональных данных, Компания
              использует одни и те же меры обеспечения их безопасности, описанные в этой Политике.</Paragraph>
            <Paragraph>4.7. Настоящая Политика относиться только к Пользователям сайта Заявки.рф и не применяется к
              пользователям внешних сайтов, ссылки на которые могут быть размещены где-то на сайте Компании.</Paragraph>

            <Title>
              5. Изменение и удаление персональной информации. Обязательное хранение данных
            </Title>

            <Paragraph>5.1. Пользователь может в любой момент изменить (обновить, дополнить) предоставленную им
              персональную информацию или её часть, воспользовавшись функцией редактирования персональных данных в
              личном кабинете или обратившись к Компании через указанные на сайте контакты.</Paragraph>
            <Paragraph>5.2. Согласие на получение информационных рассылок и рекламных материалов может быть отозвано
              Пользователем в любое время с помощью имеющего на сайте функционала.</Paragraph>
            <Paragraph>
              5.3. Согласие на обработку персональных данных может быть отозвано Пользователем в любое время путем
              направления Компании уведомления через личный кабинет или указанные на Сайте контакты, а компания обязана
              прекратить обработку персональных данных и уничтожить их в соответствии с ч.5 статьи 25 федерального
              закона №152 «О персональных данных» от 26.07.2006.
              <br/>
              Уведомление об отзыве согласия на обработку персональных данных должно содержать данные заявителя,
              указанные им при регистрации. Образец уведомления можно запросить у Компании, обратившись по контактам,
              указанным на сайте.
            </Paragraph>
            <Paragraph>5.4. В случае направлении Пользователем обращения или запроса касательно п.5.1, 5.2, а также
              запроса на получение персональных данных, относящихся к Пользователю и обрабатываемых Компанией, и
              информации, касающейся их обработки, Компания в течение 5 (пяти) рабочих дней осуществляет необходимые
              действия с персональными данными. В случае направления Пользователем уведомления в соответствии с п.5.3
              Компания осуществляет необходимые действия в течении 30 (тридцати) рабочих дней.</Paragraph>
            <Paragraph>5.5. В случае отзыва субъектом персональных данных согласия на обработку персональных данных
              оператор вправе продолжить обработку персональных данных в допустимых российским законодательством
              случаях.</Paragraph>
            <Paragraph>5.6. В случае отзыва субъектом персональных данных согласия на обработку персональных данных
              Пользователь понимает, что это может повлечь невозможность предоставления услуг Компании.</Paragraph>
            <Paragraph>5.7. Компания обрабатывает персональные данные, техническую информацию и иную информацию
              Пользователя бессрочно.</Paragraph>

            <Title>
              6. Меры, применяемые для защиты персональной информации Пользователя
            </Title>

            <Paragraph>6.1. Компания принимает необходимые и достаточные правовые, организационные и технические меры
              для защиты персональной информации Пользователя от неправомерного или случайного доступа, уничтожения,
              изменения, блокирования, копирования, распространения, а также от иных неправомерных действий с ней
              третьих лиц.</Paragraph>
            <Paragraph>6.2. Компания не принимает решений, затрагивающих права и законные интересы Пользователей, на
              основании исключительно автоматизированной обработки персональных данных, за исключением случаев
              предоставления информации по результатам запроса, сделанного самим Пользователем с использованием
              интерфейса автоматизированных систем.</Paragraph>
            <Paragraph>6.3. При принятии юридически значимых решений, взаимодействии с Пользователями третьих лиц по
              просьбе Компании, во исполнение соглашений с Пользователями или по запросам Пользователей осуществляется
              неавтоматизированная обработка персональных данных в объёме, обусловленном целями такого взаимодействия, и
              с соблюдением требований о безопасности иных данных, не затрагиваемых обработкой.</Paragraph>
            <Paragraph>6.4. При утрате или разглашении персональных данных Компания информирует Пользователя об утрате
              или разглашении персональных данных.</Paragraph>
            <Paragraph>6.5. Компания совместно с Пользователем принимает все необходимые меры по предотвращению убытков
              или иных отрицательных последствий, вызванных утратой или разглашением персональных данных
              Пользователя.</Paragraph>
            <Paragraph>
              6.6. В случае утраты или разглашения персональной информации Компания не несёт ответственность, если
              данная персональная информация:
            </Paragraph>

            <List
              items={ [
                'Стала публичным достоянием до её утраты или разглашения;',
                'Была получена от третьей стороны до момента её получения Компанией;',
                'Была разглашена с согласия Пользователя;',
                'Раскрыта в соответствии  с актом компетентного государственного органа или суда.',
              ] }
            />

            <Title>
              7. Разрешение споров
            </Title>

            <Paragraph>7.1. Все споры и разногласия, которые могут возникнуть в отношении применения настоящих правил,
              будут, по возможности, решаться Сторонами путем переговоров. Соблюдение досудебного (претензионного)
              порядка урегулирования споров является обязательным. Срок направления ответа на претензию составляет 10
              (десять) рабочих дней с момента ее получения Стороной.</Paragraph>
            <Paragraph>7.2. Все возможные споры, вытекающие из отношений, регулируемых настоящей Политикой, разрешаются
              в порядке, установленном действующим законодательством Российской Федерации, по нормам российского права,
              независимо от места пребывания Пользователя.</Paragraph>
            <Paragraph>7.3. В случае если Стороны не придут к взаимному согласию, то возникший спор подлежит разрешению
              в судебном порядке в соответствии с требованиями действующего законодательства Российской Федерации в суде
              по месту нахождения Компании.</Paragraph>

            <Title>
              8. Дополнительные условия
            </Title>

            <Paragraph>8.1. Компания вправе вносить изменения в настоящую Политику конфиденциальности без согласия
              Пользователя.</Paragraph>
            <Paragraph>8.2. Новая Политика конфиденциальности вступает в силу с момента ее размещения на Сайте Компании,
              если иное не предусмотрено новой редакцией Политики конфиденциальности.</Paragraph>
            <Paragraph>8.3. Продолжение использования Сайта после внесения таких изменений подтверждает согласие
              Пользователя с такими изменениями.</Paragraph>
            <Paragraph>8.4. Все предложения или вопросы по поводу настоящей Политики Пользователь вправе направлять
              куратору через Сайт или по адресу: info@заявки.рф.</Paragraph>

            <br/>

            <Paragraph>Дата последних изменений: 12.05.2021</Paragraph>

            <Title>
              9. Реквизиты компании
            </Title>

            <Paragraph>
              <BankDetails/>
            </Paragraph>
          </Section>
        </Background>
      </Container>
    </React.Fragment>
  );
};

export default PrivacyPolicyPage;
