import React from 'react';
import Examples from '../components/Examples';
import Categories from '../components/Categories';
import Consultation from '../components/Consultation';
import Steps from '../components/Steps';
import Background from '../components/Background';
import About from '../components/About';
import Features from '../components/Features';
import LeadFeatures from '../components/LeadFeatures';
import CategoryRequest from '../components/CategoryRequest';
import CategoryHero from '../components/CategoryHero';
import HeroPeople from '../images/hero-people.svg'
import LeadAbout from '../images/lead-about.svg'
import FeaturesClient from '../images/features-clients.svg'
import FeaturesProfit from '../images/features-profit.svg'
import FeaturesPlaning from '../images/features-planning.svg'

const HomePage = () => {

  return (
      <React.Fragment>
        <Background color="grey">
          <CategoryHero
              title={
                <React.Fragment>
                  Нужны лиды для
                  <br/>
                  вашего бизнеса?
                </React.Fragment>
              }
              description={`Оформите заявку и получайте клиентов уже сейчас`}
              image={() => <img src={HeroPeople} alt=""/>}
          />

          <About
              title={`Что такое лиды и зачем они вам?`}
              icon={() => <img width={245} style={{marginLeft: -75}} src={LeadAbout}
                               alt=""/>}
          >
            <p>
              <strong>Лиды</strong> - это готовый продукт рекламной деятельности в виде звонка или письменно оформленная
              заявка на услугу от ваших потенциальных клиентов
            </p>
            <p>
              Мы предлагаем простую и понятную услугу для любого бизнеса.
              <br/>
              Вы будете получать целевые профилированные заявки от ваших потенциальных клиентов (лиды) по удобным для
              вас объемам и условиям сотрудничества
            </p>
          </About>

          <Features
              type={`vertical`}
              title={`Лиды для вас – это:`}
              items={[{
                icon: () => <img height={80} width={120} src={FeaturesClient} alt=""/>,
                title: 'Поток новых клиентов',
                description: 'Предлагаем вам получить и преумножить вашу клиентскую базу и получать новых клиентов в том объеме в котором вы желаете',
              }, {
                icon: () => <img height={80} width={120} src={FeaturesProfit} alt=""/>,
                title: 'Рост прибыли вашего дела',
                description: 'Благодаря стабильному потоку клиентов и получения релевантных заявок вашему профилю, рост вашей прибыли не заставит себя ждать',
              }, {
                icon: () => <img height={80} width={120} src={FeaturesPlaning} alt=""/>,
                title: 'Планирование и увереность в завтрышнем дне',
                description: 'Стабильный поток клиентов поможет вам планировать развитие вашего бизнеса и иметь увереность в стабильном доходе от вашей деятельности',
              }]}
          />

          <LeadFeatures/>
        </Background>

        <Background color="white">
          <Examples/>
          <Categories/>
        </Background>

        <Background color="grey">
          <Consultation/>
        </Background>

        <Background color="white">
          <Steps/>
        </Background>

        <Background color="grey-white">
          <CategoryRequest/>
        </Background>
      </React.Fragment>
  );
};

export default HomePage;
