import React, {PureComponent} from 'react';
import FormControl from "../FormControl";
import InputLabel from "../InputLabel";
import LocationSelect from "../LocationSelect";
import {Column, Row} from "../Grid";
import LeadCountSelect from "../LeadCountSelect";
import BudgetSelect from "../BudgetSelect";
import Input from "../Input";
import Button from "../Button";
import {MailIcon, UserIcon} from "../../icons";
import PhoneInput from "../PhoneInput";
import Errors from "../Errors";
import {storeRequest} from "../../actions/requestActions";
import {openSuccessModal} from "../../actions/modalActions";
import {connect} from "react-redux";
import Checkbox from "../Checkbox";
import CategorySelect from "../CategorySelect";
import MobileDetect from "mobile-detect";

const initialState = {
  activeStep: 1,
  wrapperHeight: null,
  data: {
    name: '',
    phone: '',
    email: '',
    location: null,
    businessType: null,
    leadCount: { value: '20-30', label: '20 — 30' },
    plannedBudget: {value: '10000', label: '10 000 руб'},
    description: '',
    personalData: '',
  },
  errors: new Errors([]),
};

class CategoryRequestForm extends PureComponent {
  constructor(props) {
    super(props);

    this.state = initialState;

    this.$wrapper = null;
  }

  componentDidMount = () => {
    if (! new MobileDetect(window.navigator.userAgent).phone()) {
      this.setState({
        wrapperHeight: this.$wrapper.offsetHeight
      });
    }
  };

  openSecondStep = () => {
    this.setState({
      activeStep: 2,
    })
  };

  handleSelectChange = (name, option) => {
    const {errors} = this.state;

    this.setState(prevState => ({
      data: {
        ...prevState.data,
        [name]: option,
      },
      errors: errors,
    }));
  };

  handleChange = (e) => {
    const {name, value, type, checked} = e.target;

    this.setState(prevState => ({
      data: {
        ...prevState.data,
        [name]: type === 'checkbox' ? checked : value,
      }
    }));
  };

  handleSubmit = () => {
    const {onSubmit = () => {}} = this.props;
    const {data} = this.state;

    storeRequest(data)
      .then(() => {
        this.setState(initialState);
        this.props.openSuccessModal();

        onSubmit();
      })
      .catch(errors => {
        this.setState({
          errors: new Errors(errors)
        })
      });
  };


  render = () => {
    const {style, flat} = this.props;
    const {activeStep, errors, wrapperHeight} = this.state;
    const {name, phone, email, location, businessType, leadCount, plannedBudget, description, personalData} = this.state.data;

    return (
      <div style={style}>
        {activeStep === 1 && (
          <React.Fragment>
            <div
              style={{
                height: wrapperHeight,
              }}
              ref={wrapper => this.$wrapper = wrapper}
            >
              <FormControl>
                <InputLabel>
                  Регион
                </InputLabel>
                <LocationSelect
                  error={errors.get('location_id')}
                  value={location}
                  onChange={option => this.handleSelectChange('location', option)}
                />
              </FormControl>
              <FormControl>
                <InputLabel>
                  Тип бизнеса
                </InputLabel>
                <CategorySelect
                  error={errors.get('business_type')}
                  value={businessType}
                  onChange={option => this.handleSelectChange('businessType', option)}
                />
              </FormControl>
              {flat ? (
                <React.Fragment>
                  <FormControl>
                    <InputLabel>
                      Количество лидов в сутки
                    </InputLabel>
                    <LeadCountSelect
                      error={errors.get('lead_count')}
                      value={leadCount}
                      onChange={option => this.handleSelectChange('leadCount', option)}
                    />
                  </FormControl>
                  <FormControl>
                    <InputLabel>
                      Планируемый бюжет в месяц
                    </InputLabel>
                    <BudgetSelect
                      error={errors.get('planned_budget')}
                      value={plannedBudget}
                      onChange={option => this.handleSelectChange('plannedBudget', option)}
                    />
                  </FormControl>
                </React.Fragment>
              ) : (
                <Row>
                  <Column size={6}>
                    <FormControl>
                      <InputLabel>
                        Количество лидов в сутки
                      </InputLabel>
                      <LeadCountSelect
                        error={errors.get('lead_count')}
                        value={leadCount}
                        onChange={option => this.handleSelectChange('leadCount', option)}
                      />
                    </FormControl>
                  </Column>
                  <Column size={6}>
                    <FormControl>
                      <InputLabel>
                        Планируемый бюжет в месяц
                      </InputLabel>
                      <BudgetSelect
                        error={errors.get('planned_budget')}
                        value={plannedBudget}
                        onChange={option => this.handleSelectChange('plannedBudget', option)}
                      />
                    </FormControl>
                  </Column>
                </Row>
              )}
              <FormControl>
                <Input
                  error={errors.get('description')}
                  multiline
                  name="description"
                  value={description}
                  onChange={this.handleChange}
                  placeholder={`Расскажите какие заявки вам нужны или другую информацию о вашем бизнесе…`}
                />
              </FormControl>
            </div>
            <FormControl>
              <Button
                fullWidth
                onClick={this.openSecondStep}
              >
                Получить лиды
              </Button>
            </FormControl>
          </React.Fragment>
        )}

        {activeStep === 2 && (
          <React.Fragment>
            <div
              style={{
                height: wrapperHeight,
              }}
            >
              <FormControl>
                <Input
                  error={errors.get('name')}
                  name="name"
                  value={name}
                  onChange={this.handleChange}
                  placeholder={`Ваше имя`}
                  icon={(props) => <UserIcon {...props}/>}
                />
              </FormControl>
              <FormControl>
                <PhoneInput
                  error={errors.get('phone')}
                  name="phone"
                  value={phone}
                  onChange={this.handleChange}
                />
              </FormControl>
              <FormControl>
                <Input
                  error={errors.get('email')}
                  type="email"
                  name="email"
                  value={email}
                  onChange={this.handleChange}
                  placeholder={`Ваш email`}
                  icon={(props) => <MailIcon {...props}/>}
                />
              </FormControl>
              <FormControl>
                <Checkbox
                  checked={personalData}
                  error={errors.get('personal_data')}
                  name="personalData"
                  onChange={this.handleChange}
                >
                  Я даю своё&nbsp;
                  <a
                    href={`/согласие-на-обработку-персональных-данных`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    согласие на обработку персональных данных
                  </a>
                </Checkbox>
              </FormControl>
            </div>
            <FormControl>
              <Button
                fullWidth
                onClick={this.handleSubmit}
              >
                Отправить
              </Button>
            </FormControl>
          </React.Fragment>
        )}
      </div>
    );
  };
}

const mapDispatchToProps = dispatch => ({
  openSuccessModal: () => dispatch(openSuccessModal({
    icon: () => <MailIcon/>,
    description: 'Спасибо, что оставили заявку, мы свяжемся с Вами в ближайшее время.'
  })),
});

export default connect(null, mapDispatchToProps)(CategoryRequestForm);
