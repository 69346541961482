import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {emailVerify} from './../../actions/authActions';
import Modal from './../Modal';
import {CheckIcon, CloseIcon, MailIcon} from './../../icons';
const queryString = require('query-string');

const initialState = {
  isOpen: false,
  status: null,
};

class EmailVerifyModal extends PureComponent {
  constructor(props) {
    super(props);

    this.state = initialState;
  }

  componentDidMount() {
    const {token} = this.props.match.params;
    const {signature, expires} = queryString.parse(this.props.location.search);

    this.props.emailVerify({
      token: token,
      signature: signature,
      expires: expires,
    }).then(() => {
      this.setState({
        status: 'success',
      });
    }).catch(() => {
      this.setState({
        status: 'failure',
      });
    });

    this.setState({
      isOpen: this.props.isOpen || false,
    })
  }

  handleClose = () => {
    this.setState({
      isOpen: false,
    });

    this.props.history.push({
      pathname: `/`
    });
  };

  render = () => {
    const {isOpen, status} = this.state;

    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={this.handleClose}
        title={
          <React.Fragment>
            Подтверждение
            <br/>
            электронного адреса
          </React.Fragment>
        }
      >
        <div className="status">
          <div className="status__icon">
            <MailIcon/>

            {status === 'success' && <CheckIcon className="status__badge status__badge--success" />}
            {status === 'failure' && <CloseIcon className="status__badge status__badge--failure" />}
          </div>
          <div className="status__description">
            {status === 'success' && (
              <React.Fragment>
                Ваш электронный адрес
                <br/>
                подтвержден успешно
              </React.Fragment>
            )}

            {status === 'failure' && (
              <React.Fragment>
                Ссылка, по которой вы перешли,
                <br/>
                истекла или недействительна.
              </React.Fragment>
            )}
          </div>
        </div>
      </Modal>
    );
  };
}

export default connect(null, {emailVerify})(withRouter(EmailVerifyModal));
