import React from 'react';

const Paragraph = (props) => {
  return (
    <p
      {...props}
      style={{
        fontSize: 16,
        lineHeight: 1.4,
        marginBottom: 16,
        textAlign: 'justify'
      }}
    />
  );
};

export default Paragraph;
