import React from 'react';
import About from "../../components/About";
import Button from "../../components/Button";
import Features from "../../components/Features";
import ServiceHero from "../../components/ServiceHero";
import {ReactComponent as HeroImage} from './../../images/hero-seo-complex.svg';
import Background from "../../components/Background";
import Readiness from "../../components/Readiness";
import ServiceRequest from "../../components/ServiceRequest";
import {openServiceRequestModal} from "../../actions/modalActions";
import {connect} from "react-redux";
import ComplexSeo from '../../images/complex-seo.svg';
import StartUp from '../../images/start-up.svg';
import Grow from '../../images/grow.svg';
import Strategy from '../../images/strategy.svg';
import AdsSets from '../../images/ads-sets.svg';
import Analytics from '../../images/analitics.svg';
import Gears from '../../images/gears.svg';
import Planing from '../../images/planing.svg';
import Development from '../../images/development.svg';
import ComplexUpload from '../../images/complex-upload.svg';

const SeoServicePage = (props) => {
  const {openServiceRequestModal} = props;

  return (
    <React.Fragment>
      <Background color="grey">
        <ServiceHero
          image={() => <HeroImage style={{margin: '-30px'}} />}
          title={`Нужно Комплексное Продвижение вашего сайта?`}
        >
          <p>
            Мы предлагаем комплексные услуги по поисковой оптимизации и комплексном продвижении веб-продуктов вашего бизнеса. Мы предоставляем весь спектр услуг которые помогут вам достигнуть желаемых показателей!
          </p>
          <p>
            <strong>
              Оставьте заявку чтобы начать работу по продвижению!
            </strong>
          </p>
          <Button
            onClick={openServiceRequestModal}
          >
            Оставить заявку
          </Button>
        </ServiceHero>

        <About
          icon={() => <img width={160} height={150} src={ComplexSeo} alt="" />}
        >
          <p>
            Комплекстное продвижение — это полный спектр мероприятий от поисковой и технической оптимизации ресурса до ведения стратегических и маркетинговых работ по увеличению экономической эффективности вашего бизнеса.
          </p>
          <p>
            Комплексный подход позволяет развивать ваш проект во все возможных направления и достигать должных результатов!
          </p>
          <Button
            onClick={openServiceRequestModal}
          >
            Работать с нами!
          </Button>
        </About>

        <Features
          type={`horizontal`}
          title={`Кому необходима Контекстная Реклама?`}
          items={[{
            icon: () => <img width={120} height={120} src={StartUp} alt="" />,
            title: 'Новым компаниям и проектам желающим занять своё место на рынке',
            description: 'Если вы и ваш проект только вышли на рынок услуг, комплексное продвижение это верный шаг начать развиваться в правильном ключе, иметь правильную стратегию достижения целей и добиваться желаемой эффективности с самого начала.',
          }, {
            icon: () => <img width={120} height={120} src={Grow} alt="" />,
            title: 'Компаниям и проектам желающим добиться больших результатов на рынке!',
            description: 'Ваш бизнес или проект нуждается в более эффективном развитии и достижении результатов? Комплексный подход будет очень полезен для анализа текущего положения на рынке и коректировки вектора продвижения и развития для достижения больших результатов',
          }]}
        />

        <Features
          type={`horizontal`}
          title={`Что дает Комплексное Продвижение?`}
          items={[{
            icon: () => <img width={120} height={120} src={Strategy} alt="" />,
            title: 'Четкий план и стратегия продвижения и развития',
            description: 'Мы можем помочь осуществить максимально точную настройку для получения наибольшей отдачи при наименьших затратах. Мы правильно настроим и подберем «Временной таргетинг», «Геотаргетинг» вашу рекламу увидит именно тот пользователь которого вы привлекаете',
          }, {
            icon: () => <img width={120} height={120} src={AdsSets} alt="" />,
            title: 'Проведение необходимых мероприятий, рекламных кампаний, маркетинговых стратегий',
            description: 'Проведение рекламных кампании и реализация маркетинговых стратегий осуществляется по четко установленным принципам и поставленным целям. Четко продуманная стратегия и правильно поставленные задачи и цели становятся залогом успешного продвижения любого товара или услуги на рынке.',
          }, {
            icon: () => <img width={120} height={120} src={Analytics} alt="" />,
            title: 'Потребительская статистика и аналитика рынка, позволяющая понимать правильный вектор развития',
            description: 'Системы веб-аналитики выявляют слабые стороны бизнес процессов, с их помощью можно существенно сэкономить рекламный бюджет, умножить и сохранить прибыль. Своевременное внедрение рекомендаций и мониторинг текущего состояния проекта позволяют всегда быть на шаг впереди конкурентов.',
          }, {
            icon: () => <img width={120} height={120} src={Gears} alt="" />,
            title: 'Техническая и объективная реализация поставленных задач и достижения результата',
            description: 'Основываясь на результатах анализа вашего проекта и ваших потенциальных конкурентов,вы получите как технические и статистические данные, так и полноценный план действий и необходимых мер для улучшения вашего ресурса и последующая реализация намеченных планов',
          }]}
        />

        <Readiness
          onClick={openServiceRequestModal}
        />

        <Features
          type={`vertical`}
          title={`Что входит в услугу ведения Комплексного Продвижения?`}
          items={[{
            icon: () => <img width={120} height={120} src={Planing} alt="" />,
            title: 'Анализ вашего проекта, оперделение целей, мероприятий и составления комплексного плана продвижения',
            description: '',
          }, {
            icon: () => <img width={120} height={120} src={Development} alt="" />,
            title: 'Техническая отладка, поисковая оптимизация и подготовка проекта к поисковому и контекстному продвижению на рекламных площадках',
            description: '',
          }, {
            icon: () => <img width={120} height={120} src={ComplexUpload} alt="" />,
            title: 'Внедрение и сопровождение разработанных комплексных мероприятий для достижения поставленных задач и целей',
            description: '',
          }]}
        />
      </Background>

      <Background color="white">
        <ServiceRequest
          title={
            <React.Fragment>
              Оставьте заявку по услугам
              <br/>
              Комплексного Продвижения
            </React.Fragment>
          }
          description={`Заполните форму чтобы начать работать с нами`}
        />
      </Background>
    </React.Fragment>
  );
};

const mapDispatchToProps = dispatch => ({
  openServiceRequestModal: () => dispatch(openServiceRequestModal({
    title: 'Оставьте заявку по услугам Комплексного Продвижения',
    description: '',
  })),
});

export default connect(null, mapDispatchToProps)(SeoServicePage);
