import { combineReducers } from 'redux';
import * as actionTypes from './../actions/actionTypes';
import modalReducer from './modalReducer';
import authReducer from './authReducer';
import drawerReducer from './drawerReducer';

const appReducer = combineReducers({
  auth: authReducer,
  modal: modalReducer,
  drawer: drawerReducer,
});

const rootReducer = (state, action) => {
  if (action.type === actionTypes.DESTROY_SESSION) {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
