import React from 'react';

const Row = ({style, ...props}) => {
  return (
    <div
      {...props}

      style={{
        display: 'flex',
        // justifyContent: 'flex-start',
        // alignItems: 'flex-start',
        flexWrap: 'wrap',
        width: 'calc(100% + 16px)',
        margin: '0 -8px',
        ...style,
      }}
    />
  );
};

export default Row;
