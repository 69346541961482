import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {resetPassword} from '../../actions/authActions';
import FormControl from '../FormControl';
import Input from '../Input';
import {PasswordIcon} from '../../icons';
import Button from '../Button';
import Errors from '../Errors';

const queryString = require('query-string');

const initialState = {
  data: {
    token: null,
    email: '',
    password: '',
    passwordConfirmation: '',
  },
  errors: new Errors({}),
};

class ResetPasswordForm extends PureComponent {
  constructor(props) {
    super(props);

    this.state = initialState;
  }

  componentDidMount() {
    const {email, token} = queryString.parse(this.props.location.search);

    this.setState(prevState => ({
      data: {
        ...prevState.data,
        email: email,
        token: token,
      }
    }));
  }

  handleChange = (e) => {
    const {name, value, type, checked} = e.target;

    this.setState(prevState => ({
      data: {
        ...prevState.data,
        [name]: type === 'checkbox' ? checked : value,
      }
    }));
  };


  handleSubmit = () => {
    const {data: {token, email, password, passwordConfirmation}} = this.state;

    this.props.resetPassword({
      token: token,
      email: email,
      password: password,
      passwordConfirmation: passwordConfirmation,
    }).then(() => {
      this.setState(initialState);
      this.props.onSubmit();
    }).catch(errors => {
      this.setState({
        errors: new Errors(errors)
      })
    });
  };


  render = () => {
    const {data: {password, passwordConfirmation}, errors} = this.state;

    return (
        <React.Fragment>
          <FormControl>
            <Input
                error={errors.get('password')}
                type="password"
                name="password"
                value={password}
                onChange={this.handleChange}
                icon={props => <PasswordIcon {...props} />}
                placeholder={`Новый пароль`}
            />
          </FormControl>
          <FormControl>
            <Input
                type="password"
                name="passwordConfirmation"
                value={passwordConfirmation}
                onChange={this.handleChange}
                icon={props => <PasswordIcon {...props} />}
                placeholder={`Повторите пароль`}
            />
          </FormControl>
          <FormControl>
            <Button
                fullWidth
                onClick={this.handleSubmit}
            >
              Сменить пароль
            </Button>
          </FormControl>
        </React.Fragment>
    );
  };
}


const mapDispatchToProps = dispatch => ({
  resetPassword: props => dispatch(resetPassword(props)),
});


export default connect(null, mapDispatchToProps)(withRouter(ResetPasswordForm));
