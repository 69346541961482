import React from 'react';
import './Footer.scss';
import Container from './../Container';
import {ReactComponent as Logo} from './../../images/footer-logo.svg';
import FooterMenu from './../FooterMenu';
import Copyright from './../Copyright';
import Policies from './../Policies';

const Footer = () => {
  return (
    <div className="footer">
      <Container>
        <div className="footer__top">
          <div className="footer__logo">
            <Logo/>
            <div className="footer__logo-text">
              <strong>Заявки.рф</strong> – надежный сервис по лидогенерации
            </div>
          </div>
          <div className="footer__menu">
            <FooterMenu/>
          </div>
          {/*<div className="footer__socials">*/}
          {/*  <Socials/>*/}
          {/*</div>*/}
        </div>
        <div className="footer__bottom">
          <Copyright className="footer__copyright" />
          <Policies className="footer__policies" />
        </div>
      </Container>
    </div>
  );
};

export default Footer;
