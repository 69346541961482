import React from 'react';
import './Drawer.scss';
import {connect} from 'react-redux';
import {closeDrawer} from './../../actions/drawerActions';
import {CloseIcon} from './../../icons';
import {ReactComponent as Logo} from './../../images/logo.svg';
import Menu from './../Menu';

const Drawer = (props) => {
  document.body.style.overflow = props.isOpen ? 'hidden' : null;

  return (
    <React.Fragment>
      <div
        className={`drawer__overlay ${props.isOpen ? 'drawer__overlay--open' : ''}`}
        onClick={props.closeDrawer}
      />

      <div className={`drawer ${props.isOpen ? 'drawer--open' : ''}`}>
        <CloseIcon
          className="drawer__close"
          onClick={props.closeDrawer}
        />

        <div className="drawer__header">
          <Logo/>
        </div>
        <Menu
          className="drawer__menu"
          onClick={props.closeDrawer}
        />
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  isOpen: state.drawer.isOpen
});

export default connect(mapStateToProps, {closeDrawer})(Drawer);
