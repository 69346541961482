import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {login} from '../../actions/authActions';
import FormControl from '../FormControl';
import Input from '../Input';
import Button from '../Button';
import {MailIcon, PasswordIcon} from '../../icons';
import ForgotPasswordButton from '../ForgotPasswordButton';
import Errors from '../Errors';

const initialState = {
  data: {
    email: '',
    password: '',
  },
  errors: new Errors({}),
};

class LoginForm extends PureComponent {
  constructor(props) {
    super(props);

    this.state = initialState;
  }

  handleChange = (e) => {
    const {name, value, type, checked} = e.target;

    this.setState(prevState => ({
      data: {
        ...prevState.data,
        [name]: type === 'checkbox' ? checked : value,
      }
    }));
  };

  handleSubmit = () => {
    const {data} = this.state;

    this.props.login(data)
      .then(() => {
        this.setState(initialState);
        this.props.closeModal();
        this.props.openSuccessModal();
      })
      .catch(errors => {
        this.setState({
          errors: new Errors(errors)
        })
      });
  };

  render = () => {
    const {data: {email, password}, errors} = this.state;

    return (
      <React.Fragment>
        <FormControl>
          <Input
            error={errors.get('email')}
            name="email"
            value={email}
            onChange={this.handleChange}
            placeholder={`Ваш email`}
            icon={(props) => <MailIcon {...props}/>}
          />
        </FormControl>
        <FormControl>
          <Input
            error={errors.get('password')}
            type="password"
            name="password"
            value={password}
            onChange={this.handleChange}
            placeholder={`Пароль`}
            icon={(props) => <PasswordIcon {...props}/>}
          />
        </FormControl>
        <FormControl>
          <ForgotPasswordButton/>
        </FormControl>
        <FormControl>
          <Button
            fullWidth
            onClick={this.handleSubmit}
          >
            Войти
          </Button>
        </FormControl>
      </React.Fragment>
    );
  };
}

export default connect(null, {login})(LoginForm);
