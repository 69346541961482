import React from 'react';
import './Header.scss';
import {Link} from 'react-router-dom';
import AuthButton from '../AuthButton';
import Container from './../Container';
import Menu from './../Menu';
import {ReactComponent as Logo} from './../../images/logo.svg';
import DrawerButton from './../DrawerButton';

const Header = () => {
  return (
    <div className="header">
      <Container className="header__container">
        <DrawerButton className="header__drawer-button" />

        <Link to={`/`}>
          <Logo className="header__logo"/>
        </Link>
        {/*<CountrySelect*/}
        {/*  className="header__country-select"*/}
        {/*/>*/}
        <Menu
          className="header__menu"
        />
        <AuthButton
          className="header__auth-button"
        />
      </Container>
    </div>
  );
};

export default Header;
