import React from 'react';
import './SectionTitle.scss';

const SectionTitle = ({className = '', size = '', center = false, ...props}) => {
  return (
    <div
      {...props}
      className={`
        section-title
        ${size ? 'section-title--' + size : ''}
        ${center ? 'section-title--center' : ''}
        ${className}
      `}
    />
  );
};

export default SectionTitle;
