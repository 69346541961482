import React from 'react';
import './InputLabel.scss';

const InputLabel = (props) => {
  return (
    <label
      {...props}
      className="input-label"
    />
  );
};

export default InputLabel;
