import React from 'react';
import './ForgotPasswordButton.scss';
import {connect} from 'react-redux';
import {openForgotPasswordModal} from './../../actions/modalActions';

const ForgotPasswordButton = (props) => {
  return (
    <div
      className="forgot-password-button"
      onClick={props.openForgotPasswordModal}
    >
      Забыли пароль?
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  openForgotPasswordModal: () => dispatch(openForgotPasswordModal()),
});

export default connect(null, mapDispatchToProps)(ForgotPasswordButton);
