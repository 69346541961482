import React from 'react';
import Background from './../components/Background';
import Steps from './../components/Steps';
import LeadFeatures from './../components/LeadFeatures';
import CategoryRequest from './../components/CategoryRequest';

const HowItWorksPage = () => {
  return (
    <React.Fragment>
      <Background color="grey">
        <Steps horizontal />
        <LeadFeatures/>
      </Background>

      <Background color="white">
        <CategoryRequest/>
      </Background>
    </React.Fragment>
  );
};

export default HowItWorksPage;
