import React from 'react';
import About from "../../components/About";
import Button from "../../components/Button";
import Features from "../../components/Features";
import ServiceHero from "../../components/ServiceHero";
import {ReactComponent as HeroImage} from './../../images/hero-smm.svg';
import Background from "../../components/Background";
import Readiness from "../../components/Readiness";
import ServiceRequest from "../../components/ServiceRequest";
import {openServiceRequestModal} from "../../actions/modalActions";
import {connect} from "react-redux";
import SMM from '../../images/smm.svg'
import StartUp from '../../images/start-up.svg'
import Grow from '../../images/grow.svg'
import LinkUsers from '../../images/link-users.svg'
import AdsSets from '../../images/ads-sets.svg'
import Feedback from '../../images/feedback.svg'
import Auditory from '../../images/auditory.svg'
import Planing from '../../images/planing.svg'
import SmmContent from '../../images/smm-content.svg'

const SmmServicePage = (props) => {
  const {openServiceRequestModal} = props;

  return (
    <React.Fragment>
      <Background color="grey">
        <ServiceHero
          image={() => <HeroImage style={{margin: '-30px'}} />}
          title={`Нужно грамотное SMM продвижение на популярных ресурсах?`}
        >
          <p>
            Мы предлагаем комплексные услуги по поисковой оптимизации и комплексном продвижении веб-продуктов вашего бизнеса. Мы предоставляем весь спектр услуг которые помогут вам достигнуть желаемых показателей!
          </p>
          <p>
            <strong>
              Оставьте заявку чтобы начать работу по SMM!
            </strong>
          </p>
          <Button
            onClick={openServiceRequestModal}
          >
            Оставить заявку
          </Button>
        </ServiceHero>

        <About
          title={`Что такое SMM продвижение и зачем оно вам?`}
          icon={() => <img width={160} height={150} src={SMM} alt="" />}
        >
          <p>
            Наряду со ссылочным и поисковым трафиком сегодня огромное значение имеет трафик из соц. сетей. Не использовать его – значит не раскрыть потенциал своего сайта. Поэтому большое значение сегодня уделяется SMM (от англ. Social Media Marketing)
          </p>
          <p>
            SMM продвижение это отдельный и очень важный канал развития достижения эффективности вашего бизнеса на популярнейших площадках, где на сегоднешний день находится практически каждый
          </p>
          <Button
            onClick={openServiceRequestModal}
          >
            Работать с нами!
          </Button>
        </About>

        <Features
          type={`horizontal`}
          title={`Для кого будет полезно SMM продвижение?`}
          items={[{
            icon: () => <img width={120} height={120} src={StartUp} alt="" />,
            title: 'Новым компаниям и проектам желающим занять своё место на рынке',
            description: 'Если вы и ваш проект только вышли на рынок услуг, SMM продвижение будет не сложным и ёмким аспектом увеличения вашей эффективности. SMM быстро и эффективно позволяет заявить о себе среди целевой аудитории и достигнуть желаемых результатов.',
          }, {
            icon: () => <img width={120} height={120} src={Grow} alt="" />,
            title: 'Компаниям и проектам желающим добиться больших результатов на рынке!',
            description: 'SMM это еще один канал и способ поднять вашу конкурентноспособность и заявить о своих услугах и продуктах большому количеству потенциальной аудитории, используя не сложные методы и быть на целенным на быстрый результат.',
          }]}
        />

        <Features
          type={`horizontal`}
          title={`Что дает SMM продвижение?`}
          items={[{
            icon: () => <img width={120} height={120} src={LinkUsers} alt="" />,
            title: 'Возможность заявить о своем бизнесе на максимально возможную аудиторию',
            description: 'SMM это отличный способ заявить о себе на большую аудиторию потенциальных потребителей ваших товаров и услуг и хорошо это можно сделать на популярных ресурсах где находятся почти все и каждый.',
          }, {
            icon: () => <img width={120} height={120} src={AdsSets} alt="" />,
            title: 'Вести рекламные кампании и своевременно оповещать аудиторию об услугах, офферах и других предложениях',
            description: 'Проведение рекламных кампаний отличный способ продвинуть как постоянные ваши услуги сервис, так создавать уникальные предложения и офферы и бить в целевую аудиторию с конкретными предложениями',
          }, {
            icon: () => <img width={120} height={120} src={Feedback} alt="" />,
            title: 'Получать быстрый отклик аудитории на ваши предложения и услуги',
            description: 'Благодаря большому трафику и количеству пользователей можно получить быстрый и большой отклик потенциальных клиентов на ваши услуги, а так-же выстраивать кампании по привлечению разных групп потенциальных потребителей.',
          }, {
            icon: () => <img width={120} height={120} src={Auditory} alt="" />,
            title: 'Собирать и преумножать постоянную аудиторию ваших услуг и сервисов',
            description: 'Страницы, группы, паблики, блоги и ваш контент, это лучший способ как удержания, так и увеличения потребителей вашего сервиса. Работа с контентом и наполнения ваших страниц в соц.сетях поможет привлекать и сохранять ваших клиентов',
          }]}
        />

        <Readiness
          onClick={openServiceRequestModal}
        />

        <Features
          type={`vertical`}
          title={`Что входит в услугу SMM продвижения?`}
          items={[{
            icon: () => <img width={120} height={120} src={Planing} alt="" />,
            title: 'Анализ вашей специфики, планов и целей составление плана продвижения',
            description: '',
          }, {
            icon: () => <img width={120} height={120} src={AdsSets} alt="" />,
            title: 'Настройку и ведение рекламных кампании, разработка маркетинговой стратегии размещения.',
            description: '',
          }, {
            icon: () => <img width={120} height={120} src={SmmContent} alt="" />,
            title: 'Создание контента, постинг, работа с аудиторией и поддержка ваших страниц в социальных сетях',
            description: '',
          }]}
        />
      </Background>

      <Background color="white">
        <ServiceRequest
          title={
            <React.Fragment>
              Оставьте заявку по услугам
              <br/>
              SMM продвижения
            </React.Fragment>
          }
          description={`Заполните форму чтобы начать работать с нами`}
        />
      </Background>
    </React.Fragment>
  );
};

const mapDispatchToProps = dispatch => ({
  openServiceRequestModal: () => dispatch(openServiceRequestModal({
    title: 'Оставьте заявку по услугам SMM продвижения',
    description: 'Заполните форму чтобы начать работать с нами',
  })),
});

export default connect(null, mapDispatchToProps)(SmmServicePage);
