import React from 'react';
import Background from './../components/Background';
import NotFound from './../components/NotFound';

const NotFoundPage = () => {
  return (
    <React.Fragment>
      <Background color="white">
        <NotFound/>
      </Background>
    </React.Fragment>
  );
};

export default NotFoundPage;
