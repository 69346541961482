import React from 'react';
import Features from './../Features';
import Button from './../Button';
import {connect} from 'react-redux';
import {openCategoryRequestModal} from '../../actions/modalActions';
import BenefitsSelection from '../../images/benefits-selection.svg'
import BenefitsSources from '../../images/benefits-sources.svg'
import BenefitsFlow from '../../images/benefits-flow.svg'
import BenefitsFlexible from '../../images/benefits-flexible.svg'

const LeadFeatures = (props) => {
  return (
      <Features
          type={`horizontal`}
          title={`Наши лиды – это:`}
          items={[{
            icon: () => <img width={90} style={{marginTop: -20}} src={BenefitsSelection}
                             alt=""/>,
            title: 'Качественный отбор и уникальность',
            description: 'Каждая заявка обрабатывается и отбирается нашими специалистами до того как она попала к вам, чтобы вы получали максимально качественный поток. Мы так-же не продаем одни и теже заявки разным лицам, поток для вас, это только ваши лиды',
          }, {
            icon: () => <img width={124} style={{margin: '-27px -17px 0 -17px'}}
                             src={BenefitsSources} alt=""/>,
            title: 'Различные источники получения клиентов',
            description: 'Мы формируем поток лидов из разных источников! Благодаря нашим сайтам, коллцентрам, рекламным кампаниям, веб-инструментам и партнерам, мы в состоянии привлекать максимально релевантную аудиторию и передать их заявки вам.',
          }, {
            icon: () => <img width={90} style={{marginTop: -20}} src={BenefitsFlow}
                             alt=""/>,
            title: 'Стабильный поток и объемы',
            description: 'Благодаря нашему охвату и мощностям, мы способны обеспечить стабильный поток лидов в большом объеме и предоставлять его вам, главное чтобы вы справлялись в их обработке ;)',
          }, {
            icon: () => <img width={90} style={{marginTop: -20}} src={BenefitsFlexible}
                             alt=""/>,
            title: 'Гибкость направлений и тематик',
            description: 'Мы собираем заявки по различным запросам в разных тематиках, поэтому можем сформировать гибкий поток по различным аспектам и моделям подходящим под ваш тип работы',
          }]}
      >
        <Button
            style={{
              marginTop: 50,
            }}
            onClick={props.openCategoryRequestModal}
        >
          Работать с нами!
        </Button>
      </Features>
  );
};

export default connect(null, {openCategoryRequestModal})(LeadFeatures);
