import React from 'react';
import './FormControl.scss';

const FormControl = (props) => {
  return (
    <div
      {...props}
      className="form-control"
    />
  );
};

export default FormControl;
