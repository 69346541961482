import React from 'react';
import './FooterMenu.scss';
import {Link} from 'react-router-dom';
import {openAuthModal} from './../../actions/modalActions';
import {connect} from 'react-redux';

const FooterMenu = (props) => {
  return (
    <div className="footer-menu">
      <div className="footer-menu__column">
        <Link
          className="footer-menu__link"
          to={`/категории`}
        >
          Категории лидов
        </Link>
        <Link
          className="footer-menu__link"
          to={`/как-это-работает`}
        >
          Как это работает
        </Link>
        <Link
          className="footer-menu__link"
          to={`/о-компании`}
        >
          О компании
        </Link>
        <Link
            className="footer-menu__link"
            to={`/юристы`}
        >
          Юристам
        </Link>
      </div>
      <div className="footer-menu__column">
        <Link
          className="footer-menu__link"
          to={`/контакты`}
        >
          Контакты
        </Link>
        <Link
          to="#"
          className="footer-menu__link"
          onClick={props.openLoginModal}
        >
          Войти
        </Link>
        <Link
          to="#"
          className="footer-menu__link"
          onClick={props.openRegisterModal}
        >
          Зарегистрироваться
        </Link>
      </div>
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  openLoginModal: () => dispatch(openAuthModal({tab: 'login'})),
  openRegisterModal: () => dispatch(openAuthModal({tab: 'register'})),
});

export default connect(null, mapDispatchToProps)(FooterMenu);
