import React, { Fragment } from 'react';

const BankDetails = () => {
  return (
    <Fragment>
      ООО «РЕНТАБЕЛЬНО»<br/>
      143907, Московская обл, гор.Балашиха, Ленина пр-кт, д.32Б, помещ.22.<br/>
      ОГРН: 1165053055980<br/>
      ИНН 5001112108<br/>
      КПП 500101001
    </Fragment>
  );
};

export default BankDetails;
