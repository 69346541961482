import React from 'react';
import './Content.scss';

const Content = (props) => {
  return (
    <div
      {...props}
      className="content"
    />
  );
};

export default Content;
