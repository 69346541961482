import React from 'react';
import './Checkbox.scss';
import _ from 'lodash';

const Checkbox = ({error, children, ...props}) => {
  const checkboxId = _.uniqueId('checkbox-');

  return (
    <div>
      <div className="checkbox">
        <input
          {...props}
          type="checkbox"
          id={checkboxId}
        />
        <label htmlFor={checkboxId}>
          {children}
        </label>
        {error && (
          <div className="input__error">
            {error}
          </div>
        )}
      </div>
    </div>
  );
};

export default Checkbox;
