import axios from 'axios';

export const storeRequest = ({name, email, phone, location, leadCount, plannedBudget, businessType, description, personalData}) => {
  return new Promise((resolve, reject) => {
    axios.get('/sanctum/csrf-cookie').then(() => {
      axios.post('/service-request', {
        name: name,
        email: email,
        phone: phone,
        location_id: location ? location.id : null,
        lead_count: leadCount ? leadCount.value : null,
        planned_budget: plannedBudget ? plannedBudget.value : null,
        business_type: businessType ? businessType.value : null,
        description: description,
        personal_data: personalData,
      }).then(() => {
        resolve();
      }).catch(error => {
        reject(error.response.data.errors);
      });
    });
  });
};
