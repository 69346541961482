import React from 'react';
import './Features.scss';
import Container from './../Container';
import SectionTitle from './../SectionTitle';
import Section from './../Section';

const FeatureItem = ({type, icon: Icon, title = '', description = ''}) => {
  return (
    <div className={`feature-item feature-item--${type}`}>
      <div className="feature-item__icon">
        <Icon/>
      </div>
      <div className="feature-item__content">
        <div className="feature-item__title">
          {title}
        </div>
        <div className="feature-item__description">
          {description}
        </div>
      </div>
    </div>
  );
};

const Features = ({items = [], title, type, children}) => {
  return (
    <Section>
      <Container>
        <SectionTitle
          // center={type === 'vertical'}
        >
          {title}
        </SectionTitle>
        <div className={`features`}>
          {items.map((item, index) => <FeatureItem key={index} type={type} {...item} />)}
        </div>
        {children}
      </Container>
    </Section>
  );
};

export default Features;
