import React from 'react';
import Section from './../Section';
import SectionTitle from './../SectionTitle';
import SectionDescription from './../SectionDescription';
import CategoryRequestForm from './../CategoryRequestForm';
import Container from './../Container';

const CategoryRequest = () => {
  return (
    <Section>
      <Container>
        <SectionTitle center>
          Нужны лиды для вашего бизнеса?
        </SectionTitle>
        <SectionDescription center>
          Оформите заявку и получайте клиентов уже сейчас
        </SectionDescription>

        <CategoryRequestForm
          style={{
            maxWidth: 380,
            margin: '0 auto',
          }}
        />
      </Container>
    </Section>
  );
};

export default CategoryRequest;
