import React, {PureComponent} from 'react';
import './AuthButton.scss';
import {connect} from 'react-redux';
import {openAuthModal} from './../../actions/modalActions';
import {AccountIcon} from './../../icons';
import LogoutButton from "../LogoutButton/LogoutButton";

class AuthButton extends PureComponent {
  handleClick = () => {
    const {isAuthenticated, openAuthModal} = this.props;

    if (isAuthenticated) {
      window.location.href = `/account`;
    } else {
      openAuthModal();
    }
  };

  render = () => {
    const {className = '', isAuthenticated, user} = this.props;

    return (
      <React.Fragment>
        <button
          className={`auth-button ${className}`}
          onClick={this.handleClick}
        >
          <AccountIcon className="auth-button__icon" />
          <div className="auth-button__text">
            {!isAuthenticated ? 'Войти' : user.name}
          </div>
        </button>

        {isAuthenticated && (
          <LogoutButton/>
        )}
      </React.Fragment>
    );
  };
}

const mapStateToProps = state => ({
  user: state.auth.user,
  isAuthenticated: state.auth.isAuthenticated,
});

const mapDispatchToProps = dispatch => ({
  openAuthModal: () => dispatch(openAuthModal({tab: 'login'})),
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthButton);
