import React from 'react';
import './ServiceHero.scss';
import Container from './../Container';
import Section from '../Section';

const ServiceHero = ({image: HeroImage, title, children: description}) => {
  return (
      <Section className="service-hero">
        <Container className="service-hero__container">
          <div className="service-hero__image">
            <HeroImage/>
          </div>
          <div className="service-hero__content">
            <div className="service-hero__title">
              {title}
            </div>
            <div className="service-hero__description">
              {description}
            </div>
          </div>
        </Container>
      </Section>
  );
};

export default ServiceHero;
