import React, {PureComponent} from 'react';
import Input from '../Input';
import {MailIcon, PasswordIcon, UserIcon} from '../../icons';
import FormControl from '../FormControl';
import PhoneInput from '../PhoneInput';
import InputLabel from '../InputLabel';
import LocationSelect from '../LocationSelect';
import LeadCountSelect from '../LeadCountSelect';
import BudgetSelect from '../BudgetSelect';
import Button from '../Button';
import Errors from '../Errors';
import {connect} from 'react-redux';
import {register} from '../../actions/authActions';
import Checkbox from '../Checkbox';
import CategorySelect from '../CategorySelect';

const initialState = {
  data: {
    name: '',
    email: '',
    phone: '',
    password: '',
    passwordConfirmation: '',
    location: null,
    businessType: '',
    count: {value: '20-30', label: '20 — 30'},
    budget: {value: '10000', label: '10 000 руб'},
    description: '',
    personalData: false,
    locationsOfInterest: []
  },
  errors: new Errors({}),
};

class RegisterForm extends PureComponent {
  constructor(props) {
    super(props);

    this.state = initialState;
  }


  handleChange = (e) => {
    // const {errors} = this.state;
    const {name, value, type, checked} = e.target;

    // if (errors.has(name)) {
    //   errors.clear(name);
    // }

    this.setState(prevState => ({
      data: {
        ...prevState.data,
        [name]: type === 'checkbox' ? checked : value,
      },
      // errors: errors,
    }));
  };

  handleSubmit = () => {
    const {data} = this.state;

    this.props.register(data)
        .then(() => {
          this.setState(initialState);
          this.props.closeModal();
        })
        .catch(errors => {
          this.setState({
            errors: new Errors(errors)
          })
        });
  };

  handleSelectChange = (name, option) => {
    // const {errors} = this.state;

    // // Todo: change it
    // // if (errors.has(name)) {
    // if (name === 'location') {
    //   errors.clear('location_id');
    // }
    //
    // if (name === 'businessType') {
    //   errors.clear('business_type');
    // }
    //
    // if (name === 'count') {
    //   errors.clear('lead_count');
    // }
    //
    // if (name === 'budget') {
    //   errors.clear('planned_budget');
    // }
    // // }
    this.setState(prevState => ({
      data: {
        ...prevState.data,
        [name]: option
      },
      // errors: errors,
    }));
  };


  render = () => {
    const {data: {name, phone, email, password, passwordConfirmation, location, businessType, count, budget, description, personalData, locationsOfInterest}, errors} = this.state;

    return (
        <React.Fragment>
          <FormControl>
            <Input
                error={errors.get('name')}
                name="name"
                value={name}
                onChange={this.handleChange}
                placeholder={`Ваше имя`}
                icon={(props) => <UserIcon {...props}/>}
            />
          </FormControl>
          <FormControl>
            <PhoneInput
                error={errors.get('phone')}
                name="phone"
                value={phone}
                onChange={this.handleChange}
            />
          </FormControl>
          <FormControl>
            <Input
                error={errors.get('email')}
                type="email"
                name="email"
                value={email}
                onChange={this.handleChange}
                placeholder={`Ваш еmail`}
                icon={(props) => <MailIcon {...props}/>}
            />
          </FormControl>
          <FormControl>
            <Input
                error={errors.get('password')}
                type="password"
                name="password"
                value={password}
                onChange={this.handleChange}
                icon={props => <PasswordIcon {...props} />}
                placeholder={`Ваш пароль`}
            />
          </FormControl>
          <FormControl>
            <Input
                type="password"
                name="passwordConfirmation"
                value={passwordConfirmation}
                onChange={this.handleChange}
                icon={props => <PasswordIcon {...props} />}
                placeholder={`Повторите пароль`}
            />
          </FormControl>
          <FormControl>
            <InputLabel>
              Регион
            </InputLabel>
            <LocationSelect
                error={errors.get('location_id')}
                value={location}
                onChange={option => this.handleSelectChange('location', option)}
            />
          </FormControl>

          <FormControl>
            <InputLabel>
              Интересующие регионы
            </InputLabel>
            <LocationSelect
                error={errors.get('locations_of_interest')}
                value={locationsOfInterest}
                isMulti
                onChange={option => this.handleSelectChange('locationsOfInterest', option)}
            />
          </FormControl>


          <FormControl>
            <InputLabel>
              Тип бизнеса
            </InputLabel>
            <CategorySelect
                error={errors.get('business_type')}
                value={businessType}
                onChange={option => this.handleSelectChange('businessType', option)}
            />
          </FormControl>
          <FormControl>
            <InputLabel>
              Количество лидов в сутки
            </InputLabel>
            <LeadCountSelect
                error={errors.get('lead_count')}
                value={count}
                onChange={option => this.handleSelectChange('count', option)}
            />
          </FormControl>
          <FormControl>
            <InputLabel>
              Планируемый бюжет в месяц
            </InputLabel>
            <BudgetSelect
                error={errors.get('planned_budget')}
                value={budget}
                onChange={option => this.handleSelectChange('budget', option)}
            />
          </FormControl>
          <FormControl>
            <Input
                error={errors.get('description')}
                multiline
                name="description"
                value={description}
                onChange={this.handleChange}
                placeholder={`Расскажите какие заявки вам нужны или другую информацию о вашем бизнесе…`}
            />
          </FormControl>
          <FormControl>
            <Checkbox
                checked={personalData}
                error={errors.get('personal_data')}
                name="personalData"
                onChange={this.handleChange}
            >
              Я даю своё&nbsp;
              <a
                  href={`/согласие-на-обработку-персональных-данных`}
                  target="_blank"
                  rel="noopener noreferrer"
              >
                согласие на обработку персональных данных
              </a>
            </Checkbox>
          </FormControl>
          <FormControl>
            <Button
                fullWidth
                onClick={this.handleSubmit}
            >
              Зарегистрироваться
            </Button>
          </FormControl>
        </React.Fragment>
    );
  };
}

export default connect(null, {register})(RegisterForm);
