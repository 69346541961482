import React from 'react';
import Modal from './../Modal';
import {connect} from 'react-redux';
import {openSuccessModal, closeCategoryRequestModal} from './../../actions/modalActions';
import CategoryRequestForm from './../CategoryRequestForm';

const CategoryRequestModal = (props) => {
  const {isOpen, closeModal} = props;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      title={`Начать работать!`}
      description={`Оставьте заявку на консультацию по лидам и мы найдем вам подходящие лиды под ваше дело!`}
      icon={null}
    >
      <CategoryRequestForm
        flat={true}
        onSubmit={closeModal}
      />
    </Modal>
  );
};

const mapStateToProps = state => ({
  isOpen: state.modal.categoryRequest.isOpen
});

const mapDispatchToProps = dispatch => ({
  closeModal: () => dispatch(closeCategoryRequestModal()),
  openSuccessModal: () => dispatch(openSuccessModal({
    icon: () => null,
    description: '...'
  })),
});

export default connect(mapStateToProps, mapDispatchToProps)(CategoryRequestModal);