import React from 'react';
import {connect} from 'react-redux';
import Modal from './../Modal';
import {InfoIcon} from './../../icons';
import {openSuccessModal, closeInfoModal} from './../../actions/modalActions';
import ContactForm from './../ContactForm';

const InfoModal = (props) => {
  const {isOpen, closeModal, openSuccessModal} = props;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      title={`Написать сообщение`}
      description={`Если вам нужна дополнительная информация по работе нашего сервиса, напишите нам и мы проконсультируем вас`}
      icon={<InfoIcon/>}
    >
      <ContactForm
        icon={<InfoIcon/>}
        onSubmit={() => {
          closeModal();
          openSuccessModal();
        }}
      />
    </Modal>
  );
};

const mapStateToProps = state => ({
  isOpen: state.modal.info.isOpen
});

const mapDispatchToProps = dispatch => ({
  closeModal: () => dispatch(closeInfoModal()),
  openSuccessModal: () => dispatch(openSuccessModal({
    icon: () => <InfoIcon/>,
    description: '...'
  })),
});

export default connect(mapStateToProps, mapDispatchToProps)(InfoModal);
