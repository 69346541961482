import React from 'react';
import Background from './../components/Background';
import PageHero from './../components/PageHero';
import Support from './../components/Support';
import Contact from './../components/Contact';
import ContactsImage from '../images/contacts.svg'

const ContactsPage = () => {
  return (
    <React.Fragment>
      <Background color="grey">
        <PageHero
          title={`Контакты`}
          image={() => <img style={{margin: '30px 0'}} src={ContactsImage} alt="" />}
        >
          <Contact
            label={`Инфо-линия:`}
            value={<a href="tel:+7 (495) 662-75-24">+7 (495) 662-75-24</a>}
          />
          <Contact
            label={`Отдел продаж:`}
            value={<a href="mailto:sales@xn--80aengj8j.xn--p1ai">sales@заявки.рф</a>}
          />
          <Contact
            label={`Техническая поддержка:`}
            value={<a href="mailto:support@xn--80aengj8j.xn--p1ai">support@заявки.рф</a>}
          />
        </PageHero>

        <Support/>
      </Background>
    </React.Fragment>
  );
};

export default ContactsPage;
