import React, {PureComponent} from 'react';
import './CookieAlert.scss';
import Container from './../Container';
import Button from './../Button';
import {CloseIcon} from './../../icons';

class CookieAlert extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: !localStorage.getItem('cookies'),
    };
  }

  handleClose = () => {
    this.setState({
      isOpen: false,
    });

    localStorage.setItem('cookies', true);
  };

  render = () => {
    const {isOpen} = this.state;

    if (!isOpen) {
      return null;
    }

    return (
      <div className="cookie-alert">
        <Container className="cookie-alert__container">
          <div className="cookie-alert__message">
            Для повышения удобства сайта мы используем cookies. Оставаясь на сайте, вы соглашаетесь с&nbsp;
            <a
              href={`/политика-конфиденциальности`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Политикой конфиденциальности
            </a>
          </div>
          <Button
            onClick={this.handleClose}
          >
            Хорошо!
          </Button>
          <div
            className="cookie-alert__close"
            onClick={this.handleClose}
          >
            <CloseIcon/>
          </div>
        </Container>
      </div>
    );
  }
}

export default CookieAlert;
