import React from 'react';
import About from "../../components/About";
import Button from "../../components/Button";
import Features from "../../components/Features";
import ServiceHero from "../../components/ServiceHero";
import {ReactComponent as HeroImage} from './../../images/hero-serm.svg';
import Background from "../../components/Background";
import Readiness from "../../components/Readiness";
import ServiceRequest from "../../components/ServiceRequest";
import {openServiceRequestModal} from "../../actions/modalActions";
import {connect} from "react-redux";
import Serm from '../../images/serm.svg';
import Fight from '../../images/fight.svg';
import StartUp from '../../images/start-up.svg';
import Strategy from '../../images/strategy.svg';
import Selection from '../../images/selection.svg';
import Rating from '../../images/rating.svg';
import FightNegative from '../../images/fight-negative.svg';
import Planing from '../../images/planing.svg';
import RatingUpload from '../../images/rating-upload.svg';

const SermServicePage = (props) => {
  const {openServiceRequestModal} = props;

  return (
    <React.Fragment>
      <Background color="grey">
        <ServiceHero
          image={() => <HeroImage style={{margin: '-30px'}} />}
          title={`Управление репутацией в интернете (SERM)`}
        >
          <p>
            Мы предлагаем услуги по улучшению, а также повышению репутации вашей компании. В нашем арсенале широкий спектр инструментов для работы над корректировкой вектора развития и отзывов о вашем деле.
          </p>
          <p>
            <strong>
              Оставьте заявку чтобы поработать над репутацией!
            </strong>
          </p>
          <Button
            onClick={openServiceRequestModal}
          >
            Оставить заявку
          </Button>
        </ServiceHero>

        <About
          title={`Что такое Управление Репутацией?`}
          icon={() => <img width={160} height={150} src={Serm} alt="" />}
        >
          <p>
            SERM (Search Engine Reputation Management) — это управление репутацией в поисковых системах.
          </p>
          <p>
            Представляет собой комплекс мер, направленных на вытеснение негативной репутации компании в интернете, путем распространения положительного контента и вытеснением необъективно негативного.
          </p>
          <Button
            onClick={openServiceRequestModal}
          >
            Работать с нами!
          </Button>
        </About>

        <Features
          type={`horizontal`}
          title={`Кому будет полезно Управление Репутацией?`}
          items={[{
            icon: () => <img width={120} height={120} src={Fight} alt="" />,
            title: 'Компаниям в жесткой конкурентной среде, где зачастую используются нечестные методы вытеснения оппонентов',
            description: 'Не секрет, что негативные отзывы могут частично или полностью уничтожить репутацию компании, и навсегда лишить лояльности не только клиентов, но и бизнес-партнеров. Поэтому данный аспект стоит как отслеживать, так предпринимать необходимые меры.',
          }, {
            icon: () => <img width={120} height={120} src={StartUp} alt="" />,
            title: 'Молодые компании, в арсенале которых нет достаточного числа положительных отзывов',
            description: 'Нередко новые компании и проекты еще не успели обрести репутацию на рынке, что не позволяет приумножать свою аудиторию. В добавок в конкурентной борьбе конкуренты топят посредством большого числа негатива, избавится от которого невозможно без комплекса мер по восстановлению репутации.',
          }]}
        />

        <Features
          type={`horizontal`}
          title={`Что дает Управление Репутацией?`}
          items={[{
            icon: () => <img width={120} height={120} src={Strategy} alt="" />,
            title: 'Глубокий анализ конкурентов и выявление негатива позволяет сформировать стратегию по реабилитации вашей репутации',
            description: 'Мы проанализируем вашу репутационную ситуацию, оценим ваших конкурентов и составим стратегию по реабилитации исходя из специфики вашего дела и построим план действий в вашей ситуации',
          }, {
            icon: () => <img width={120} height={120} src={Selection} alt="" />,
            title: 'Дает возможность выявить и вытеснить негатив, направленный на понижение статуса компании в интернете',
            description: 'На основе анализа вашей ситуации разработаем план по решению репутационных проблем и подберем эффективные меры по ведению и сопровождению.',
          }, {
            icon: () => <img width={120} height={120} src={Rating} alt="" />,
            title: 'Постоянный прирост положительного контента и работа над положительным образом вашей компании или проекта',
            description: 'Мы готовы организовывать подготовку материалов для повышения вашей положительной репутации. Написание положительного контента, статей, публикаций и отзывов. Поиск, проработка и подготовка эффективных ресурсов для размещения.',
          }, {
            icon: () => <img width={120} height={120} src={FightNegative} alt="" />,
            title: 'Постепенное и эффективное вытеснение негатива, меняющее вектор отношения к вашей компании, сайту или проекту.',
            description: 'Готовы бороться с необъективным негативом в адрес вашей компании всевозможными способами, такими как: внедрение положительного контента, вытеснение негативного, мешающим репутационному развитию вашего дела.',
          }]}
        />

        <Readiness
          onClick={openServiceRequestModal}
        />

        <Features
          type={`vertical`}
          title={`Что входит в услугу SERM?`}
          items={[{
            icon: () => <img width={120} height={120} src={Planing} alt="" />,
            title: 'Первичный аудит. Анализ текущего положения проекта в сети, выявление источников негатива, анализ конкурентов.',
            description: '',
          }, {
            icon: () => <img width={120} height={120} src={Strategy} alt="" />,
            title: 'Разработка стратегии по вытеснению негатива. На основании данных о конкурентах, количеству негативных отзывов, и задействованных инструментов.',
            description: '',
          }, {
            icon: () => <img width={120} height={120} src={RatingUpload} alt="" />,
            title: 'Подготовка материалов. Создание положительного контента. Поиск, проработка и подготовка ресурсов и последущее размещение.',
            description: '',
          }]}
        />
      </Background>

      <Background color="white">
        <ServiceRequest
          title={
            <React.Fragment>
              Оставьте заявку по услугам
              <br/>
              Управления Репутацией
            </React.Fragment>
          }
          description={`Заполните форму чтобы начать работать с нами`}
        />
      </Background>
    </React.Fragment>
  );
};

const mapDispatchToProps = dispatch => ({
  openServiceRequestModal: () => dispatch(openServiceRequestModal({
    title: 'Оставьте заявку по услугам',
    description: 'Управления Репутацией',
  })),
});

export default connect(null, mapDispatchToProps)(SermServicePage);
