import React from 'react';
import './Consultation.scss';
import Section from './../Section';
import Container from './../Container';
import ConsultationForm from './../ConsultationForm';
import {ReactComponent as ConsultationIcon} from './../../images/consultation.svg'

const Consultation = ({flat = false}) => {
  return (
    <Section>
      <Container>
        <div className={`consultation ${flat ? 'consultation--flat' : ''}`}>
          <div className="consultation__inner">
            <ConsultationIcon
              className="consultation__icon"
            />
            <div className="consultation__title">
              Не нашли подходящую тематику?
            </div>
            <div className="consultation__description">
              Оставьте заявку на консультацию по лидам и мы найдем вам подходящие лиды под ваше дело!
            </div>
            <div className="consultation__description consultation__description--second">
              Или наберите менеджеру на номер <a href="tel:+74956627524">+7 (495) 662-75-24</a>!
            </div>
          </div>
          <div className="consultation__form">
            <div style={{ width: 330 }}>
              <ConsultationForm/>
            </div>
          </div>
        </div>
      </Container>
    </Section>
  );
};

export default Consultation;
