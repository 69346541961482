import React from 'react';
import Container from '../components/Container';
import SectionTitle from '../components/SectionTitle';
import { Title, Paragraph, List } from '../components/Typography';
import Section from '../components/Section';
import Background from '../components/Background';
import BankDetails from '../components/BankDetails';

const TermsPage = () => {
  return (
    <React.Fragment>
      <Container>
        <Background color="white">
          <Section>
            <SectionTitle>
              Пользовательское соглашение
            </SectionTitle>

            <Paragraph>Администрация сайта «Заявки.рф» именуемое в дальнейшем «Исполнитель», с одной
              стороны, и лицо, правомочное заключить
              юридически обязательное соглашение и акцептовавшее оферту, в дальнейшем именуемое «Заказчик», совместно
              именуемые Стороны, заключили настоящее Пользовательское соглашение (далее – Соглашение) о
              нижеследующем:</Paragraph>

            <Title>
              1. Термины и определения
            </Title>

            <Paragraph>1.1 В настоящем Соглашении, если из текста прямо не вытекает иное, следующие термины будут иметь
              указанные ниже значения:</Paragraph>
            <Paragraph>1.1.1 «Сайт» - совокупность информации, текстов, графических элементов, дизайна и иных
              результатов интеллектуальной деятельности, а также программ для ЭВМ, содержащихся в информационной
              системе, обеспечивающей доступность такой информации в сети Интернет по сетевому адресу Заявки.рф,
              предназначенная для оказания услуг без личного присутствия Заказчика в офисе Исполнителя. Исполнитель
              является администратором (владельцем) сайта;</Paragraph>
            <Paragraph>1.1.2 «Клиент» – лицо, намеревающееся воспользоваться услугами, оказываемыми Заказчиком, и
              обратившееся с этой целью к Исполнителю либо Заказчику при посредничестве Исполнителя;</Paragraph>
            <Paragraph>1.1.3 «Лид» - информация, содержащая вопрос и контактные данные Клиента, поступающая на Сайт
              Исполнителя для последующей её обработки Заказчиком или Исполнителем по поручению Заказчика;</Paragraph>
            <Paragraph>1.1.4 «Обработка звонка» – уточнение сотрудником Исполнителя данных лида, предоставление сведений
              о характере и видах оказываемых Заказчиком услуг, месте приема Клиентов Заказчиком и иной справочной
              информации, необходимой Клиенту;</Paragraph>
            <Paragraph>1.1.5 «Время обработки лида» – время, необходимое Заказчику на принятие лида в работу, его
              оценки, принятия решения по покупке или обоснованного отказа в покупке лида и направления ответа с
              решением с помощью технических средств связи (e-mail, ответ в виде направления технического сигнала на
              Сайт);</Paragraph>
            <Paragraph>1.1.6 «Кампания» - конкретизированный запрос от Заказчика, и согласованный с Исполнителем, на
              оказание услуг: количество желаемых лидов, их характеристика и условия работы;</Paragraph>
            <Paragraph>1.1.7 «Личный кабинет Заказчика» – персональная веб-страница Заказчика на Сайте Исполнителя,
              защищенная ограничением доступа, посредством введения уникальных идентификационных данных (почтовый ящик,
              пароль), предназначенная для согласования и работы с Кампаниями и осуществления электронного
              документооборота.</Paragraph>

            <Paragraph>1.2. Все остальные термины и определения, встречающиеся в тексте Соглашения, толкуются Сторонами
              в соответствии с действующим законодательством РФ и сложившейся практикой использования данных терминов,
              определений и словосочетаний при работе в области Интернет-технологий.</Paragraph>

            <Title>2. Предмет Соглашения</Title>

            <Paragraph>2.1. Исполнитель обязуется по согласованию с Заказчиком оказывать услуги по предоставлению лидов
              (реклама в сети интернет), а Заказчик обязуется указанные услуги принимать и оплачивать.</Paragraph>
            <Paragraph>2.2. Перечень услуг, которые могут оказываться (выполняться) Исполнителем в рамках Соглашения, и
              их существенные характеристики публикуются на Сайте или согласуются в настройках Кампании.</Paragraph>
            <Paragraph>2.3. Все существующие на данный момент услуги, а также любое развитие их и (или) добавление новых
              является предметом настоящего Соглашения.</Paragraph>
            <Paragraph>2.4. Использование услуг, предоставляемых Сайтом Заявки.рф регулируется российским
              законодательством, настоящим Соглашением, Политикой конфиденциальности, а также условиями использования
              отдельных услуг.</Paragraph>

            <Title>3. Порядок заключения Соглашения. Регистрация и Личный кабинет Заказчика.</Title>

            <Paragraph>3.1. Соглашение считается заключённым в Сергиевом Посаде с момента получения Исполнителем
              информации об акцепте публичной оферты Заказчиком.</Paragraph>
            <Paragraph>3.2. Желание заключить Соглашение является добровольным и свободным, Заказчик ознакомлен,
              осознаёт и принимает изложенные обязательства.</Paragraph>
            <Paragraph>3.3. Каждая Сторона заверяет другую Сторону, что имеет все надлежаще оформленные права,
              полномочия и одобрения, необходимые для заключения Соглашения и исполнения обязательств, предусмотренных
              настоящим Соглашением.</Paragraph>
            <Paragraph>
              3.4. Акцептом (полным и безоговорочным принятием) условий публичной оферты считается осуществление
              Заказчиком любого из следующих действий:
            </Paragraph>

            <List
              items={ [
                'регистрация в Сайте и подтверждение факта ознакомления и согласия с условиями публичной оферты путём заполнения данных регистрационной формы;',
                'подтверждение факта ознакомления и согласия с условиями публичной оферты посредством имеющегося функционала в Личном кабинете;',
                'внесение оплаты Заказчиком;',
                'начало использования какого-либо сервиса или его отдельных функций Заказчиком.',
              ] }
            />

            <Paragraph>
              В случае несогласия Заказчика с какими-либо из положений Соглашения, Заказчик не вправе использовать Сайт.
              В случае если Исполнителем были внесены какие-либо изменения в Соглашение, с которыми Заказчик не
              согласен, он обязан прекратить использование Сайта. При этом к отношениям, возникшим в период
              использования Заказчиком Сайта, применяются положения действующего на тот момент Соглашения, если иное не
              установлено дополнительно.
            </Paragraph>
            <Paragraph>3.5. Для того чтобы воспользоваться Сайтом или некоторыми отдельными его функциями, Заказчику
              необходимо пройти процедуру регистрации и аутентификации, в результате которой будет создана и настроена
              учетная запись – Личный кабинет.</Paragraph>
            <Paragraph>3.6. Для регистрации Заказчик предоставляет точную и достоверную информацию о себе по вопросам,
              предлагаемым в форме регистрации, и обязуется поддерживать эту информацию в актуальном
              состоянии.</Paragraph>
            <Paragraph>3.7. Заказчик предоставляет Исполнителю право на обработку информации о себе в соответствии с
              условиями настоящего Соглашения, как самостоятельно, так и с привлечением третьих лиц. Персональная
              информация Заказчика, содержащаяся в Личном кабинете, хранится и обрабатывается Исполнителем в
              соответствии с условиями Политики конфиденциальности.</Paragraph>
            <Paragraph>3.8. При регистрации Заказчик самостоятельно выбирает себе логин (адрес электронной почты
              Заказчика) и пароль для доступа в Личный кабинет. Исполнитель вправе устанавливать требования к паролю
              (длина, допустимые символы и т.д.).</Paragraph>
            <Paragraph>
              3.9. Заказчик самостоятельно несет ответственность за безопасность (устойчивость к угадыванию) выбранных
              им уникальных идентификационных данных, а также самостоятельно обеспечивает их конфиденциальность.
              Заказчик самостоятельно несет ответственность за все действия (а также их последствия) в рамках или с
              использованием Сайта под своей учетной записью, включая случаи добровольной передачи Заказчиком данных для
              доступа в свой Личный кабинет третьим лицам на любых условиях (в том числе по договорам или соглашениям).
              <br/>
              При этом все действия на Сайте через Личный кабинет Заказчика считаются выполненными от его имени и имеют
              юридическое значение, за исключением случаев, когда Заказчик, в порядке, предусмотренном п. 3.10.,
              уведомил Исполнителя о несанкционированном доступе к Сайту Исполнителя с использованием учетной записи
              Заказчика и/или о любом нарушении (подозрениях о нарушении) конфиденциальности своих средств доступа к
              Личному кабинету (пароля).
            </Paragraph>
            <Paragraph>3.10. Заказчик обязан в течении 1 (одного) рабочего дня уведомить Исполнителя о любом случае
              несанкционированного (не разрешенного Заказчиком) доступа к Сайту Исполнителя с использованием учетной
              записи Заказчика и/или о любом нарушении (подозрениях о нарушении) конфиденциальности своих средств
              доступа к Личному кабинету. В целях безопасности, Заказчик обязан самостоятельно осуществлять безопасное
              завершение работы под своей учетной записью (кнопка «Выход») по окончании каждой сессии работы с Сайтом.
              Исполнитель не отвечает за возможную потерю или порчу данных, а также другие последствия любого характера,
              которые могут произойти из-за нарушения Заказчиком положений этой части Соглашения. В случае обнаружения
              действий указанных в настоящем подпункте Исполнитель принимает меры по их устранению не более чем за 3
              (три) рабочих дня после получения уведомления от Заказчика.</Paragraph>

            <Title>4. Права и обязанности сторон</Title>

            <Paragraph>4.1. Заказчик обязуется:</Paragraph>
            <Paragraph>4.1.1. принимать и оплачивать услуги Исполнителя в соответствии с условиями настоящего
              Соглашения;</Paragraph>
            <Paragraph>4.1.2. передавать Исполнителю информацию и документацию, необходимую для оказания Исполнителем
              услуг по настоящему Соглашению с соблюдением правил и требований настоящего Соглашения;</Paragraph>
            <Paragraph>4.1.3. предоставлять Исполнителю достоверные и полные сведения о Заказчике через Личный кабинет
              или на адрес электронной почты Исполнителя в течение 5 (пяти) рабочих дней с даты получения от Исполнителя
              соответствующего требования;</Paragraph>
            <Paragraph>4.1.4. в течении 1 (одного) рабочего дня информировать Исполнителя обо всех обстоятельствах,
              которые могут повлиять на исполнение Сторонами настоящего Соглашения;</Paragraph>
            <Paragraph>4.1.5. осуществлять обработку полученных данных Клиентов, в том числе, содержащихся в оставленных
              лидах, в соответствии с требованиями действующего законодательства Российской Федерации о защите
              персональных данных;</Paragraph>
            <Paragraph>4.1.6. не использовать данные Клиента в иных целях, кроме как для оказания услуг по
              лидам;</Paragraph>
            <Paragraph>4.1.7. не вступать в правоотношения с Клиентами, пришедшими от Исполнителя, за которых не была
              произведена оплата в соответствии с условиями сотрудничества;</Paragraph>
            <Paragraph>4.1.8. при заключении Соглашения предоставить Исполнителю надлежащим образом удостоверенные копии
              соответствующих документов, если деятельность Заказчика подлежит лицензированию или сертификации, требует
              иных разрешений, согласований, членства в СРО и т.п. – в виде сканированных копий, по требованию
              Исполнителя – на бумажных носителях.</Paragraph>

            <Paragraph>4.2. Заказчик имеет право:</Paragraph>
            <Paragraph>4.2.1.использовать полученные от использования Сайта результаты в целях, не противоречащих
              действующему законодательству и условиям настоящего Соглашения;</Paragraph>
            <Paragraph>4.2.2. имеет право проверять ход и качество оказываемых услуг, не вмешиваясь при этом в
              оперативно-хозяйственную деятельность Исполнителя при оказании данных услуг;</Paragraph>
            <Paragraph>4.2.3. в любое время заявить о приостановлении или отказе от оказания ему услуг по настоящему
              Соглашению. Исполнитель в течении 3 (трёх) рабочих дней приостанавливает оказание услуг Заказчику.
              Окончательный расчёт производится в течении 3 (трёх) рабочих дней и подлежит оплате в полном размере в
              течении 3 (трёх) рабочих дней.</Paragraph>

            <Paragraph>4.3. Заказчик не имеет права:</Paragraph>
            <Paragraph>4.3.1. загружать, посылать, передавать или любым другим способом размещать и/или распространять
              контент, который является незаконным, вредоносным, клеветническим, оскорбляет нравственность,
              демонстрирует (или является пропагандой) насилия и жестокости, нарушает права интеллектуальной
              собственности, пропагандирует ненависть и/или дискриминацию людей по различным признакам, содержит
              оскорбления в адрес каких-либо лиц или организаций, содержит элементы (или является пропагандой)
              применения или иного использования наркотических веществ или их аналогов, взрывчатых веществ или иного
              оружия; иных элементов, противоречащих законам РФ;</Paragraph>
            <Paragraph>4.3.2. загружать, посылать, передавать или любым другим способом размещать и/или распространять
              контент, при отсутствии прав на такие действия согласно законодательству или каким-либо договорным
              отношениям;</Paragraph>
            <Paragraph>4.3.3. загружать, посылать, передавать или любым другим способом размещать и/или распространять
              какие-либо материалы, содержащие вирусы или другие компьютерные коды, файлы или программы, предназначенные
              для нарушения, уничтожения либо ограничения функциональности любого компьютерного или
              телекоммуникационного оборудования или программ, для осуществления несанкционированного
              доступа;</Paragraph>
            <Paragraph>4.3.4. несанкционированно собирать и хранить персональные данные других лиц;</Paragraph>
            <Paragraph>4.3.5. выдавать себя за другого человека или представителя организации и/или сообщества без
              достаточных на то прав, в том числе за сотрудников Исполнителя, за модераторов, за владельца Сайта, а
              также применять любые другие формы и способы незаконного представительства других лиц в сети, а также
              вводить других Заказчиков или Исполнителя в заблуждение относительно свойств и характеристик каких-либо
              субъектов или объектов;</Paragraph>
            <Paragraph>4.3.6. использовать Сайт ненадлежащим образом, т.е. пытаться вмешаться в его работу или получить
              к нему доступ в обход стандартного интерфейса и имеющихся инструкций, а также предпринимать действий,
              направленных на подрыв сетевой безопасности либо нарушения работы программно-технических средств
              Сайта;</Paragraph>
            <Paragraph>4.3.7. воспроизводить, повторять и копировать, продавать и перепродавать, а также использовать
              для каких-либо коммерческих целей какие-либо части Сайта (включая контент, доступный Заказчику посредством
              Сайта), или доступ к ним, кроме тех случаев, когда Заказчик получил такое разрешение от
              Исполнителя;</Paragraph>
            <Paragraph>4.3.8. содействовать действиям, направленным на нарушение ограничений и запретов, налагаемых
              Соглашением, или провоцировать такие действия;</Paragraph>
            <Paragraph>4.3.9. другим образом нарушать нормы законодательства, в том числе нормы международного
              права.</Paragraph>

            <Paragraph>4.4. Исполнитель обязуется:</Paragraph>
            <Paragraph>4.4.1. с помощью функционала Сайта передавать Заказчику лиды;</Paragraph>
            <Paragraph>4.4.2. провести настройку Личного кабинета, согласовав с Заказчиком график работы, количество
              желаемых лидов, их характеристики и условия работы;</Paragraph>
            <Paragraph>4.4.3. осуществлять сбор, хранение, передачу, обезличивание и уничтожение данных Клиентов в
              соответствии с требованиями действующего законодательства Российской Федерации о защите персональных
              данных;</Paragraph>
            <Paragraph>4.4.4. не использовать данные Клиентов, в том числе содержащиеся в их лидах, в иных целях, кроме
              как для оказания услуг, предусмотренных Соглашением;</Paragraph>
            <Paragraph>4.4.5. обеспечить программными средствами постоянный учёт исполнения Кампании на
              Сайте;</Paragraph>
            <Paragraph>4.4.6. обеспечить корректную работу Сайта;</Paragraph>
            <Paragraph>4.4.7. рассматривать претензии по качеству лидов и отменять неподтвержденные, в случае если это
              предусмотрено условиями сотрудничества.</Paragraph>

            <Paragraph>4.5. Исполнитель имеет право:</Paragraph>
            <Paragraph>4.5.1. получать от Заказчика дополнительную информацию, необходимую для оказания услуг по
              настоящему Соглашению;</Paragraph>
            <Paragraph>4.5.2. изменять цену услуг;</Paragraph>
            <Paragraph>4.5.3. приостановить или отказаться от оказания услуги без предварительного уведомления и без
              возврата оплаченной суммы, если её использование Заказчиком сопряжено с нарушением установленных
              требований к пользованию услугой, создает угрозу причинения вреда третьим лицам, нарушает требования
              действующего законодательства или явно не соответствует общепринятым моральным и этическим нормам или
              имеются подозрения о таких нарушениях;</Paragraph>
            <Paragraph>4.5.4. временно приостановить оказание Заказчику услуг по настоящему Соглашению по техническим,
              технологическим или иным причинам, препятствующим оказанию услуг, на время устранения таких
              причин;</Paragraph>
            <Paragraph>4.5.5. привлекать третьих лиц для выполнения своих обязательств по настоящему
              Соглашению;</Paragraph>
            <Paragraph>4.5.6. хранить, передавать партнерам и использовать в целях улучшения качества предоставляемой
              услуги информацию обо всех подключениях Заказчика, включая IP-адреса, cookies и адреса запрошенных
              страниц, если такая информация была получена в ходе оказания Услуг;</Paragraph>
            <Paragraph>4.5.7. устанавливать ограничения в использовании Сайта для всех Заказчиков, в том числе:
              наличие/отсутствие отдельных функций Сайта, срок хранения заявок на Сайте, минимальное количество
              заказываемых лидов и стандартные характеристики Кампании;</Paragraph>
            <Paragraph>4.5.8. посылать Заказчикам информационные сообщения и иные материалы, в том числе рекламного
              характера, связываться посредством голосовых, текстовых, видео- и смешанных сообщений посредством
              СМС-сообщений, электронной почты, пуш-уведомлений и иных средств передачи информации, которые
              предусмотрены обычной деловой (производственной) деятельностью Исполнителя;</Paragraph>
            <Paragraph>4.5.9. изменять Сайт, в том числе изменять или добавлять разделы, менять дизайн, функционал и
              пр.</Paragraph>

            <Title>5. Порядок оплаты услуг Исполнителя.</Title>

            <Paragraph>5.1. Заказчик обязуется оплачивать услуги, оказанные Исполнителем. Порядок определения
              вознаграждения определяется при согласовании Кампании. Услуги по Соглашению НДС не облагаются в связи с
              применением Исполнителем упрощенной системы налогообложения в соответствии с гл. 26.2 НК РФ.</Paragraph>
            <Paragraph>5.2. Заказчик подтверждает согласие с ценой указанной в Личном кабинете, в том числе в случае её
              изменения Исполнителем. Изменения вступают в действие с момента их публикации, если не указано
              иное.</Paragraph>
            <Paragraph>5.3. Оплата услуг производится в порядке предоплаты. Заказчик пополняет баланс на Сайте путём
              оплаты с помощью технических возможностей Сайта.</Paragraph>
            <Paragraph>5.4. Плата за фактически потребленные услуги списывается по мере их использования.</Paragraph>
            <Paragraph>5.5. Исполнитель предоставляет Заказчику возможность просмотра состояния баланса Заказчика и
              статистику потребления им услуг.</Paragraph>
            <Paragraph>5.6. Стороны пришли к соглашению, что для учета оказания услуг по Соглашению, в том числе для
              обработки статистики используется автоматизированная система Исполнителя, данные которой будут
              использоваться для определения объемов оказанных услуг и для расчета их стоимости. Заказчик согласен с
              тем, что любая иная система подсчета объема оказанных услуг, в том числе предоставления лидов, не может
              являться официальным источником информации для расчета.</Paragraph>
            <Paragraph>5.7. Услуги оказываются на полную сумму перечисленных средств. Возврат неизрасходованных средств
              не производится. Средства подлежать возврату в случае, если услуги не могут быть оказаны в следствии
              невозможности со стороны Исполнителя выполнять свои обязательства.</Paragraph>
            <Paragraph>5.8. Исполнитель возвращает Заказчику неиспользованные денежные средства в порядке,
              предусмотренном Соглашением, в течение 30 (тридцати) календарных дней с даты получения письменного
              заявления о возврате денежной суммы. Заявление должно содержать полное наименование Заказчика и все
              платежные реквизиты, по которым будет перечислена сумма. Денежная сумма возвращается только тому лицу, от
              которого поступила денежная сумма.</Paragraph>
            <Paragraph>5.9. Обязанность Заказчика по оплате по настоящему Соглашению считается исполненной в момент
              зачисления денежных средств в полном объеме на баланс на Сайте.</Paragraph>
            <Paragraph>5.10. Все расчеты по настоящему Соглашению осуществляются в российских рублях. В случае, если
              размер вознаграждения определен в иностранной валюте, применяется курс Центрального банка России,
              установленный на дату платежа.</Paragraph>

            <Title>6. Условия использования Сайта Заявки.рф</Title>

            <Paragraph>6.1. Цена лида устанавливается Исполнителем и зависит от желаемого количества лидов, региона
              работы Заказчика, времени поступления лида, наличия свободных лидов, тематики лидов, и рыночных условий на
              момент обращения Заказчика. Данные характеристики лидов и условия работы согласуются Сторонами на Сайте
              или по электронной почте и имеют юридическую силу. На основании согласованных условий в течении
              согласованного периода времени Исполнителем производится настройка Личного кабинета. Текущие настройки
              сохраняются до согласования изменений к Кампании. Возможно одновременная работа нескольких Кампаний.
              Настройка Личного кабинета производится только при условии положительного баланса.</Paragraph>
            <Paragraph>
              6.2. Исполнитель вправе отказаться от выполнения Кампании:
            </Paragraph>

            <List
              items={ [
                'если запрошенная услуга не может быть предоставлена из-за отсутствия технической возможности;',
                'если Заказчик ранее пользовался услугами с нарушением установленных требований, создавал угрозу причинения вреда третьим лицам, нарушал требования действующего законодательства;',
                'если Заказчик имеет просроченную задолженность по оплате услуг Исполнителя.',
              ] }
            />

            <Paragraph>6.3. Факт получения лида через Сайт не гарантирует получение Клиента. Текстовые лиды поступают
              Заказчику в учетную запись (раздел «Заявки»), а также приходят на электронную почту, которую Заказчик
              указал при регистрации. Аудио лиды (Звонки) поступают Заказчику на многоканальный телефон и отражаются в
              Личном кабинете. Обработка звонков Исполнителем по просьбе Заказчика отслеживается через специальную
              систему (CRM-систему).</Paragraph>
            <Paragraph>6.4. Исполнитель может, но не обязуется предоставить услугу краткой консультации
              рекомендательного характера по задаваемым вопросам. Предоставление такой консультации осуществляется на
              бесплатной основе.</Paragraph>
            <Paragraph>6.5. Исполнитель вправе предоставлять отдельные услуги без взимания дополнительной платы
              (тестовые и свободно предоставляемые услуги). Такие услуги предоставляются «как есть», без каких либо
              гарантий со стороны Исполнителя. Исполнитель вправе отказывать в предоставлении этих услуг Заказчику без
              объяснения причин.</Paragraph>
            <Paragraph>6.6. Размещаемые на Сайте или иным образом предоставленные Исполнителем материалы носят
              информационный, рекомендательный характер. Исполнитель не гарантирует их актуальности и полноты, а также
              не принимает никаких претензий от Заказчика по поводу их содержания.</Paragraph>
            <Paragraph>6.7. При проведении запланированных профилактических и технических работ, которые могут привести
              к некорректной работе и длительным перерывам в функционировании Сайта, Исполнитель предварительно извещает
              Заказчика не менее чем за 24 (двадцать четыре) часа. Срок извещения может сокращаться в исключительных
              случаях, когда это обусловлено требованиями информационной безопасности и надежности функционирования
              Сайта.</Paragraph>
            <Paragraph>6.8. Все сроки для целей Соглашения определяются по московскому времени. Рабочие дни определяются
              в соответствии с официальным производственным календарем РФ и пятидневной рабочей неделей с выходными
              днями в субботу и воскресенье.</Paragraph>
            <Paragraph>6.9. Для определения времени событий используются показания времени, хранимые Сайтом.</Paragraph>
            <Paragraph>6.10. Любые уведомления по Соглашению могут направляться одной Стороной другой Стороне через Сайт
              или электронную почту. Исполнитель рассматривает любые вопросы Заказчика в течение 5 (пяти) рабочих дней,
              если иного не указано в Соглашении.</Paragraph>
            <Paragraph>6.11. Исполнитель размещает сообщения (уведомления, извещения) на Сайте, а также направляет их по
              адресу электронной почты Заказчика, указанному им на Сайте. Заказчик обязан регулярно (не менее одного
              раза в неделю) проверять наличие адресованных ему сообщений (уведомлений, извещений) на Сайте, а также
              обеспечить актуальность информации о контактных данных на Сайте и доступность почтового ящика.</Paragraph>
            <Paragraph>6.12. Любая Сторона в целях фиксации обстоятельств и сути взаимодействия сторон вправе вести
              запись телефонных переговоров с другой Стороной (без ограничения времени и способа фиксации телефонных
              переговоров), при этом запись телефонных переговоров может быть осуществлена как при входящем, так и при
              исходящем звонке при предварительном предупреждении противоположной Стороны. В случае, если для
              аналогичных целей требуется запись разговора с Клиентом, то сторона осуществляющая звонок обязана
              предупредить Клиента о производящейся записи разговора.</Paragraph>

            <Title>7. Гарантии и ограничение ответственности</Title>

            <Paragraph>7.1. Исполнитель настоящим гарантирует, что информация, предоставляемая им Заказчику в процессе
              исполнения настоящего Соглашения получена в соответствии с требованиями действующего законодательства
              Российской Федерации.</Paragraph>
            <Paragraph>7.2. Заказчик настоящим гарантирует, что услуги, предоставляемые Клиенту в процессе исполнения
              настоящего Соглашения, соответствуют требованиям действующего законодательства Российской
              Федерации.</Paragraph>
            <Paragraph>7.3. Заказчик использует Сайт Исполнителя на свой собственный риск. Исполнитель не несет
              ответственности за любые виды убытков, произошедшие вследствие использования Заказчиком Сайта или
              отдельных частей/функций Сайта.</Paragraph>
            <Paragraph>7.4. Объем ответственности Исполнителя по настоящему Соглашению ограничивается размером стоимости
              услуг в отчетном периоде, в котором было признано нарушение Исполнителем своих обязательств по Соглашению,
              что повлекло причинение ущерба Заказчику. Возмещению подлежит только реальный судебнодоказанный ущерб в
              следствии действий или бездействий Исполнителя.</Paragraph>
            <Paragraph>7.5. Исполнитель не возмещает Заказчику и/или третьим лицам убытки, понесенные в связи с
              использованием и/или невозможностью использования Сайта, разглашения информации третьим лицам в связи с
              использованием Сайта, и другие убытки, прямо или косвенно связанные с использованием и/или невозможностью
              использования Сайта, включая (но не ограничиваясь) упущенную выгоду.</Paragraph>
            <Paragraph>7.6. Исполнитель не несет какой-либо ответственности, если она связана или явилась результатом
              представления Заказчиком Исполнителю неточной, неверной или вводящей в заблуждение информации.</Paragraph>
            <Paragraph>7.7. Исполнитель освобождается от ответственности в случае полной или частичной потери информации
              Заказчика, если такая потеря была вызвана умышленными или неосторожными действиями самого Заказчика
              (удаление объектов и заявок Заказчика и т.п.).</Paragraph>
            <Paragraph>7.8. Стороны освобождаются от ответственности за частичное или полное неисполнение обязательств
              по Соглашению, если оно явилось следствием форс-мажора. К обстоятельствам форс-мажора относятся
              обстоятельства непреодолимой силы, не позволяющие обеспечить одной из Сторон надлежащее выполнение своих
              обязательств по настоящему Соглашению, в том числе (но не исключительно) постановления органов
              государственной власти и управления, войны, гражданские беспорядки, забастовки, природные события и
              катастрофы, носящих техногенный характер, а именно: авария (сбой в работе) в силовых электрических или
              компьютерных сетях или иных сервисах электрической связи, используемых для функционирования Сайта, не
              аккуратных действий Заказчиков, а равно в результате умышленных противоправных действий третьих лиц или
              каких либо других действий и лиц, направленных на нарушение функционирования Сайта. Сторона, для которой
              создалась невозможность исполнения обстоятельств, обязана в течении 2 (двух) рабочих дней в письменном
              виде (через Сайт или электронную почту) уведомить другую Сторону о наступлении, предполагаемом сроке
              действия и прекращения вышеуказанных обстоятельств. Неуведомление или несвоевременное уведомление лишает
              стороны права ссылаться на любое вышеуказанное обстоятельство как на основание, освобождающее от
              ответственности за неисполнение обязательства.</Paragraph>
            <Paragraph>7.9. В связи с использованием компьютерного и иного оборудования, каналов связи и (или) программ
              для ЭВМ, принадлежащих третьим лицам, Стороны соглашаются с тем, что Исполнитель по настоящему Соглашению
              не несет ответственность за любые задержки, прерывания, прямой и косвенный ущерб или потери, происходящие
              из-за дефектов в любом электронном или механическом оборудовании и (или) программах для ЭВМ, либо
              вследствие иных объективных технологических причин, а также в результате действий или бездействий третьих
              лиц, проблем при передаче данных или соединении, перебоев в электропитании, произошедших не по вине
              Исполнителя.</Paragraph>
            <Paragraph>
              7.10. Сайт, как и любое программное обеспечение, не свободно от ошибок. Сайт, весь функционал, включая
              скрипты, приложения, контент и иную информацию, размещенную на Сайте, поставляются на условиях «КАК ЕСТЬ»,
              со всеми недостатками, проявившимися сразу или не сразу. Исполнитель не гарантирует и не обещает
              каких-либо результатов от использования Сайта.
              <br/>
              Исполнитель не даёт никаких заверений или гарантий относительно сроков устранения каких-либо проблем и/или
              их последствий, отмеченных Заказчиком.
            </Paragraph>
            <Paragraph>7.11. Заказчик уведомлен и согласен с тем, что Исполнитель не может гарантировать поступления
              лидов в необходимом Заказчику объеме в определённый срок, т.к. это непосредственно зависит от действий
              Клиентов в сети Интернет.</Paragraph>
            <Paragraph>7.12. Исполнитель не несет ответственности в отношении достижения Заказчиком конкретного
              результата при обработке лидов, поступивших от Исполнителя, или в отношении наступления каких-либо
              последствий обработки этих лидов Заказчиком. При этом Исполнитель вправе провести проверку на предмет
              действительности лидов. В случае выявления неподтвержденных лидов, Исполнитель может заменить выявленные
              такие лиды на новые в таком же количестве, о чем уведомляет Заказчика.</Paragraph>
            <Paragraph>7.13. Исполнитель не несет ответственности за нарушение прав третьих лиц, возникших в результате
              действий Заказчика, совершенных с использованием предоставляемых Исполнителем услуг.</Paragraph>
            <Paragraph>7.14. Исполнитель не несёт ответственности за результаты, качество и прочие условия оказываемых
              Заказчиком услуг и/или выполняемых работ в интересах Клиентов и/или указанных ими третьих лиц, и за любую
              иную деятельность Заказчика.</Paragraph>
            <Paragraph>7.15. Стороны не несут ответственности за предоставленную Клиентами информацию. Вся
              ответственность за такие действия лежит на Клиентах.</Paragraph>
            <Paragraph>7.16. Ответственность за нарушение обработки персональных данных Клиентов несет
              Заказчик.</Paragraph>
            <Paragraph>7.17. Все результаты доступа к Сайту не могут быть переданы лицам, не получившим на это
              соответствующих разрешений от компетентных органов и третьих лиц в соответствии с установленными правилами
              и действующим законодательством РФ.</Paragraph>
            <Paragraph>7.18. Исполнитель не принимает на себя какие-либо условия или обязательства, помимо тех, которые
              зафиксированы в Соглашении, за исключением случаев, когда такие обязательства зафиксированы в письменном
              виде и подписаны Исполнителем и Заказчиком.</Paragraph>

            <Title>8. Конфиденциальная информация</Title>

            <Paragraph>8.1. Стороны, по своему усмотрению, в определяемых ими целях и устанавливаемом ими объеме
              предоставляют друг другу доступ к конфиденциальной информации о своей деятельности и взаимно обязуются
              добросовестно использовать и не разглашать указанную информацию, которая стала им известна в связи с
              исполнением предмета настоящего Соглашения, а также полученную ими любым иным способом.</Paragraph>
            <Paragraph>
              8.2. К конфиденциальной информации в смысле настоящего Соглашения отнесены сведения следующего содержания,
              если они не могут быть получены из открытых источников:
            </Paragraph>

            <List
              items={ [
                'сведения, касающиеся основных направлений деятельности Сторон, их действующих и стратегических планов, проектов, программ и т.п.;',
                'сведения о технических и технологических разработках и решениях Сторон;',
                'сведения о финансовом состоянии Сторон, размерах получаемой прибыли, а также иные сведения финансового характера;',
                'сведения, касающиеся менеджмента Сторон: используемые ими управленческие решения, способы ведения дел, «ноу-хау», стратегия и т.п.;',
                'сведения о маркетинговой политике сторон, в том числе об используемых способах привлечения покупателей и партнеров, т.п.;',
                'сведения о бывших, настоящих и потенциальных клиентах и партнерах Сторон;',
                'сведения о сотрудниках Сторон;',
                'любая иная информация, имеющая действительную или потенциальную ценность в силу неизвестности ее третьим лицам.',
              ] }
            />

            <Paragraph>8.3. Под "разглашением конфиденциальной информации" в смысле настоящего Соглашения следует
              понимать несанкционированные Раскрывающей стороной действия Принимающей стороны, в результате которых
              третьи лица получают возможность ознакомления с конфиденциальной информацией. Распространением
              конфиденциальной информации признается также бездействие Принимающей стороны, выразившееся в необеспечении
              надлежащего уровня защиты полученной от Раскрывающей стороны конфиденциальной информации и повлекшее
              получение доступа к такой информации со стороны третьих лиц.</Paragraph>
            <Paragraph>8.4. Стороны обязуются обеспечить соответствующий режим защиты информации от несанкционированного
              доступа со стороны третьих лиц.</Paragraph>
            <Paragraph>8.5. Принимающая сторона обязана обеспечить со своей стороны, со стороны своих аффилированных
              лиц, а также со стороны своих сотрудников, имеющих доступ к конфиденциальной информации, использование
              полученной конфиденциальной информации исключительно в целях, предусмотренных настоящим Соглашением, либо
              в целях, предусмотренных иными заключенными между Сторонами договорами и/или соглашениями,
              предусматривающими предоставление конфиденциальной информации.</Paragraph>
            <Paragraph>
              8.6. Не является разглашением конфиденциальной информации раскрытие любой из Сторон конфиденциальной
              информации, которая:
            </Paragraph>

            <List
              items={ [
                'законно являлась или стала известна или доступна Принимающей стороне до ее получения от Раскрывающей стороны;',
                'является или стала известной третьим лицам в результате правомерных деяний (действий, бездействия) Раскрывающей стороны;',
                'независимо подготовлена Принимающей стороной без какого-либо обращения к конфиденциальной информации, полученной от Раскрывающей стороны;',
                'разрешена к раскрытию письменным разрешением Раскрывающей стороны;',
                'является общедоступной по состоянию на дату подписания настоящего Соглашения или стала общедоступной после его подписания без нарушения положений настоящего Соглашения;',
                'раскрыта в соответствии с актом компетентного государственного органа или суда, вступившего в законную силу, при отсутствии возможности обжалования соответствующего акта.',
              ] }
            />

            <Paragraph>
              Конфиденциальная информация может быть раскрыта Принимающей стороной без согласия Раскрывающей стороны:
            </Paragraph>

            <List
              items={ [
                'судебным органам, органам предварительного следствия, органам дознания по делам, находящимся в их производстве, государственным органам, муниципальным органам, их должностным лицам, в порядке и на основаниях, которые предусмотрены законодательством Российской Федерации по их официальному письменному запросу.'
              ] }
            />

            <Paragraph>
              В случае если в официальном предписании или ином запросе уполномоченного органа указан объем информации,
              подлежащий раскрытию, Принимающая сторона обязуется не раскрывать Конфиденциальную информацию в степени
              большей, чем определено таким предписанием или иным запросом.
              <br/>
              В случае получения надлежаще оформленного мотивированного запроса уполномоченного государственного органа,
              на основании которого у Принимающей стороны возникнет обязанность раскрыть Конфиденциальную информацию,
              Принимающая сторона обязуется направить письменное уведомление об этом Раскрывающей стороне в течение 2
              (двух) рабочих дней с даты получения Принимающей стороной требования государственного или судебного органа
              и/или иного уполномоченного органа или наступления такого обязательства по закону, а в экстренных случаях
              – предпринять меры к тому, чтобы быстро проинформировать об этом Раскрывающую сторону любым доступным ей
              способом, таким образом, чтобы Стороны смогли совместно изыскать средство защиты и/или определить порядок
              исполнения настоящего Соглашения.
              <br/>
              Стороны обязуются сотрудничать друг с другом с тем, чтобы требовать от таких органов обеспечения
              поддержания конфиденциального статуса конфиденциальной информации.
            </Paragraph>
            <Paragraph>8.7. В случае недобросовестного использования или разглашения конфиденциальной информации,
              потерпевшая Сторона вправе потребовать возмещения прямых доказанных убытков, причиненных ей таким
              недобросовестным использованием либо разглашением конфиденциальной информации.</Paragraph>
            <Paragraph>8.8. Условия настоящего раздела сохраняют юридическую силу в течение 5 (пяти) лет после
              прекращения действия настоящего Соглашения.</Paragraph>

            <Title>9. Порядок внесения изменений и дополнений в Соглашение. Расторжение и прекращение Соглашения</Title>

            <Paragraph>9.1. Настоящий Соглашение вступает в силу с момента опубликования в сети Интернет на Сайте и
              действует до момента отзыва оферты Исполнителем либо публикации Соглашения в новой редакции.</Paragraph>
            <Paragraph>9.2. Положения настоящего Соглашения, как и всех его последующих редакций, являются обязательными
              для всех Заказчиков, в том числе и ранее зарегистрированных.</Paragraph>
            <Paragraph>9.3. Изменения и (или) дополнения в настоящий Соглашение вносятся в одностороннем порядке по
              решению Исполнителя и вступают в силу в день их опубликования на Сайте, если иной срок вступления в силу
              не предусмотрен дополнительно при их опубликовании.</Paragraph>
            <Paragraph>9.4. Текст изменений и (или) дополнений настоящего Соглашения либо его новая редакция доводится
              Исполнителем до всеобщего сведения путем публикации соответствующей информации на Сайте. Соглашение может
              быть изменено Исполнителем без какого-либо специального уведомления. Действующая редакция Соглашения
              всегда находится на странице по адресу https://Заявки.рф.</Paragraph>
            <Paragraph>9.5. В случае признания недействительным какого-либо положения Соглашения, остальные его
              положения не утрачивают свою силу. Ничтожность любого из положений Соглашения, не влечет ничтожность
              остальных его положений.</Paragraph>
            <Paragraph>9.6. Любая из Сторон имеет право в одностороннем (внесудебном) порядке отказаться от исполнения
              настоящего Соглашению путем направления соответствующего письменного уведомления другой Стороне. Настоящий
              Соглашение будет считаться расторгнутым по истечении 10 (десяти) рабочих дней с момента получения
              соответствующей Стороной такого уведомления при условии выполнения Заказчиком обязательств по оплате за
              фактически предоставленные услуги.</Paragraph>
            <Paragraph>9.7. Исполнитель удаляет данные Заказчика из своей базы данных через 5 лет после расторжения
              Соглашения. Учетная запись на Сайте может быть удалена по просьбе Заказчика.</Paragraph>

            <Title>10. Заключительные положения</Title>

            <Paragraph>10.1. Настоящий Соглашение регулируется и толкуется в соответствии с законодательством Российской
              Федерации. Вопросы, не урегулированные настоящим Соглашением, подлежат разрешению в соответствии с
              законодательством Российской Федерации.</Paragraph>
            <Paragraph>10.2. Ввиду безвозмездности услуг (консультации по вопросам работы на Сайте, пояснения, ответы на
              запросы Заказчика), оказываемых в рамках настоящего Соглашения, нормы о защите прав потребителей,
              предусмотренные законодательством Российской Федерации, не могут быть применимыми к отношениям между
              Заказчиком и Исполнителем.</Paragraph>
            <Paragraph>
              10.3. Все споры и разногласия, которые могут возникнуть в процессе выполнения настоящего Соглашения,
              будут, по возможности, решаться Сторонами путем переговоров. Соблюдение досудебного (претензионного)
              порядка урегулирования споров является обязательным. Срок направления ответа на претензию составляет 10
              (десять) рабочих дней с момента ее получения Стороной.
              <br/>
              Все возможные споры, вытекающие из отношений, регулируемых настоящим Соглашением, разрешаются в порядке,
              установленном действующим законодательством Российской Федерации, по нормам российского права, независимо
              от места пребывания Заказчика.
              <br/>
              В случае если Стороны не придут к взаимному согласию, то возникший спор подлежит разрешению в судебном
              порядке в соответствии с требованиями действующего законодательства Российской Федерации в суде по месту
              нахождения Исполнителя.
            </Paragraph>
            <Paragraph>10.4. Бездействие со стороны Исполнителя в случае нарушения Заказчиком либо иными пользователями
              пунктов Соглашения не лишает Исполнителя права предпринять соответствующие действия в защиту своих
              интересов позднее, а также не означает отказа Исполнителя от своих прав в случае совершения в последующем
              подобных либо сходных нарушений.</Paragraph>
            <Paragraph>
              10.5. Исполнитель в любое время по собственному усмотрению и без предварительного уведомления Заказчика
              вправе передать полностью или частично свои права и обязанности, вытекающие из настоящего Соглашения,
              любому третьему лицу.
              <br/>
              Заказчик имеет право передавать свои права и обязанности по заключённому Соглашению и/или передавать права
              на использование Сайта с письменного согласия Исполнителя.
            </Paragraph>
            <Paragraph>
              10.6. Настоящий Соглашение составлен на русском языке и в некоторых случаях может быть предоставлен
              Заказчику для ознакомления на другом языке. В случае расхождения русскоязычной версии Соглашения и версии
              Соглашения на ином языке, применяются положения русскоязычной версии настоящего Соглашения.
            </Paragraph>

            <Title>11. Реквизиты</Title>

            <Paragraph>
              <BankDetails/>
            </Paragraph>
          </Section>
        </Background>
      </Container>
    </React.Fragment>
  );
};

export default TermsPage;
