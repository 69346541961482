import React from 'react';
import './Section.scss';

const Section = ({className = '', ...props}) => {
  return (
    <div
      {...props}
      className={`section ${className}`}
    />
  );
};

export default Section;
