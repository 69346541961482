import React from 'react';
import { ReactComponent as Location } from './location.svg';
import { ReactComponent as Info } from './info.svg';
import { ReactComponent as Mail } from './mail.svg';
import { ReactComponent as User } from './user.svg';
import { ReactComponent as Handshake } from './handshake.svg';
import { ReactComponent as Password } from './password.svg';
import { ReactComponent as Pencil } from './pencil.svg';
import { ReactComponent as Phone } from './phone.svg';
import { ReactComponent as Support } from './support.svg';
import { ReactComponent as Account } from './account.svg';
import { ReactComponent as Site } from './site.svg';
import { ReactComponent as Logout } from './logout.svg';

export const LocationIcon = (props) => {
  return <Location {...props} />;
};

export const InfoIcon = (props) => {
  return <Info {...props} />;
};

export const MailIcon = (props) => {
  return <Mail {...props} />;
};

export const UserIcon = (props) => {
  return <User {...props} />;
};

export const HandshakeIcon = (props) => {
  return <Handshake {...props} />;
};

export const PasswordIcon = (props) => {
  return <Password {...props} />;
};

export const PencilIcon = (props) => {
  return <Pencil {...props} />;
};

export const PhoneIcon = (props) => {
  return <Phone {...props} />;
};

export const SupportIcon = (props) => {
  return <Support {...props} />;
};

export const AccountIcon = (props) => {
  return <Account {...props} />;
};

export const SiteIcon = (props) => {
  return <Site {...props} />;
};

export const LogoutIcon = (props) => {
  return <Logout {...props} />;
};

export const ArrowDownIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-arrow-down ${className}`} />;
};

export const ArrowLeftIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-arrow-left ${className}`} />;
};

export const ArrowRightIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-arrow-right ${className}`} />;
};

export const ArrowUpIcon = ({className, ...props}) => {
  return <i {...props} className={`icon-arrow-up ${className}`} />;
};

export const CheckIcon = ({className, ...props}) => {
  return <i {...props} className={`icon-check ${className}`} />;
};

export const CloseIcon = ({className, ...props}) => {
  return <i {...props} className={`icon-close ${className}`} />;
};

export const MenuIcon = ({className, ...props}) => {
  return <i {...props} className={`icon-menu ${className}`} />;
};

export const PaperclipIcon = ({className, ...props}) => {
  return <i {...props} className={`icon-paperclip ${className}`} />;
};
