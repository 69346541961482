import React, {PureComponent} from 'react';
import Select from '../Select';
import axios from 'axios';

class LocationSelect extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      locations: [],
    };
  }

  componentDidMount() {
    axios.get('/locations')
      .then(response => {
        this.setState({
          locations: response.data.results,
        });
      })
  }

  render() {
    const {locations} = this.state;

    return (
      <Select
        {...this.props}
        isSearchable={true}
        options={locations}
        getOptionValue={option => option.id}
        getOptionLabel={option => option.name}
        placeholder={`Выберите регион`}
      />
    );
  }
}

export default LocationSelect;
