import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import Modal from '../Modal';
import {openSuccessModal} from '../../actions/modalActions';
import ResetPasswordForm from '../ResetPasswordForm';
import {PasswordIcon} from '../../icons';
import {fetchUser} from '../../actions/authActions';

const initialState = {
  isOpen: false,
};

class ResetPasswordModal extends PureComponent {
  constructor(props) {
    super(props);

    this.state = initialState;
  }

  componentDidMount() {
    this.setState({
      isOpen: this.props.isOpen || false,
    })
  }

  handleClose = () => {
    this.setState({
      isOpen: false,
    });

    this.props.history.push({
      pathname: `/`
    });
  };

  handleSubmit = () => {
    this.setState(initialState);
    this.handleClose();
    this.props.openSuccessModal();
    this.props.fetchUser()
  };

  render = () => {
    const {isOpen} = this.state;

    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={this.handleClose}
        title={`Смена пароля`}
        description={`Введите новый пароль...`}
      >
        <ResetPasswordForm
          onSubmit={this.handleSubmit}
        />
      </Modal>
    );
  };
}

const mapDispatchToProps = dispatch => ({
  openSuccessModal: () => dispatch(openSuccessModal({
    icon: () => <PasswordIcon/>,
    description: 'Вы успешно обновили пароль'
  })),
  fetchUser: () => dispatch(fetchUser())
});

export default connect(null, mapDispatchToProps)(withRouter(ResetPasswordModal));
