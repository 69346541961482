import React, {PureComponent} from 'react';
import './AuthModal.scss';
import {connect} from 'react-redux';
import MobileDetect from 'mobile-detect';
import {closeAuthModal} from './../../actions/modalActions';
import Modal from './../Modal';
import LoginForm from './../LoginForm';
import RegisterForm from './../RegisterForm';

class AuthModal extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      tab: props.tab || 'login',
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps !== this.props) {
      this.setState({
        tab: this.props.tab,
      });
    }
  }

  render = () => {
    const {tab} = this.state;

    return (
      <Modal
        isOpen={this.props.isOpen}
        onRequestClose={this.props.closeAuthModal}
        title={`Мой профиль`}
      >
        <div className={`tabs ${new MobileDetect(window.navigator.userAgent).phone() ? 'tabs--phone' : ''}`}>
          <button
            className={`tabs__item ${tab === 'login' ? 'tabs__item--active' : ''}`}
            onClick={() => this.setState({tab: 'login'})}
          >
            Войти
          </button>
          <button
            className={`tabs__item ${tab === 'register' ? 'tabs__item--active' : ''}`}
            onClick={() => this.setState({tab: 'register'})}
          >
            Регистрация
          </button>
        </div>

        {tab === 'login' && (
          <LoginForm/>
        )}

        {tab === 'register' && (
          <RegisterForm/>
        )}
      </Modal>
    );
  };
}

const mapStateToProps = state => ({
  isOpen: state.modal.auth.isOpen,
  tab: state.modal.auth.payload.tab,
});

const mapDispatchToProps = dispatch => ({
  closeAuthModal: () => dispatch(closeAuthModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthModal);
