import React from 'react';
import './Support.scss';
import Container from '../Container';
import Button from '../Button';
import {HandshakeIcon, InfoIcon, SupportIcon} from '../../icons';
import {openInfoModal, openPartnerModal, openSupportModal} from '../../actions/modalActions';
import {connect} from 'react-redux';
import Section from '../Section';

const Support = (props) => {
  return (
      <Section>
        <Container>
          <div className="support">
            <div className="support__item">
              <div className="support__icon">
                <InfoIcon/>
              </div>
              <div className="support__content">
                <div className="support__title">
                  Для дополнительной информации
                </div>
                <div className="support__description">
                  Если вам нужна дополнительная информация по работе нашего сервиса, напишите нам и мы проконсультируем
                  вас
                </div>
                <Button
                    size="small"
                    onClick={props.openInfoModal}
                >
                  Написать
                </Button>
              </div>
            </div>
            <div className="support__item">
              <div className="support__icon">
                <SupportIcon/>
              </div>
              <div className="support__content">
                <div className="support__title">
                  Техническая поддержка
                </div>
                <div className="support__description">
                  У вас сложности или вопросы технического характера? Напишите нам, мы решим этот вопрос.
                </div>
                <Button
                    size="small"
                    onClick={props.openSupportModal}
                >
                  Написать
                </Button>
              </div>
            </div>
            <div className="support__item">
              <div className="support__icon">
                <HandshakeIcon/>
              </div>
              <div className="support__content">
                <div className="support__title">
                  Партнерам
                </div>
                <div className="support__description">
                  Хотите предложить ваше партнерство? Напишите нам мы будем рады обсудить с вами условия сотрудничества
                </div>
                <Button
                    size="small"
                    onClick={props.openPartnerModal}
                >
                  Написать
                </Button>
              </div>
            </div>
          </div>
        </Container>
      </Section>
  );
};

const mapDispatchToProps = dispatch => ({
  openInfoModal: () => dispatch(openInfoModal()),
  openSupportModal: () => dispatch(openSupportModal()),
  openPartnerModal: () => dispatch(openPartnerModal()),
});

export default connect(null, mapDispatchToProps)(Support);
