import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {forgotPassword} from '../../actions/authActions';
import FormControl from './../FormControl';
import Input from './../Input';
import {MailIcon} from '../../icons';
import Button from './../Button';
import Errors from './../Errors';

const initialState = {
  data: {
    email: '',
  },
  errors: new Errors({}),
};

class ForgotPasswordForm extends PureComponent {
  constructor(props) {
    super(props);

    this.state = initialState;
  }

  handleChange = (e) => {
    const {name, value, type, checked} = e.target;

    this.setState(prevState => ({
      data: {
        ...prevState.data,
        [name]: type === 'checkbox' ? checked : value,
      }
    }));
  };

  handleSubmit = () => {
    const {data: {email}} = this.state;

    this.props.forgotPassword({
      email: email
    }).then(() => {
      this.setState(initialState);
      this.props.onSubmit();
    }).catch(errors => {
      this.setState({
        errors: new Errors(errors)
      })
    });
  };

  render = () => {
    const {data: {email}, errors} = this.state;

    return (
        <React.Fragment>
          <FormControl>
            <Input
                error={errors.get('email')}
                name="email"
                value={email}
                onChange={this.handleChange}
                icon={props => <MailIcon {...props} />}
                placeholder={`Ваш email`}
            />
          </FormControl>
          <FormControl>
            <Button
                fullWidth
                onClick={this.handleSubmit}
            >
              Отправить
            </Button>
          </FormControl>
        </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  forgotPassword: props => dispatch(forgotPassword(props)),
});

export default connect(null, mapDispatchToProps)(ForgotPasswordForm);
