import React from 'react';
import './Contact.scss';

const Contact = ({label = '', value = ''}) => {
  return (
    <div className="contact">
      <div className="contact__label">
        {label}
      </div>
      <div className="contact__value">
        {value}
      </div>
    </div>
  );
};

export default Contact;
