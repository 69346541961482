import React from 'react';
import {connect} from 'react-redux';
import Background from './../components/Background';
import PageHero from './../components/PageHero';
import Categories from './../components/Categories';
import LeadFeatures from './../components/LeadFeatures';
import Button from './../components/Button';
import CategoryRequest from './../components/CategoryRequest';
import {openCategoryRequestModal} from './../actions/modalActions';
import AboutUsImage from '../images/about-us.svg'

const AboutUsPage = (props) => {
  return (
    <React.Fragment>
      <Background color="grey">
        <PageHero
          title={`О компании`}
          image={() => <img src={AboutUsImage} alt="" />}
        >
          <p>
            Компания «Заявки.рф» – это надежный и гибкий сервис по лидогенерации и снабжению бизнеса новыми клиентами
          </p>
          <p>
            Мы предлагаем простую и понятную услугу для любого бизнеса. Вы будете получать целевые профилированные заявки от ваших потенциальных клиентов (лиды) по удобным для вас объемам и условиям сотрудничества
          </p>
          <Button
            onClick={props.openCategoryRequestModal}
          >
            Работать с нами!
          </Button>
        </PageHero>
      </Background>

      <Background color="white">
        <Categories/>
        <LeadFeatures/>
      </Background>

      <Background color="grey-white">
        <CategoryRequest/>
      </Background>
    </React.Fragment>
  );
};

export default connect(null, {openCategoryRequestModal})(AboutUsPage);
