import React from 'react';
import './Categories.scss';
import {withRouter} from 'react-router-dom';
import Section from './../Section';
import Container from './../Container';
import SectionTitle from './../SectionTitle';
import SectionDescription from './../SectionDescription';
import categories from './../../constants/categories';

const Categories = (props) => {
  const {history} = props;

  return (
    <Section>
      <Container>
        <SectionTitle center>
          Категории лидов
        </SectionTitle>
        <SectionDescription center>
          Мы предоставляем лиды в широком спектре тематик для разного типа бизнеса, посмотрите сами!
        </SectionDescription>
        <div className="categories">
          {categories.map((category, index) => (
            <div
              key={index}
              className="categories__item"
              onClick={() => history.push(category.url)}
            >
              {category.title}
            </div>
          ))}
        </div>
      </Container>
    </Section>
  );
};

export default withRouter(Categories);
