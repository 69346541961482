import React from 'react';
import './PageHero.scss';
import Section from './../Section';
import Container from './../Container';

const PageHero = ({title = '', image: Image = () => null, children}) => {
  return (
    <Section>
      <Container>
        <div className="page-hero">
          <div className="page-hero__image">
            <Image/>
          </div>
          <div className="page-hero__content">
            <div className="page-hero__title">
              {title}
            </div>
            <div className="page-hero__description">
              {children}
            </div>
          </div>
        </div>
      </Container>
    </Section>
  );
};

export default PageHero;
