import React from 'react';
import './FileInput.scss';
import {uniqueId} from 'lodash';
import {PaperclipIcon} from '../../icons';
import _ from 'lodash';
import {CloseIcon} from '../../icons';

const FileInput = ({value, onChange, error = null, ...props}) => {
  const files = [...value];

  const id = uniqueId('file-input-');
  let fileInput = React.createRef();

  return (
      <div className="file-input">
        <div className="file-input__description">
          {`Прикрепите скриншот или`}
          <br/>
          {`файл если считаете нужным`}
        </div>
        <label
            htmlFor={id}
            className="file-input__label"
        >
          <PaperclipIcon
              className="file-input__icon"
          />
          Прикрепить файл
        </label>
        <input
            {...props}
            multiple
            id={id}
            type="file"
            className="file-input__input"
            ref={fileInput}
            onChange={e => onChange([...files, ...e.target.files])}
        />

        {files.length > 0 && (
            <div className="file__list">
              {_.map(files, (file, index) => {
                return (
                    <React.Fragment key={index}>
                      <div className="file__item">
                        <div className="file__name">
                          {file.name}
                        </div>
                        <button
                            className="file__delete"
                            onClick={() => {
                              delete files[index];
                              onChange(files.filter(Boolean));
                            }}
                        >
                          <CloseIcon/>
                        </button>
                      </div>
                      {error[`attachments.${index}`] && (
                          <div className="input__error">
                            {error[`attachments.${index}`]}
                          </div>
                      )}
                    </React.Fragment>
                );
              })}
            </div>
        )}
      </div>
  );
};

export default FileInput;
